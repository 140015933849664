import { useMemo } from "react";

import { useAppState } from "../AppStateContext";

// Keep in mind that any component/hook that uses `geoFilterOptions` should handle the case where
// it's not present yet, especially since it's fairly slow to load. We pre-fetch this data in
// Audiences.js (again, since it's slow) so that it's more likely to be fetched when needed.
const useGetGeoFilterOptions = () => {
  const { constants, geoFilterOptions } = useAppState();

  return useMemo(
    () =>
      geoFilterOptions
        ? {
            states: constants.US_STATES_AND_DC,
            counties: geoFilterOptions.counties,
            congressionalDistricts: geoFilterOptions.congressionalDistricts,
            stateLegUpperDistricts: geoFilterOptions.stateLegUpperDistricts,
            stateLegLowerDistricts: geoFilterOptions.stateLegLowerDistricts,
          }
        : null,
    [constants, geoFilterOptions],
  );
};

export default useGetGeoFilterOptions;
