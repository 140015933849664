import { Button, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";

import { COLORS } from "../../colors";

const TabButton = ({ children, ...rest }) => {
  return (
    <Button
      sx={{
        color: COLORS.gray,
        "&:hover, &.active": {
          backgroundColor: "initial",
          color: COLORS.black,
        },
      }}
      variant="text"
      {...rest}
    >
      {children}
    </Button>
  );
};

const Tabs = ({ tabs }) => {
  return (
    <Grid
      container
      justifyContent="space-evenly"
      sx={{ background: COLORS.white, marginBottom: "2rem" }}
    >
      {tabs.map(tab => (
        <Grid item key={`${tab.name}-${tab.url}`}>
          <TabButton component={NavLink} startIcon={tab.icon} to={tab.url}>
            {tab.name}
          </TabButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default Tabs;
