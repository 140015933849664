import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import MatchbookLogoWithText from "../svgs/MatchbookLogoWithText";

const AccountWrapper = () => {
  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="center" minHeight="100vh">
        <Box
          sx={{
            borderRadius: "6px",
            padding: "2rem",
            width: "400px",
          }}
        >
          <MatchbookLogoWithText margin="0 auto 1.5rem" width="200px" />
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AccountWrapper;
