import { alpha } from "@mui/material";
import { darken } from "@mui/material/styles";

export const COLORS = {
  brown: "#AF734A",
  magenta: "#84385B",
  lightMagenta: alpha("#84385B", 0.5),
  veryLightMagenta: alpha("#84385B", 0.2),
  cyan: "#089488",
  blue: "#5D69A1",
  lightBlue: "#0070E0",
  green: "#00e676",
  lightGreen: alpha("#00e676", 0.2),
  teal: "#089488",
  lightTeal: alpha("#089488", 0.2),
  red: "#BF4032",
  lightRed: alpha("#BF4032", 0.5),
  orange: "#F8922F",
  darkOrange: darken("#F8922F", 0.1),
  lightOrange: alpha("#F8922F", 0.3),
  veryLightOrange: alpha("#F8922F", 0.1),
  black: "#000",
  lighterBlack: "#111",
  white: "#FFF",
  veryDarkGray: "#919191",
  darkerGray: "#848484",
  gray: "#727272",
  lightGray: "#D9D9D9",
  lighterGray: "#EFEFEF",
  veryLightGray: "#F7F7F7",
};

// Return a shade of gray somewhere along the spectrum from very light (0) to very dark (10).
export const grayscale = shade => {
  shade = Math.min(10, Math.max(0, shade));
  const value = Math.floor((255 * Math.log(shade + 1)) / Math.log(11));
  const hex = ("00" + (value % 256).toString(16)).slice(-2);
  return `#${hex}${hex}${hex}`;
};
