import { useParams } from "react-router-dom";

import useGetActiveOrg from "./useGetActiveOrg";

const useGetOutreachCampaignFromParam = () => {
  const { outreachCampaignId } = useParams();
  const { organization } = useGetActiveOrg();
  const { outreachCampaigns } = organization;
  return outreachCampaigns && outreachCampaigns.find(oc => oc.id === outreachCampaignId);
};

export default useGetOutreachCampaignFromParam;
