import { gql, useLazyQuery } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { last, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useAppStateDispatch } from "../../AppStateContext";
import { CORE_DIGITAL_AD_FUNDRAISING_ESTIMATE_FIELDS } from "../../fragments";
import DropdownSelectOne from "../shared/DropdownSelectOne";

import CampaignFundraisingEstimate from "./CampaignFundraisingEstimate";

const MINUTES_TO_WAIT_FOR_FUNDRAISING_ESTIMATES = 25;

const GET_ESTIMATES = gql`
  ${CORE_DIGITAL_AD_FUNDRAISING_ESTIMATE_FIELDS}
  query getCampaignFundraisingEstimates($campaignId: ID!) {
    campaignFundraisingEstimates(campaignId: $campaignId) {
      ...CoreDigitalAdFundraisingEstimateFields
    }
  }
`;

const CampaignFundraisingEstimatesSection = ({
  estimates,
  budget,
  audienceSize,
  outreachCampaign,
}) => {
  const dispatch = useAppStateDispatch();
  const bestEstimate = useMemo(() => last(sortBy(estimates, ["dollarsRaised"])), [estimates]);
  const estimateOptions = useMemo(
    () => estimates.map(e => ({ value: e.id, label: e.contentVariation.name })),
    [estimates],
  );
  const [selectedEstimate, setSelectedEstimate] = useState(bestEstimate);

  // Handle when new estimates come in after the component has rendered.
  useEffect(() => {
    if (bestEstimate && !selectedEstimate) {
      setSelectedEstimate(bestEstimate);
    }
  }, [bestEstimate, selectedEstimate]);

  const [doGetEstimates, { loading }] = useLazyQuery(GET_ESTIMATES, {
    onCompleted: data => {
      dispatch({
        type: "org-add-or-update-outreach-campaign",
        organizationId: outreachCampaign.organization.id,
        outreachCampaign: {
          ...outreachCampaign,
          campaignContentVariationDigitalAdFundraisingEstimates: data.campaignFundraisingEstimates,
        },
      });
    },
  });

  const getEstimates = () => doGetEstimates({ variables: { campaignId: outreachCampaign.id } });

  const now = dayjs();
  const campaignCreatedMinutesAgo = now.diff(dayjs(outreachCampaign.createdAt), "minute");

  // @todo we could circle back and provide some UI for this case, but I think it's probably
  // not necessary at the moment, and this shouldn't happen unless there's an error.
  if (
    estimates.length === 0 &&
    campaignCreatedMinutesAgo > MINUTES_TO_WAIT_FOR_FUNDRAISING_ESTIMATES
  ) {
    return null;
  }

  let mainContent;
  if (estimates.length > 0 && selectedEstimate) {
    mainContent = (
      <>
        <Box sx={{ maxWidth: "400px" }}>
          <DropdownSelectOne
            onChange={estimateId => setSelectedEstimate(estimates.find(e => e.id === estimateId))}
            options={estimateOptions}
            value={selectedEstimate.id}
          />
        </Box>
        <Box marginTop="1rem">
          <Link to={`/content/variation/${selectedEstimate.contentVariation.typedId}`}>
            View variation
          </Link>
        </Box>
        <CampaignFundraisingEstimate
          estimate={selectedEstimate}
          {...{ budget, audienceSize, outreachCampaign }}
        />
      </>
    );
  } else {
    mainContent = (
      <>
        <Box>Estimates are being generated, this may take a minute or two...</Box>
        <Box marginTop="1rem">
          <Button disabled={loading} onClick={getEstimates}>
            Refresh estimates
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="h2">Performance estimates:</Typography>
      {mainContent}
    </>
  );
};

export default CampaignFundraisingEstimatesSection;
