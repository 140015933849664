import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

import useGetMinMaxCreditsPurchase from "../../hooks/useGetMinMaxCreditsPurchase";
import PurchaseCreditsCheckout from "../PurchaseCreditsCheckout";

import SharedModal from "./SharedModal";

const PurchaseCreditsDirectly = ({ numCredits, returnPath }) => {
  const [shouldShowPurchaseCreditsCheckout, setShouldShowPurchaseCreditsCheckout] = useState(false);
  const { MIN_CREDITS_PURCHASE, MAX_CREDITS_PURCHASE } = useGetMinMaxCreditsPurchase();

  let actualNumCredits = numCredits;
  if (actualNumCredits < MIN_CREDITS_PURCHASE) {
    actualNumCredits = MIN_CREDITS_PURCHASE;
  } else if (actualNumCredits > MAX_CREDITS_PURCHASE) {
    actualNumCredits = MAX_CREDITS_PURCHASE;
  }

  const returnPathEncoded = returnPath ? encodeURIComponent(returnPath) : "";
  const purchaseButton = (
    <Button onClick={() => setShouldShowPurchaseCreditsCheckout(true)}>
      Purchase {actualNumCredits} credits
    </Button>
  );

  return (
    <Box marginTop="1rem" variant="body">
      <SharedModal
        isOpen={shouldShowPurchaseCreditsCheckout}
        setIsOpen={setShouldShowPurchaseCreditsCheckout}
        width={600}
      >
        <PurchaseCreditsCheckout numCredits={actualNumCredits} returnPath={returnPath} />
      </SharedModal>
      {actualNumCredits !== numCredits ? (
        <Tooltip
          title={
            <Typography variant="subtitle2">
              The minimum number of credits that can be purchased at a time is{" "}
              {MIN_CREDITS_PURCHASE}.
            </Typography>
          }
        >
          {purchaseButton}
        </Tooltip>
      ) : (
        purchaseButton
      )}
      <Box marginTop="1rem">
        Or you can{" "}
        <Link to={`/credits/purchase/${returnPathEncoded}`}>
          purchase a different number of credits
        </Link>{" "}
        from our credits page.
      </Box>
    </Box>
  );
};

export default PurchaseCreditsDirectly;
