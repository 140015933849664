import { useParams } from "react-router-dom";

import useGetActiveOrg from "./useGetActiveOrg";

const useGetAudienceFromParam = () => {
  const { audienceId } = useParams();
  const { organization } = useGetActiveOrg();
  const { audiences } = organization;
  return audiences && audiences.find(a => a.id === audienceId);
};

export default useGetAudienceFromParam;
