import { gql, useQuery, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import "react-toastify/dist/ReactToastify.css";
import CampaignIcon from "@mui/icons-material/Campaign";
import LogoutIcon from "@mui/icons-material/Logout";
import MmsIcon from "@mui/icons-material/Mms";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SearchIcon from "@mui/icons-material/Search";
// import PersonIcon from "@mui/icons-material/Person";
// import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
// import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { Outlet, NavLink, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppStateProvider, useAppStateDispatch, useAppState } from "./AppStateContext";
import { COLORS } from "./colors";
import ThemeWrapper from "./components/ThemeWrapper";
import Loading from "./components/shared/Loading";
import MatchbookLogoWithText from "./components/svgs/MatchbookLogoWithText";
import { LOGGED_IN_USER_FIELDS } from "./fragments";
import { userFriendlyNumber } from "./helpers";
import useGetActiveOrg from "./hooks/useGetActiveOrg";
import useScrollToTopOnPathChange from "./hooks/useScrollToTopOnPathChange";

import "./App.css";

const NAV_SECTION_1 = {
  // Home: { url: "/", icon: HouseOutlinedIcon },
  Audiences: { url: "/audiences", icon: PermContactCalendarIcon },
  Content: { url: "/content", icon: MmsIcon },
  Campaigns: { url: "/campaigns", icon: CampaignIcon },
  Experiments: { url: "/experiments", icon: SearchIcon },
};
const NAV_SECTION_2 = {
  // Account: { url: "/account", icon: PersonIcon },
  Help: { url: "/help", icon: QuestionAnswerIcon },
};

const DRAWER_WIDTH = 190;
const TOP_NAV_HEIGHT = 64;

const AppWrapper = styled.div`
  margin: 0 30px;
`;

const StyledNavLink = styled(NavLink)`
  color: ${COLORS.black};
  &.active {
    & span {
      font-weight: bold;
    }
  }
`;

export const GET_USER = gql`
  ${LOGGED_IN_USER_FIELDS}
  query getUser {
    user {
      ...RootUserFields
    }
  }
`;

export const GET_CONSTANTS = gql`
  query getConstants {
    getConstants {
      constants
    }
  }
`;

const LOGOUT = gql`
  mutation {
    logout
  }
`;

const Fallback = () => {
  return (
    <h3 style={{ margin: "2rem", textAlign: "center" }}>
      Whoops, something went wrong. Please try again later.
    </h3>
  );
};

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      <AppStateProvider>
        <ThemeWrapper>
          {/* This is needed to provide localization info for date/time related functions. */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ToastContainer draggable={false} hideProgressBar={true} position="top-center" />
            <AppWrapper>
              <InnerApp />
            </AppWrapper>
          </LocalizationProvider>
        </ThemeWrapper>
      </AppStateProvider>
    </Sentry.ErrorBoundary>
  );
};

const LeftNav = () => {
  return (
    <Drawer
      anchor="left"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
    >
      <Toolbar
        disableGutters
        sx={{
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem 1.5rem 0 1.5rem",
        }}
      >
        <Box>
          <Link to="/">
            <MatchbookLogoWithText width="100%" />
          </Link>
        </Box>
        <Typography color={COLORS.blue} variant="body2">
          (beta)
        </Typography>
      </Toolbar>
      <List>
        {Object.keys(NAV_SECTION_1).map(navKey => (
          <NavItem key={navKey} navValue={NAV_SECTION_1[navKey]} text={navKey} />
        ))}
      </List>
      <List>
        {Object.keys(NAV_SECTION_2).map(navKey => (
          <NavItem key={navKey} navValue={NAV_SECTION_2[navKey]} text={navKey} />
        ))}
      </List>
    </Drawer>
  );
};

const TopNav = () => {
  const { organization } = useGetActiveOrg();
  const [doLogoutMutation] = useMutation(LOGOUT);

  const logout = () => {
    doLogoutMutation({
      onCompleted: () => {
        window.location.reload();
      },
    });
  };

  const creditDisplay = organization.isExemptFromPayments ? null : (
    <>
      :{" "}
      <Link to="/credits/purchase">
        {userFriendlyNumber(organization.numCredits)} {pluralize("credit", organization.numCredits)}
      </Link>
    </>
  );

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: COLORS.white,
        color: COLORS.black,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        height: `${TOP_NAV_HEIGHT}px`,
      }}
    >
      <Toolbar>
        <Typography noWrap sx={{ flexGrow: 1 }}>
          <strong>{organization.name}</strong>
          {creditDisplay}
        </Typography>
        <Button onClick={logout} startIcon={<LogoutIcon />} variant="text">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const NavItem = ({ text, navValue }) => {
  const IconComponent = navValue.icon;
  return (
    <ListItem disablePadding component={StyledNavLink} to={navValue.url}>
      <ListItemButton>
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <IconComponent />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

const InnerApp = () => {
  const dispatch = useAppStateDispatch();
  const { user, constants } = useAppState();
  useScrollToTopOnPathChange();

  useQuery(GET_USER, {
    onCompleted: data => dispatch({ type: "user-and-orgs-set", user: data.user }),
  });

  useQuery(GET_CONSTANTS, {
    onCompleted: data =>
      dispatch({ type: "constants-set", constants: data.getConstants.constants }),
  });

  // User is still loading or being updated in state
  if (user === undefined || !constants) {
    return <Loading />;
  } else if (!user) {
    return <Outlet />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <TopNav />
      {/* @todo-styling handle mobile */}
      <LeftNav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          maxWidth: "lg",
          height: "100%",
          marginTop: `${TOP_NAV_HEIGHT}px`,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default App;
