import { map } from "lodash";
import { useMemo } from "react";

import { useAppState } from "../AppStateContext";

// Stripe checkout doesn't allow purchasing <$.50 or >$999,999 in a transaction.
const MIN_STRIPE_CHARGE_CENTS = 50;
const MAX_STRIPE_CHARGE_CENTS = 99999900;

const useGetMinMaxCreditsPurchase = () => {
  const { constants } = useAppState();

  const MIN_CREDITS_PURCHASE = useMemo(
    () =>
      Math.ceil(
        MIN_STRIPE_CHARGE_CENTS /
          Math.max(...map(constants.CREDIT_PRICING_TIERS, tier => tier.pricePerCredit)),
      ),
    [constants.CREDIT_PRICING_TIERS],
  );
  const MAX_CREDITS_PURCHASE = useMemo(
    () =>
      Math.floor(
        MAX_STRIPE_CHARGE_CENTS /
          Math.min(...map(constants.CREDIT_PRICING_TIERS, tier => tier.pricePerCredit)),
      ),
    [constants.CREDIT_PRICING_TIERS],
  );

  return { MIN_CREDITS_PURCHASE, MAX_CREDITS_PURCHASE };
};

export default useGetMinMaxCreditsPurchase;
