import { Grid, Box } from "@mui/material";
import { reverse, sortBy } from "lodash";

import { CONTENT_GROUP_TYPES } from "../../constants";
import { formatDate } from "../../helpers";
import useGetCurrentOutreachCampaigns from "../../hooks/useGetCurrentOutreachCampaigns";
import { OneLineTypography } from "../../styles";
import CardItem from "../shared/CardItem";

import OutreachCampaignsHeader from "./OutreachCampaignsHeader";

const OutreachCampaignListItem = ({ outreachCampaign }) => {
  return (
    <CardItem linkTo={`/campaigns/${outreachCampaign.id}`}>
      <Box sx={{ marginBottom: "0.5rem" }}>
        <OneLineTypography
          sx={{ marginBottom: "0.25rem" }}
          title={outreachCampaign.name}
          variant="h3"
        >
          {outreachCampaign.name}
        </OneLineTypography>
        <OneLineTypography
          title={CONTENT_GROUP_TYPES[outreachCampaign.contentGroup.type].name}
          variant="subtitle2"
        >
          {CONTENT_GROUP_TYPES[outreachCampaign.contentGroup.type].name}
        </OneLineTypography>
        <OneLineTypography variant="subtitle2">
          {formatDate(outreachCampaign.createdAt)}
        </OneLineTypography>
      </Box>
      <OneLineTypography title={outreachCampaign.audience.name} variant="subtitle2">
        Audience: {outreachCampaign.audience.name}
      </OneLineTypography>
      <OneLineTypography title={outreachCampaign.contentGroup.name} variant="subtitle2">
        Content: {outreachCampaign.contentGroup.name}
      </OneLineTypography>
    </CardItem>
  );
};

const OutreachCampaignList = () => {
  const outreachCampaigns = useGetCurrentOutreachCampaigns();

  const outreachCampaignList = reverse(sortBy(outreachCampaigns, "createdAt")).map(oc => (
    <OutreachCampaignListItem key={oc.id} outreachCampaign={oc} />
  ));
  return (
    <>
      <OutreachCampaignsHeader alternateRightContent={null} />
      <Grid container spacing={4}>
        {outreachCampaignList}
      </Grid>
    </>
  );
};

export default OutreachCampaignList;
