import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../AppStateContext";
import { userFriendlyNumber } from "../helpers";

import Loading from "./shared/Loading";

const GET_STRIPE_SESSION_STATUS = gql`
  query stripeSessionStatus($sessionId: String!) {
    stripeSessionStatus(sessionId: $sessionId) {
      status
      numCredits
    }
  }
`;

// Note that this mutation returns an organization record.
const FULFILL_STRIPE_SESSION = gql`
  mutation stripeFulfillSession($sessionId: String!) {
    stripeFulfillSession(sessionId: $sessionId) {
      id
      numCredits
    }
  }
`;

const CreditsReturn = () => {
  const { sessionId, returnPath } = useParams();
  const dispatch = useAppStateDispatch();
  const navigate = useNavigate();
  const [isFulfillmentRequestComplete, setIsFulfillmentRequestComplete] = useState(false);
  const [isStatusCheckComplete, setIsStatusCheckComplete] = useState(false);

  // Trigger fulfillment from the landing page. This is in addition to fulfillment being triggered
  // by a stripe webhook, but this makes the UX nicer for our instant-fulfillment use-case.
  // Note that we need the webhook fulfillment flow too because it's possible the user e.g.
  // closes their tab before they get to the landing page but after the transaction completes.
  // See https://docs.stripe.com/checkout/fulfillment#trigger-fulfillment-on-landing-page
  const [doFulfillMutation] = useMutation(FULFILL_STRIPE_SESSION, {
    variables: { sessionId },
    onCompleted: data => {
      setIsFulfillmentRequestComplete(true);
      dispatch({
        type: "org-update-num-credits",
        organizationId: data.stripeFulfillSession.id,
        numCredits: data.stripeFulfillSession.numCredits,
      });
    },
  });

  useEffect(() => {
    doFulfillMutation();
  }, [doFulfillMutation]);

  // The checkout session status check being successful indicates that checkout succeeded,
  // not that fulfillment was completed: checkout and fulfillment are separate processes.
  useQuery(GET_STRIPE_SESSION_STATUS, {
    skip: isStatusCheckComplete,
    variables: { sessionId },
    onCompleted: data => {
      if (data.stripeSessionStatus.status === "open") {
        toast("There was an issue processing your payment. Please try again.", { type: "error" });
      } else {
        // This check probably isn't strictly necessary but we don't want to error out if the
        // session signature isn't quite what was expected.
        const numCreditsDisplay = data.stripeSessionStatus.numCredits
          ? userFriendlyNumber(data.stripeSessionStatus.numCredits)
          : "";
        toast(`You successfully purchased ${numCreditsDisplay} credits.`, {
          type: "success",
        });
      }
      setIsStatusCheckComplete(true);
    },
    onError: () => {
      setIsStatusCheckComplete(true);
    },
  });

  useEffect(() => {
    // In our case we want to wait for fulfillment to complete in addition to the session status
    // check completing before redirecting the user to the credits page. This is because our
    // fulfillment is very fast (so it's not a problem to wait) and it's nice to show the user
    // the updated number of credits they have post-transaction from the /credits/purchase page
    // once they redirect there.
    if (isFulfillmentRequestComplete && isStatusCheckComplete) {
      if (returnPath) {
        navigate(returnPath);
      } else {
        navigate("/credits/purchase");
      }
    }
  }, [isFulfillmentRequestComplete, isStatusCheckComplete, navigate, returnPath]);

  return <Loading />;
};

export default CreditsReturn;
