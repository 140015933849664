import { gql } from "@apollo/client";

export const LOGGED_IN_USER_FIELDS = gql`
  fragment RootUserFields on RootUser {
    id
    firstName
    lastName
    email
    isStaff
    organizations {
      id
      name
      numCredits
      isExemptFromPayments
    }
  }
`;

export const CORE_AUDIENCE_FIELDS = gql`
  fragment CoreAudienceFields on BaseAudience {
    id
    name
    status
    metadata
    isPreview
    isCurrentVersion
    isArchived
    canNarrowFrom
    canCopyAndEditFiltersFrom
    canCreateLookalikeFrom
    isFilteredFromNational
    errorInfoUserFacing
    numLookalikesRequested
    lookalikeIncludeOriginalAudience
    isFilteredFromLookalike
    audienceFilteredFrom {
      id
      name
      canCreateLookalikeFrom
    }
    audienceCopiedAndEditedFrom {
      id
    }
    audienceRegeneratedFrom {
      id
    }
    audienceLookalikeCreatedFrom {
      id
    }
    exports {
      id
      createdAt
      createdBy {
        firstName
        lastName
      }
      downloadUrl
      purchaseCostBreakdown
      status
      totalCost
    }
    filters {
      id
      createdAt
      states
      counties
      congressionalDistricts
      stateLegLowerDistricts
      stateLegUpperDistricts
      zip
      distanceFromZip
      ageStart
      ageEnd
      ageIncludeUnknown
      genders
      races
      registrationDateStart
      registrationDateEnd
      hasDonorHistory
      contactInfo
      sortField
      sortAsc
      limit
    }
    organization {
      id
      numCredits
    }
    uploadedAudienceCsv {
      id
      status
    }
    uploadedAudienceCsvMatchMetadata {
      id
      numMatches
      numUniqueMatches
      columnMatchResults {
        id
        column
        matchRate
        percentOfMatches
        percentMissing
      }
    }
    csvMatchUnlockCost
    outreachCampaigns {
      id
      name
    }
    createdAt
    createdBy {
      firstName
      lastName
    }
  }
`;

export const CORE_UPLOADED_AUDIENCE_CSV_FIELDS = gql`
  fragment CoreUploadedAudienceCsvFields on UploadedAudienceCsv {
    id
    name
    status
    numDataRows
    rawColumnMetadata
    columnSelections
    createdAt
    createdBy {
      firstName
      lastName
    }
  }
`;

export const GEO_FILTER_OPTION_FIELDS = gql`
  fragment GeoFilterOptionFields on GeoFilter {
    state
    label
    value
    sortString
  }
`;

export const CORE_CONTENT_GROUP_FIELDS = gql`
  fragment CoreContentGroupFields on ContentGroup {
    id
    typedId
    type
    isLocked
    name
    createdAt
    outreachCampaigns {
      id
      name
    }
  }
`;

export const CORE_DIGITAL_AD_FUNDRAISING_ESTIMATE_FIELDS = gql`
  fragment CoreDigitalAdFundraisingEstimateFields on CampaignContentVariationDigitalAdFundraisingEstimate {
    id
    numDonations
    dollarsRaised
    lastModifiedAt
    createdAt
    contentVariation {
      id
      typedId
      name
      isArchived
    }
  }
`;

export const CORE_OUTREACH_CAMPAIGN_FIELDS = gql`
  ${CORE_CONTENT_GROUP_FIELDS}
  ${CORE_DIGITAL_AD_FUNDRAISING_ESTIMATE_FIELDS}
  fragment CoreOutreachCampaignFields on OutreachCampaign {
    id
    name
    status
    createdAt
    budget
    deliveryDateStart
    deliveryDateEnd
    deliveryDateTime
    actblueCode
    platform
    audience {
      id
      name
      metadata
    }
    contentGroup {
      ...CoreContentGroupFields
    }
    organization {
      id
    }
    campaignContentVariationDigitalAdFundraisingEstimates {
      ...CoreDigitalAdFundraisingEstimateFields
    }
  }
`;

export const CORE_GENERIC_CONTENT_VARIATION_FIELDS = gql`
  fragment CoreGenericContentVariationFields on ContentVariation {
    name
    id
    typedId
    type
  }
`;

// These "fragments" are not really fragments, since they don't seem to work with the standard
// fragments system.
const _CONTENT_VARIATION_TEXT_WITH_IMAGE_FIELDS = `
  text {
    id
    content
  }
  image {
    id
    url
  }
`;

const _CONTENT_VARIATION_DIRECT_MAIL_FIELDS = `
  pdf {
    id
    url
  }
`;

export const CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS = {
  DirectMail: _CONTENT_VARIATION_DIRECT_MAIL_FIELDS,
  TextMessage: _CONTENT_VARIATION_TEXT_WITH_IMAGE_FIELDS,
  DigitalAd: _CONTENT_VARIATION_TEXT_WITH_IMAGE_FIELDS,
};

export const CORE_CREDIT_TRANSACTION_FIELDS = gql`
  fragment CoreCreditTransactionFields on CreditTransaction {
    id
    transactionType
    createdAt
    numCredits
    notes
    amountPaidInCents
    volumeDiscountPercentage
    purchasedEntity {
      ... on Audience {
        id
        name
      }
      ... on AudienceExport {
        id
        audienceName
        audienceId
      }
      ... on OutreachCampaign {
        id
        outreachCampaignName: name
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    organization {
      id
    }
  }
`;
