import { Box, Typography } from "@mui/material";

import { COLORS } from "../../colors";

const VariationTextMessagePreview = ({
  name,
  imageUrl,
  textContent,
  emptyMessage = "Define your content to see a preview.",
  full = false,
}) => {
  if (emptyMessage && textContent.length <= 0) {
    return <>{emptyMessage}</>;
  }
  return (
    <Box
      sx={{
        backgroundColor: COLORS.lightTeal,
        padding: "1rem",
        typography: "body2",
        height: "100%",
      }}
    >
      {imageUrl ? (
        <img
          alt=""
          src={imageUrl}
          style={{ maxWidth: "100%", height: "auto", marginBottom: "1rem" }}
        />
      ) : null}
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "300px",
          maxHeight: full ? "none" : "300px",
          display: "block",
          whiteSpace: "pre-line",
        }}
        variant="code"
      >
        {textContent}
      </Typography>
    </Box>
  );
};

export default VariationTextMessagePreview;
