import styled from "@emotion/styled";

import loading from "../svgs/loading.svg";

const Wrapper = styled.div`
  text-align: center;
  width: 28px;
  margin: 0 ${({ center }) => (center ? "auto" : 0)};
`;

const Loading = ({ center = true }) => {
  return (
    <Wrapper center={center}>
      <img alt="Loading" src={loading} />
    </Wrapper>
  );
};

export default Loading;
