import { isEmpty, mapKeys, mapValues } from "lodash";
import { useMemo } from "react";

import { useAppState } from "../../AppStateContext";
import { valueLabelListToLookupDict } from "../../helpers";
import HorizontalPieChart from "../shared/HorizontalPieChart";

const DEMO_CATEGORIES = {
  gender: { title: "Sex", constant: "GENDERS" },
  // Age group doesn't have a corresponding constants value, we just use the value in the
  // metadata key directly.
  age_group: { title: "Age group" },
  party: { title: "Political party", constant: "PARTIES" },
  race: { title: "Race", constant: "RACES" },
};

// Returns transformed metadata ready to be used by our HorizontalPieChart component, including
// the appropriate labels. For example:
// {
//   party: {
//     Democratic: 15464,
//     Other: 54873,
//     Republican: 29663,
//   },
//   age_group: {
//     "55_": 87546,
//     "18_34": 3,
//     "35_54": 7082,
//   },
//   ...
// }
const getTransformedMetadata = (metadata, constants) => {
  const newMetadata = {};
  if (!metadata || metadata.size === 0) {
    return newMetadata;
  }
  // Transform metadata from flat dicts to dicts nested by the demo category by splitting the keys
  // by "__".
  Object.keys(metadata).forEach(k => {
    if (!k.includes("__")) {
      return;
    }
    const [demo, category] = k.split("__");
    const value = metadata[k];
    if (!newMetadata[demo]) {
      newMetadata[demo] = {};
    }
    newMetadata[demo][category] = value;
  });
  // Get the appropriate constants data for each demographic category.
  const constantLookupDicts = Object.keys(DEMO_CATEGORIES).reduce((acc, demoCatKey) => {
    const constantKey = DEMO_CATEGORIES[demoCatKey].constant;
    if (constantKey) {
      acc[demoCatKey] = valueLabelListToLookupDict(constants[constantKey]);
    }
    return acc;
  }, {});
  // Transform the keys in those dicts into "labels" (i.e. what we want to show the user)
  // based on the constants data.
  const newMetadataWithLabels = mapValues(newMetadata, (demoCatVal, demoCatKey) =>
    mapKeys(demoCatVal, (_, k) => {
      const constantsVal = constantLookupDicts[demoCatKey];
      return constantsVal ? constantsVal[k] : k;
    }),
  );
  return newMetadataWithLabels;
};

const AudienceDemographics = ({ audience }) => {
  const { constants } = useAppState();
  const metadata = useMemo(
    () => getTransformedMetadata(audience.metadata, constants),
    [audience, constants],
  );
  if (isEmpty(metadata)) {
    return;
  }
  return (
    <>
      {Object.keys(DEMO_CATEGORIES).map(demo => (
        <HorizontalPieChart data={metadata[demo]} key={demo} title={DEMO_CATEGORIES[demo].title} />
      ))}
    </>
  );
};

export default AudienceDemographics;
