import { Link } from "@mui/material";

import { COLORS } from "../../colors";

const SimpleTextButton = ({ children, ...rest }) => (
  <Link color={COLORS.lightBlue} component="button" underline="none" {...rest}>
    {children}
  </Link>
);

export default SimpleTextButton;
