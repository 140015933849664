// See https://react.dev/learn/scaling-up-with-reducer-and-context for context on this approach.
import * as Sentry from "@sentry/react";
import { cloneDeep, isEmpty, keyBy, mapValues, merge, pick } from "lodash";
import { createContext, useReducer, useContext } from "react";
import { toast } from "react-toastify";

import { AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES } from "./constants";
import { formatPercentage } from "./helpers";

export const AppStateContext = createContext(null);
export const AppStateDispatchContext = createContext(null);

// Make sure to define all top-level keys here so it's easy to see the skeleton of the app state.
const INITIAL_APP_STATE = {
  user: undefined,
  // Use a separate top-level key for admin data to keep this separate from non-admin org data.
  adminOrganizations: undefined,
  organizations: {},
  constants: undefined,
  geoFilterOptions: undefined,
  additionalAudienceIdsToPollQueue: [],
  creditTransactions: undefined,
};

const ORG_KEYS = [
  "id",
  "audiences",
  "contentGroups",
  "isExemptFromPayments",
  "name",
  "numCredits",
  "outreachCampaigns",
  "uploadedAudienceCsvs",
];

const appReducer = (appState, action) => {
  switch (action.type) {
    case "user-and-orgs-set": {
      if (action.user) {
        Sentry.configureScope(scope => {
          scope.setUser({ id: action.user.id });
        });
      } else {
        Sentry.configureScope(scope => {
          scope.setUser(null);
        });
      }

      let newOrgs;
      if (action.user && action.user.organizations) {
        newOrgs = cloneDeep(appState.organizations) || {};
        action.user.organizations.forEach(org => {
          newOrgs[org.id] = merge(newOrgs[org.id] || {}, pick(org, ORG_KEYS));
        });
      }

      return {
        ...appState,
        user: action.user,
        organizations: newOrgs || appState.organizations,
      };
    }

    case "constants-set": {
      return { ...appState, constants: transformConstants(action.constants) };
    }

    case "admin-organizations-set": {
      return {
        ...appState,
        adminOrganizations: keyBy(action.adminOrganizations, "id"),
      };
    }
    case "admin-organizations-add-membership": {
      const { newMembership, organizationId } = action;
      const newMemberships = [
        ...appState.adminOrganizations[organizationId].memberships,
        newMembership,
      ];
      return replaceAdminOrganizationMemberships({ appState, organizationId, newMemberships });
    }
    case "admin-organizations-update-membership": {
      const { updatedMembership, organizationId } = action;

      const newMemberships = appState.adminOrganizations[organizationId].memberships.map(
        membership => (membership.id === updatedMembership.id ? updatedMembership : membership),
      );
      return replaceAdminOrganizationMemberships({ appState, organizationId, newMemberships });
    }
    case "admin-organizations-remove-membership": {
      const { membershipId, organizationId } = action;

      const newMemberships = appState.adminOrganizations[organizationId].memberships.filter(
        membership => membership.id !== membershipId,
      );
      return replaceAdminOrganizationMemberships({ appState, organizationId, newMemberships });
    }

    case "geo-filter-options-set": {
      const { geoFilterOptions } = action;
      return { ...appState, geoFilterOptions };
    }

    case "current-audience-id-set": {
      const { currentAudienceId } = action;
      return { ...appState, currentAudienceId };
    }

    case "org-set-audiences-and-csvs": {
      const { organization } = action;

      const newOrg = merge(
        cloneDeep(appState.organizations[organization.id]) || {},
        pick(organization, ORG_KEYS),
      );

      newOrg.audiences.forEach(audience =>
        toastIfAudienceExitingPendingState({ appState, organizationId: organization.id, audience }),
      );

      return {
        ...appState,
        organizations: {
          ...appState.organizations,
          [organization.id]: { ...newOrg, audiences: getAudiencesRelationships(newOrg.audiences) },
        },
      };
    }

    case "org-add-or-update-audience": {
      const { organizationId, audience } = action;
      return addOrUpdateAudienceForOrg({ appState, organizationId, audience });
    }

    case "org-add-or-update-audiences": {
      const { organizationId, audiences } = action;
      let newState = appState;
      audiences.forEach(audience => {
        newState = addOrUpdateAudienceForOrg({ appState: newState, organizationId, audience });
        if (audience.otherVersions) {
          audience.otherVersions.forEach(version => {
            newState = addOrUpdateAudienceForOrg({
              appState: newState,
              organizationId,
              audience: version,
            });
          });
        }
      });
      return newState;
    }

    case "org-set-content-groups": {
      return setRecordTypeForOrg({
        appState,
        newOrgObject: action.organization,
        recordType: "contentGroups",
      });
    }

    case "org-add-content-group": {
      const { contentGroup } = action;
      const newOrgs = mapValues(appState.organizations, org => ({
        ...org,
        contentGroups: org.contentGroups.concat(contentGroup),
      }));

      return {
        ...appState,
        organizations: newOrgs,
      };
    }

    case "org-update-content-group": {
      const { contentGroup } = action;
      const newOrgs = mapValues(appState.organizations, org => ({
        ...org,
        contentGroups: org.contentGroups.map(cg =>
          // We have to preserve the existing variations for the content group we're updating
          // because we aren't necessarily fetching those.
          cg.typedId === contentGroup.typedId
            ? { ...contentGroup, variations: contentGroup.variations || cg.variations }
            : cg,
        ),
      }));

      return {
        ...appState,
        organizations: newOrgs,
      };
    }

    // @todo could improve performance with multiple orgs by specifying the org in the dispatch.
    case "org-delete-variation": {
      const { contentVariationTypedId } = action;
      const newOrgs = mapValues(appState.organizations, org => ({
        ...org,
        contentGroups: org.contentGroups.map(cg => ({
          ...cg,
          variations: cg.variations.filter(
            variation => variation.typedId !== contentVariationTypedId,
          ),
        })),
      }));

      return {
        ...appState,
        organizations: newOrgs,
      };
    }

    // @todo could improve performance with multiple orgs by specifying the org in the dispatch.
    case "org-add-variation-to-content-group": {
      const { contentGroupTypedId, variation } = action;
      const newOrgs = mapValues(appState.organizations, org => ({
        ...org,
        contentGroups: org.contentGroups.map(cg => ({
          ...cg,
          variations:
            cg.typedId === contentGroupTypedId
              ? (cg.variations || []).concat(variation)
              : cg.variations,
        })),
      }));

      return {
        ...appState,
        organizations: newOrgs,
      };
    }

    case "org-set-outreach-campaigns": {
      return setRecordTypeForOrg({
        appState,
        newOrgObject: action.organization,
        recordType: "outreachCampaigns",
      });
    }

    case "org-set-credit-transactions": {
      const { creditTransactions, organizationId } = action;
      return {
        ...appState,
        organizations: {
          ...appState.organizations,
          [organizationId]: { ...appState.organizations[organizationId], creditTransactions },
        },
      };
    }

    case "org-add-or-update-outreach-campaign": {
      const { organizationId, outreachCampaign } = action;
      return addOrUpdateOutreachCampaignForOrg({ appState, organizationId, outreachCampaign });
    }

    case "org-update-num-credits": {
      const { organizationId, numCredits } = action;
      return {
        ...appState,
        organizations: {
          ...appState.organizations,
          [organizationId]: { ...appState.organizations[organizationId], numCredits },
        },
      };
    }

    case "additional-audiences-to-poll-queue-add": {
      const { audienceIdsToPoll } = action;
      const { additionalAudienceIdsToPollQueue } = appState;
      return {
        ...appState,
        additionalAudienceIdsToPollQueue:
          additionalAudienceIdsToPollQueue.concat(audienceIdsToPoll),
      };
    }

    case "additional-audiences-to-poll-queue-remove": {
      const { audienceIdsToRemove } = action;
      const { additionalAudienceIdsToPollQueue } = appState;
      return {
        ...appState,
        additionalAudienceIdsToPollQueue: additionalAudienceIdsToPollQueue.filter(
          a => !audienceIdsToRemove.includes(a),
        ),
      };
    }

    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

// Helpers
const transformCreditPricingTiersConstant = creditPricingTiers => {
  const basePrice = creditPricingTiers["0"];

  const getVolumeDiscountPercentage = pricePerCredit =>
    parseInt(100 - (pricePerCredit / basePrice) * 100);

  return mapValues(creditPricingTiers, pricePerCredit => {
    const volumeDiscountPercentage = getVolumeDiscountPercentage(pricePerCredit);
    return {
      pricePerCredit,
      volumeDiscountPercentage: volumeDiscountPercentage,
      volumeDiscountDisplay: volumeDiscountPercentage
        ? ` (${formatPercentage({ value: volumeDiscountPercentage / 100 })} volume discount)`
        : "",
    };
  });
};

const transformConstants = constants => {
  const { CREDIT_PRICING_TIERS } = constants;

  return {
    ...constants,
    CREDIT_PRICING_TIERS: transformCreditPricingTiersConstant(CREDIT_PRICING_TIERS),
  };
};

const replaceAdminOrganizationMemberships = ({ appState, organizationId, newMemberships }) => {
  const org = appState.adminOrganizations[organizationId];
  return {
    ...appState,
    adminOrganizations: {
      ...appState.adminOrganizations,
      [organizationId]: { ...org, memberships: newMemberships },
    },
  };
};

const addOrUpdateOrgRecord = ({ appState, organizationId, recordTypePlural, newRecord }) => {
  const org = appState.organizations[organizationId] || {};
  let newRecords = [...(org[recordTypePlural] || [])];
  const idx = newRecords.findIndex(r => r.id === newRecord.id);
  if (idx >= 0) {
    // Merge the old object with the new one: if the old one has some fields that the new one
    // doesn't, we keep the data from the old record rather than discarding it.
    newRecords[idx] = { ...newRecords[idx], ...newRecord };
  } else {
    newRecords.push(newRecord);
  }

  if (recordTypePlural === "audiences") {
    newRecords = getAudiencesRelationships(newRecords);
  }

  return {
    ...appState,
    organizations: {
      ...appState.organizations,
      [organizationId]: {
        ...org,
        [recordTypePlural]: newRecords,
      },
    },
  };
};

const setRecordTypeForOrg = ({ appState, newOrgObject, recordType }) => {
  const oldOrg = appState.organizations[newOrgObject.id];
  const newOrgState = isEmpty(oldOrg) ? pick(newOrgObject, "id") : cloneDeep(oldOrg);

  const newRecords = newOrgObject[recordType];

  return {
    ...appState,
    organizations: {
      ...appState.organizations,
      [newOrgState.id]: { ...newOrgState, [recordType]: newRecords },
    },
  };
};

const getAudiencesRelationships = audiences => {
  // reverseRelationships ends up being an object like:
  // {audienceIdsCopiedAndEditedTo: {3: [4, 5, 6], 7: [1, 3]}, audienceIdsFilteredTo: ...}
  // where all the integers are audience IDs.
  // First copy `audiences` since it might not already be a copy of app state.
  // @todo-aviv clean this up.
  let newAudiences = cloneDeep(audiences);
  const reverseRelationships = {};
  Object.values(AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES).forEach(reverseName => {
    reverseRelationships[reverseName] = {};
  });
  newAudiences.forEach(a => {
    Object.keys(AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES).forEach(name => {
      if (a[name]) {
        const reverseName = AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES[name];
        reverseRelationships[reverseName][a[name].id] = (
          reverseRelationships[reverseName][a[name].id] || []
        ).concat(a.id);
      }
    });
  });
  newAudiences = newAudiences.reduce((acc, a) => {
    Object.keys(reverseRelationships).forEach(reverseName => {
      a[reverseName] = reverseRelationships[reverseName][a.id] || [];
    });
    return acc.concat([a]);
  }, []);

  // For every audience, find other versions of the same audience and then store those that are:
  // 1. the current version or 2. the pending version so those are accessible.
  newAudiences = newAudiences.map(audience => {
    const otherVersions = newAudiences.filter(
      a =>
        // It's the original audience.
        (audience.audienceRegeneratedFrom && a.id === audience.audienceRegeneratedFrom.id) ||
        (a.audienceRegeneratedFrom &&
          // It was regenerated from this audience.
          (a.audienceRegeneratedFrom.id === audience.id ||
            // It was regenerated from the same audience as this audience.
            (audience.audienceRegeneratedFrom &&
              a.audienceRegeneratedFrom.id === audience.audienceRegeneratedFrom.id))),
    );
    audience.pendingVersion = pickKeyAudienceFields(
      otherVersions.find(a => a.status === "PENDING"),
    );
    audience.currentVersion = pickKeyAudienceFields(
      !audience.isCurrentVersion && otherVersions.find(a => a.isCurrentVersion),
    );

    return audience;
  });

  return newAudiences;
};

const addOrUpdateAudienceForOrg = ({ appState, organizationId, audience }) => {
  toastIfAudienceExitingPendingState({ appState, organizationId, audience });

  let newState = addOrUpdateOrgRecord({
    appState,
    organizationId,
    recordTypePlural: "audiences",
    newRecord: audience,
  });
  if (audience.uploadedAudienceCsv) {
    // If the fetched audience has a CSV record, put that information directly in the org's
    // uploadedAudienceCsv list also.
    // @todo because we're doing this, there's no reason for the audience record to store
    // anything about the CSV other than its ID -- it wastes memory and, more importantly, it
    // puts us at risk of having inconsistent data in the two locations.
    newState = addOrUpdateOrgRecord({
      appState: newState,
      organizationId,
      recordTypePlural: "uploadedAudienceCsvs",
      newRecord: audience.uploadedAudienceCsv,
    });
  }
  return newState;
};

// If the client side has just learned that a formerly pending audience is no longer pending, we
// want to notify the user.
const toastIfAudienceExitingPendingState = ({ appState, organizationId, audience }) => {
  if (audience.status === "PENDING") {
    return;
  }

  const org = appState.organizations[organizationId];

  if (!org || !org.audiences) {
    return;
  }

  const oldAudience = org.audiences.find(a => a.id === audience.id);

  if (!oldAudience || oldAudience.status !== "PENDING") {
    return;
  }

  if (audience.status === "ACTIVE" && !audience.isPreview) {
    const newVersionCopy = audience.audienceRegeneratedFrom ? " new version of" : "";
    toast(`The${newVersionCopy} audience "${audience.name}" is now active.`, { type: "success" });
  } else if (audience.status === "ERROR") {
    // If we're already on the detail page for this audience (or about to redirect to it), an error
    // toast would be redundant.
    //
    // One exception: if it's a CSV upload and the CSV itself failed, we're not going to redirect
    // to the audience page, so we actually should toast here. Doing that check here is a little
    // dodgy because usually we get details about an audience's CSV by just taking the ID and then
    // finding the top-level CSV record in the global store... but in this case the updated info on
    // the CSV hasn't been saved into appState yet. We work around that by fetching the CSV's status
    // field in the version of the record that's nested under the audience (see
    // CORE_AUDIENCE_FIELDS).
    if (
      audience.id !== appState.currentAudienceId ||
      (audience.uploadedAudienceCsv && audience.uploadedAudienceCsv.status === "ERROR")
    ) {
      const msg =
        audience.errorInfoUserFacing ||
        `Something went wrong while creating the audience${
          audience.name ? ` "${audience.name}"` : ""
        }.`;
      toast(msg, { type: "error" });
    }
  }
};

const addOrUpdateOutreachCampaignForOrg = ({ appState, organizationId, outreachCampaign }) => {
  return addOrUpdateOrgRecord({
    appState,
    organizationId,
    recordTypePlural: "outreachCampaigns",
    newRecord: outreachCampaign,
  });
};

// This could potentially lead to perf issues if we have a lot of records and/or nested
// objects/arrays, so let's keep an eye on it and consider using a different approach if needed.
function deepFreeze(obj) {
  Object.getOwnPropertyNames(obj).forEach(prop => {
    const value = obj[prop];
    if (value && typeof value === "object" && !Object.isFrozen(value)) {
      deepFreeze(value);
    }
  });
  return Object.freeze(obj);
}

function pickKeyAudienceFields(audience) {
  if (!audience) {
    return null;
  }
  return pick(audience, ["id", "name"]);
}

export function AppStateProvider({ children }) {
  const [appState, dispatch] = useReducer(appReducer, INITIAL_APP_STATE);
  const frozenAppState = deepFreeze(appState);

  return (
    <AppStateContext.Provider value={frozenAppState}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  return useContext(AppStateContext);
}

export function useAppStateDispatch() {
  return useContext(AppStateDispatchContext);
}
