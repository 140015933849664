import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { useAppState } from "../../AppStateContext";
import { CONTENT_GROUP_TYPES } from "../../constants";
import {
  formatDate,
  formatDateTime,
  userFriendlyNumber,
  userFriendlyWholeDollars,
} from "../../helpers";
import useGetOutreachCampaignFromParam from "../../hooks/useGetOutreachCampaignFromParam";
import TextLabel from "../shared/TextLabel";

import CampaignFundraisingEstimatesSection from "./CampaignFundraisingEstimatesSection";
import OutreachCampaignsHeader from "./OutreachCampaignsHeader";

const ContentGroupDetail = () => {
  const outreachCampaign = useGetOutreachCampaignFromParam();
  const { constants } = useAppState();

  if (!outreachCampaign) {
    return "Campaign not found.";
  }

  const contentType = outreachCampaign.contentGroup.type;
  const isDigitalAdCampaign = contentType === "DigitalAd";

  return (
    <>
      <Box maxWidth="md">
        <OutreachCampaignsHeader />
        <Typography variant="h2">{outreachCampaign.name}</Typography>

        <Grid container rowSpacing={2} sx={{ marginBottom: "2rem" }}>
          <Grid item xs={6}>
            <TextLabel>Type: </TextLabel>
            {CONTENT_GROUP_TYPES[contentType].name}
          </Grid>
          {outreachCampaign.budget ? (
            <Grid item xs={6}>
              <TextLabel>Budget: </TextLabel>
              {userFriendlyWholeDollars(outreachCampaign.budget)}
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <TextLabel>Created: </TextLabel> {formatDateTime(outreachCampaign.createdAt)}
          </Grid>
          <Grid item xs={6}>
            <TextLabel>Audience: </TextLabel>
            <Link to={`/audiences/${outreachCampaign.audience.id}`}>
              {outreachCampaign.audience.name}
            </Link>
          </Grid>
          <Grid item xs={6}>
            <TextLabel>Content: </TextLabel>
            <Link to={`/content/group/${outreachCampaign.contentGroup.typedId}`}>
              {outreachCampaign.contentGroup.name}
            </Link>
          </Grid>
          <Grid item xs={6}>
            <TextLabel>Audience Size: </TextLabel>
            {userFriendlyNumber(outreachCampaign.audience.metadata.size)}
          </Grid>
          {outreachCampaign.deliveryDateStart ? (
            <Grid item xs={6}>
              <TextLabel>Delivery date: </TextLabel>
              From {formatDate(outreachCampaign.deliveryDateStart)} to{" "}
              {formatDate(outreachCampaign.deliveryDateEnd)}
            </Grid>
          ) : null}
          {outreachCampaign.deliveryDateTime ? (
            <Grid item xs={6}>
              <TextLabel>Delivery date: </TextLabel>
              {formatDateTime(outreachCampaign.deliveryDateTime)}
            </Grid>
          ) : null}
          {outreachCampaign.actblueCode ? (
            <Grid item xs={6}>
              <TextLabel>ActBlue code: </TextLabel>
              {outreachCampaign.actblueCode}
            </Grid>
          ) : null}
          {outreachCampaign.platform ? (
            <Grid item xs={6}>
              <TextLabel>{CONTENT_GROUP_TYPES[contentType].platformLabelDisplay}: </TextLabel>
              {
                constants.CAMPAIGN_PLATFORMS[contentType].find(
                  p => p.value === outreachCampaign.platform,
                ).label
              }
            </Grid>
          ) : null}
        </Grid>

        {isDigitalAdCampaign ? (
          <CampaignFundraisingEstimatesSection
            audienceSize={outreachCampaign.audience.metadata.size}
            budget={outreachCampaign.budget}
            estimates={outreachCampaign.campaignContentVariationDigitalAdFundraisingEstimates}
            outreachCampaign={outreachCampaign}
          />
        ) : null}
      </Box>
    </>
  );
};

export default ContentGroupDetail;
