import { useParams } from "react-router-dom";

import useGetActiveOrg from "./useGetActiveOrg";

const useGetContentGroupFromParam = () => {
  const { contentGroupTypedId } = useParams();
  const { organization } = useGetActiveOrg();
  const { contentGroups } = organization;
  return contentGroups && contentGroups.find(cg => cg.typedId === contentGroupTypedId);
};

export default useGetContentGroupFromParam;
