import { Box, Typography } from "@mui/material";

const Help = () => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        <Typography>
          If you need help with anything,{"  "}
          <a href="mailto:help@indigo.engineering">send us an email</a>.
        </Typography>
      </Box>
    </>
  );
};

export default Help;
