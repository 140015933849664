import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTopOnPathChange = () => {
  const { pathname } = useLocation();
  const prevPathnameRef = useRef();

  useEffect(() => {
    const prevPathname = prevPathnameRef.current;
    prevPathnameRef.current = pathname;

    if (!prevPathname) {
      return;
    }

    let dontScrollToTop;
    // This is just an example (we don't actually want to make this an exception),
    // but we can add in any exceptions here as needed.
    // if (
    //   prevPathname.startsWith("/audiences/filters") &&
    //   pathname.startsWith("/audiences/filters")
    // ) {
    //   dontScrollToTop = true;
    // }

    if (!dontScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};

export default useScrollToTopOnPathChange;
