import { gql, useQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";

import HeatMap from "../shared/HeatMap";
import Loading from "../shared/Loading";

const GET_AUDIENCE_SAMPLE_DATA = gql`
  query getAudienceSampleData($audienceId: ID!) {
    audience(pk: $audienceId) {
      id
      sampleData {
        id
        coordinates
      }
    }
  }
`;

const AudienceHeatMap = ({ audience }) => {
  const { loading, data } = useQuery(GET_AUDIENCE_SAMPLE_DATA, {
    fetchPolicy: "cache-first",
    variables: { audienceId: audience.id },
  });

  const coordinates = useMemo(() => {
    if (data) {
      if (data.audience.sampleData) {
        return data.audience.sampleData.coordinates.map(pair => ({
          latitude: pair[0],
          longitude: pair[1],
        }));
      } else {
        return [];
      }
    }
  }, [data]);

  // @todo Decide when to limit the map bounds artificially. The main case that's problematic is
  // when there are a number of samples in the continental U.S., but also a small number in AK or
  // HI. Tweaking the `outlierPercentage` prop in `HeatMap` may be an adequate solution.

  if (loading || !data) {
    return <Loading />;
  }

  if (coordinates.length) {
    return <HeatMap locations={coordinates} notEnoughDataThreshold={0} />;
  } else {
    return <Typography>No location data is available for this audience.</Typography>;
  }
};

export default AudienceHeatMap;
