import useGetActiveOrg from "./useGetActiveOrg";

const useGetCurrentVersionOfAudienceFilteredFrom = () => {
  const { organization } = useGetActiveOrg();
  const { audiences } = organization;

  const getCurrentVersionOfAudienceFilteredFrom = audience => {
    if (!audience.audienceFilteredFrom) {
      return null;
    }
    // We have to get the actual audience record because `currentVersion` isn't present in the
    // sparse `audienceFilteredFrom` dict.
    const audienceFilteredFrom = audiences.find(a => a.id === audience.audienceFilteredFrom.id);
    if (!audienceFilteredFrom.currentVersion) {
      return null;
    }
    // Similarly, we have to get the actual audience record here because `canCreateLookalikeFrom`
    // isn't present in the sparse `currentVersion` dict.
    return audiences.find(a => a.id === audienceFilteredFrom.currentVersion.id);
  };

  return getCurrentVersionOfAudienceFilteredFrom;
};

export default useGetCurrentVersionOfAudienceFilteredFrom;
