import { gql, useQuery } from "@apollo/client";
import { Outlet } from "react-router-dom";

import { useAppStateDispatch } from "../AppStateContext";
import { CORE_CREDIT_TRANSACTION_FIELDS } from "../fragments";
import useGetActiveOrg from "../hooks/useGetActiveOrg";

const GET_ORG_CREDIT_TRANSACTIONS = gql`
  ${CORE_CREDIT_TRANSACTION_FIELDS}
  query getOrganizationCreditTransactions($organizationId: ID!) {
    organization(pk: $organizationId) {
      numCredits
      id
      creditTransactions {
        ...CoreCreditTransactionFields
      }
    }
  }
`;

const CreditsData = () => {
  const { organization } = useGetActiveOrg();
  const dispatch = useAppStateDispatch();

  useQuery(GET_ORG_CREDIT_TRANSACTIONS, {
    variables: { organizationId: organization.id },
    onCompleted: data => {
      // This is needed for when we redirect here from CreditsReturn after purchasing credits, in
      // which case we need to update the org credits to reflect the latest.
      dispatch({
        type: "org-update-num-credits",
        organizationId: data.organization.id,
        numCredits: data.organization.numCredits,
      });
      dispatch({
        type: "org-set-credit-transactions",
        organizationId: data.organization.id,
        creditTransactions: data.organization.creditTransactions,
      });
    },
  });

  return <Outlet />;
};

export default CreditsData;
