import { CONTENT_GROUP_TYPES } from "../constants";

import useGetActiveOrg from "./useGetActiveOrg";

const useGetCurrentContentGroups = () => {
  const { organization } = useGetActiveOrg();
  const { contentGroups } = organization;
  // @todo-content add some filters here.
  return contentGroups.filter(cg => Object.keys(CONTENT_GROUP_TYPES).includes(cg.type));
};

export default useGetCurrentContentGroups;
