import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal } from "@mui/material";

const ModalCloseButton = ({ callback }) => (
  <IconButton
    aria-label="close"
    onClick={callback}
    sx={{ position: "absolute", right: "8px", top: "8px" }}
  >
    <CloseIcon />
  </IconButton>
);

const getModalBoxStyle = ({ width }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: width || 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  padding: 4,
  maxHeight: "calc(100vh - 50px)",
  overflowY: "auto",
});

// @todo consider only rendering this from where it's used when it's isOpen=true, i.e. use whether
// it's rendered or not to determine whether to show it or not. This can make it easier to manage
// whether it's displayed or not, and you don't have to worry about "lingering state".
const SharedModal = ({ setIsOpen, isOpen, showCloseButton = true, width = 400, children }) => {
  const closeModal = () => setIsOpen(false);
  return (
    <Modal onClose={closeModal} open={isOpen}>
      <Box sx={getModalBoxStyle({ width })}>
        {showCloseButton ? <ModalCloseButton callback={closeModal} /> : null}
        {children}
      </Box>
    </Modal>
  );
};

export default SharedModal;
