import { gql, useMutation } from "@apollo/client";
import { Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { MIN_PASSWORD_LENGTH } from "../../constants";
import { LOGGED_IN_USER_FIELDS } from "../../fragments";
import { toastWithAdditionalMessages } from "../../helpers";

import CreatePasswordInput from "./CreatePasswordInput";

const RESET_PASSWORD = gql`
  ${LOGGED_IN_USER_FIELDS}
  mutation resetPassword($password: String!, $uid: String!, $token: String!) {
    resetPassword(password: $password, uid: $uid, token: $token) {
      ... on LoginSuccess {
        user {
          ...RootUserFields
        }
      }
      ... on LoginError {
        message
        additionalMessages
      }
    }
  }
`;

const ResetPassword = () => {
  const { user } = useAppState();
  const navigate = useNavigate();
  const dispatch = useAppStateDispatch();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");

  // This ensures that we only redirect after the dispatch completes and the context is updated.
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const [doResetPasswordMutation, { loading }] = useMutation(RESET_PASSWORD);

  const handleResetPassword = event => {
    event.preventDefault();
    doResetPasswordMutation({
      variables: { password, uid: searchParams.get("uid"), token: searchParams.get("token") },
      onCompleted: data => {
        if (data.resetPassword.user) {
          dispatch({ type: "user-and-orgs-set", user: data.resetPassword.user });
          toast("Password has been reset.", { type: "success" });
        } else if (data.resetPassword.message) {
          toastWithAdditionalMessages(
            data.resetPassword.message,
            data.resetPassword.additionalMessages,
            {
              type: "error",
            },
          );
        }
      },
    });
  };

  const submitEnabled = password.length >= MIN_PASSWORD_LENGTH;
  const resetPasswordForm = (
    <form onSubmit={handleResetPassword}>
      <CreatePasswordInput {...{ password, setPassword }} disabled={loading} />
      <Button disabled={!submitEnabled || loading} size="large" type="submit">
        {loading ? "Loading..." : "Reset password"}
      </Button>
    </form>
  );

  return (
    <>
      <Typography variant="h2">Reset password</Typography>
      {resetPasswordForm}
    </>
  );
};
export default ResetPassword;
