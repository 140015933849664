import { gql, useMutation } from "@apollo/client";
import { Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { LOGGED_IN_USER_FIELDS } from "../../fragments";

const LOGIN = gql`
  ${LOGGED_IN_USER_FIELDS}
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ... on LoginSuccess {
        user {
          ...RootUserFields
        }
      }
      ... on LoginError {
        message
      }
    }
  }
`;

const Login = () => {
  const { user } = useAppState();
  const dispatch = useAppStateDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [doLoginMutation, { loading }] = useMutation(LOGIN);

  // User is already logged in
  if (user) {
    return <Navigate to="/" />;
  }

  const handleLogin = event => {
    event.preventDefault();
    doLoginMutation({
      variables: { email, password },
      onCompleted: data => {
        if (data.login.user) {
          dispatch({ type: "user-and-orgs-set", user: data.login.user });
          toast("Login successful.", { type: "success" });
        } else if (data.login.message) {
          toast(data.login.message, { type: "error" });
        }
      },
    });
  };

  const loginForm = (
    <>
      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          autoComplete="email"
          disabled={loading}
          label="Email"
          margin="dense"
          name="email"
          onChange={e => setEmail(e.target.value)}
          type="email"
          value={email}
        />
        <TextField
          fullWidth
          autoComplete="current-password"
          disabled={loading}
          label="Password"
          margin="dense"
          name="password"
          onChange={e => setPassword(e.target.value)}
          type="password"
          value={password}
        />
        <Button disabled={loading} size="large" type="submit">
          {loading ? "Loading..." : "Login"}
        </Button>
      </form>
      <Typography>
        Or <Link to="/forgot_password">forgot password?</Link>
      </Typography>
    </>
  );

  return (
    <>
      <Typography variant="h2">Login</Typography>
      {loginForm}
    </>
  );
};
export default Login;
