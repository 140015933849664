import useGetActiveOrg from "./useGetActiveOrg";

const useGetCurrentOutreachCampaigns = () => {
  const { organization } = useGetActiveOrg();
  const { outreachCampaigns } = organization;
  // @todo add some filters here.
  return outreachCampaigns;
};

export default useGetCurrentOutreachCampaigns;
