import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const MatchbookDatePicker = ({ isDateTimePicker = false, ...props }) => {
  const PickerComponent = isDateTimePicker ? DateTimePicker : DatePicker;
  return (
    <PickerComponent
      {...props}
      sx={{
        "& .MuiPickersDay-root": {
          fontSize: "0.75rem",
          lineHeight: "1.5",
        },
        "& .MuiInputBase-input": {
          paddingTop: "9px",
          paddingBottom: "7px",
        },
        "& .MuiInputLabel-root:not([data-shrink=true])": {
          marginTop: "-7px",
        },
      }}
    />
  );
};

export default MatchbookDatePicker;
