import { Navigate, createRoutesFromElements, Route } from "react-router-dom";

import App from "./App";
import ContentGroupAndCampaignData from "./components/ContentGroupAndCampaignData";
import CreditTransactions from "./components/CreditTransactions";
import CreditsData from "./components/CreditsData";
import CreditsReturn from "./components/CreditsReturn";
import Dashboard from "./components/Dashboard";
import Experiments from "./components/Experiments";
import Help from "./components/Help";
import PurchaseCredits from "./components/PurchaseCredits";
import AccountWrapper from "./components/account/AccountWrapper";
import ForgotPassword from "./components/account/ForgotPassword";
import Login from "./components/account/Login";
import RequireAuth from "./components/account/RequireAuth";
import RequireStaff from "./components/account/RequireStaff";
import ResetPassword from "./components/account/ResetPassword";
import Signup from "./components/account/Signup";
import CreateOrg from "./components/admin/CreateOrg";
import OrgAdmin from "./components/admin/OrgAdmin";
import ApplyAudienceFilters from "./components/audience/ApplyAudienceFilters";
import AudienceData from "./components/audience/AudienceData";
import AudienceDetail from "./components/audience/AudienceDetail";
import AudienceList from "./components/audience/AudienceList";
import CreateAudience from "./components/audience/CreateAudience";
import CreateLookalike from "./components/audience/CreateLookalike";
import UploadNewAudienceCsv from "./components/audience/UploadNewAudienceCsv";
import ContentGroupDetail from "./components/content/ContentGroupDetail";
import ContentGroupList from "./components/content/ContentGroupList";
import ContentVariationDetail from "./components/content/ContentVariationDetail";
import CreateContentGroup from "./components/content/CreateContentGroup";
import CreateContentVariation from "./components/content/CreateContentVariation";
import CreateOutreachCampaign from "./components/outreach_campaign/CreateOutreachCampaign";
import OutreachCampaignDetail from "./components/outreach_campaign/OutreachCampaignDetail";
import OutreachCampaignList from "./components/outreach_campaign/OutreachCampaignList";

export const routes = createRoutesFromElements(
  <Route element={<App />} path="/">
    {/* @todo actual 404 page */}
    <Route element={<p>Not found</p>} path="*" />

    <Route element={<AccountWrapper />}>
      <Route element={<Login />} path="login" />
      <Route element={<Signup />} path="accept_invite/:inviteCode" />
      <Route element={<ForgotPassword />} path="forgot_password" />
      <Route element={<ResetPassword />} path="reset_password" />
    </Route>

    <Route element={<RequireAuth />}>
      <Route element={<AudienceData />}>
        <Route element={<Dashboard />} path="/" />
        <Route path="/audiences">
          <Route element={<AudienceList />} path="" />
          <Route element={<AudienceDetail />} path=":audienceId" />
          <Route element={<CreateAudience />}>
            <Route element={<UploadNewAudienceCsv />} path="match" />
            <Route element={<ApplyAudienceFilters />} path="filters/:audienceId?" />
            <Route element={<CreateLookalike />} path="expand/:audienceId?" />
          </Route>
        </Route>
        <Route element={<ContentGroupAndCampaignData />}>
          <Route path="/content">
            <Route element={<ContentGroupList />} path="" />
            <Route element={<ContentGroupDetail />} path="group/:contentGroupTypedId" />
            <Route element={<CreateContentGroup />} path="group/new/:type"></Route>
            <Route element={<ContentVariationDetail />} path="variation/:contentVariationTypedId" />
            <Route
              element={<CreateContentVariation />}
              path="variation/new/:contentGroupTypedId/:contentVariationTypedId?"
            />
          </Route>
          <Route path="/campaigns">
            <Route element={<OutreachCampaignList />} path="" />
            <Route element={<OutreachCampaignDetail />} path=":outreachCampaignId" />
            <Route
              element={<CreateOutreachCampaign />}
              path="new/audience/:audienceId/content/:contentGroupTypedId"
            />
            <Route element={<CreateOutreachCampaign />} path="new/audience/:audienceId" />
            <Route element={<CreateOutreachCampaign />} path="new/content/:contentGroupTypedId" />
            <Route element={<CreateOutreachCampaign />} path="new" />
          </Route>
        </Route>
        <Route element={<Experiments />} path="/experiments" />
        <Route element={<Help />} path="/help" />
        <Route element={<CreditsData />}>
          <Route element={<PurchaseCredits />} path="/credits/purchase/:returnPath?" />
          <Route element={<CreditTransactions />} path="/credits/transactions" />
        </Route>
        <Route element={<CreditsReturn />} path="/credits/return/:sessionId/:returnPath?" />
      </Route>

      <Route element={<RequireStaff />}>
        {/* If/when we have other admin pages, we can make `admin` a separate top-level page */}
        <Route element={<Navigate to="/admin/org" />} path="admin" />
        <Route element={<CreateOrg />} path="/admin/org/create" />
        <Route element={<OrgAdmin />} path="/admin/org/:orgId?" />
      </Route>
    </Route>
  </Route>,
);
