import { useCallback, useEffect } from "react";

import { useAppStateDispatch } from "../AppStateContext";

const useMaintainCurrentAudienceId = currentAudienceId => {
  const dispatch = useAppStateDispatch();

  const update = useCallback(
    value => dispatch({ type: "current-audience-id-set", currentAudienceId: value }),
    [dispatch],
  );

  // This keeps the app state in sync with the provided value.
  useEffect(() => update(currentAudienceId), [currentAudienceId, update]);

  // This will unset the value on unmount of the component that's using this hook.
  // (Note that the effect is not calling update directly but rather returning a cleanup function
  // that will call update.)
  useEffect(() => () => update(null), [update]);
};

export default useMaintainCurrentAudienceId;
