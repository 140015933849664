import { TextField } from "@mui/material";
import { useState } from "react";

import { userFriendlyNumber } from "../../helpers";

// Common additional props to pass: `label`.
// It's only necessary to pass `displayValue` and `setDisplayValue` if you need access to them
// from the parent component and want to manage that state there. If they're not passed, we'll
// manage that state from here.
const NumericField = ({
  value,
  setValue,
  maxLength = 10,
  displayValue,
  setDisplayValue,
  ...rest
}) => {
  const [displayValueInternal, setDisplayValueInternal] = useState("");
  const displayValueToUse = displayValue || displayValueInternal;
  const setDisplayValueToUse = setDisplayValue || setDisplayValueInternal;

  const handleChange = e => {
    const inputValue = e.target.value.replace(/,/g, "");
    if (!isNaN(inputValue)) {
      setValue(parseInt(inputValue));
      setDisplayValueToUse(inputValue);
    }
  };

  const handleBlur = () => {
    setDisplayValueToUse(value ? userFriendlyNumber(value) : "");
  };

  const handleFocus = () => {
    setDisplayValueToUse(value ? value.toString() : "");
  };

  return (
    <TextField
      inputProps={{
        inputMode: "numeric", // This just suggests a numeric keyboard on devices with them.
        maxLength: maxLength,
      }}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      size="small"
      value={displayValueToUse}
      {...rest}
    />
  );
};

export default NumericField;
