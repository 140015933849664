import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import pluralize from "pluralize";

import { COLORS } from "../../colors";
import {
  formatPercentage,
  userFriendlyDollarsRoundedToSignificantDigits,
  userFriendlyRoundNumber,
} from "../../helpers";
import TextLabel from "../shared/TextLabel";

const PROFIT_THRESHOLD = 3;

// @todo circle back on this -- used with the chart below
// const EstimateBar = ({ title, value, typicalMultiplier = 0.75, formatFn = val => val }) => {
//   const predictedWidth = 80;
//   const typicalWidth = predictedWidth * typicalMultiplier;

//   return (
//     <Box>
//       <Typography variant="h3">{title}</Typography>
//       <Grid container alignItems="center" rowSpacing={0}>
//         <Grid item sx={{ fontSize: "small" }} xs={3}>
//           Predicted: {formatFn(value)}
//         </Grid>
//         <Grid item xs={9}>
//           <Box sx={{ backgroundColor: COLORS.cyan, height: "20px", width: `${predictedWidth}%` }} />
//         </Grid>
//         <Grid item sx={{ fontSize: "small" }} xs={3}>
//           Typical: {formatFn(value * typicalMultiplier)}
//         </Grid>
//         <Grid item xs={9}>
//           <Box sx={{ backgroundColor: COLORS.orange, height: "20px", width: `${typicalWidth}%` }} />
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

const CampaignFundraisingEstimate = ({
  isPlaceholder = false,
  budget,
  audienceSize,
  estimate,
  outreachCampaign,
}) => {
  const placeholderString = "█████";
  let profitValue, roiValue, grossReturnValue, reachValue;
  let grossReturnErrorValue, donorsErrorValue;
  if (isPlaceholder) {
    profitValue = `$${placeholderString}`;
    roiValue = `${placeholderString}%`;
    reachValue = `${placeholderString} unique people`;
    grossReturnValue = `${placeholderString} from ${placeholderString} unique donors`;
  } else {
    const { dollarsRaised, numDonations } = estimate;

    const grossReturn = dollarsRaised;
    const profit = grossReturn - budget;
    const roi = (grossReturn - budget) / budget;
    const donors = numDonations;

    // The next few lines (until the next empty line) assume it's a digital ad campaign.
    // We'll need to diverge once we have estimates for other types of campaigns.
    const durationInHours = dayjs(outreachCampaign.deliveryDateEnd).diff(
      dayjs(outreachCampaign.deliveryDateStart),
      "hour",
    );
    // @todo this shouldn't be the same as reachStart...
    // const baseReach =
    //   933.177399723407 +
    //   0.0122619396440697 * audienceSize +
    //   0.0762790917203794 * budget +
    //   -1.63541745749267 * durationInHours;
    const maxReachEnd = 0.75 * audienceSize;
    const reachEnd = Math.min(
      maxReachEnd,
      1210.30625905 +
        0.01375454 * audienceSize +
        0.08074586 * budget +
        -0.48972699 * durationInHours,
    );
    // reachStart should be at least 100 less than `reachEnd`, but not <0
    const minReachStart = Math.max(reachEnd - 100, 0);
    const reachStart = Math.min(
      933.177399723407 +
        0.0122619396440697 * audienceSize +
        0.0762790917203794 * budget +
        -1.63541745749267 * durationInHours,
      minReachStart,
    );
    // @todo circle back on impressions
    // const impressionsMultiplier =
    //   1.97408948139136 +
    //   -0.00000290827917676038 * audienceSize +
    //   0.00000832537474241636 * budget +
    //   0.0092292428468611 * durationInHours;
    // const impressionMultiplierStart = 1.775433 + 0.008407968 * durationInHours;
    // const impressionMultiplierEnd = 2.172746 + 0.01005 * durationInHours;

    const grossReturnError = grossReturn * 0.45;
    const donorsError = Math.max(donors * 0.35, 1);

    // Formatting
    // @todo eliminate the repetition of the word "Estimated"?
    // @todo formatting (800k, 20k, etc.)?
    profitValue =
      profit > PROFIT_THRESHOLD ? userFriendlyDollarsRoundedToSignificantDigits(profit) : "-";
    roiValue = profit > PROFIT_THRESHOLD ? `${formatPercentage({ value: roi })}` : "-";
    reachValue = `${userFriendlyRoundNumber(reachStart)} - ${userFriendlyRoundNumber(
      reachEnd,
    )} unique ${pluralize("person", Math.round(reachEnd))}`;
    grossReturnValue = `${userFriendlyDollarsRoundedToSignificantDigits(
      grossReturn,
    )} from ${userFriendlyRoundNumber(donors)} ${pluralize("donor", Math.round(donors))}`;

    grossReturnErrorValue = userFriendlyDollarsRoundedToSignificantDigits(grossReturnError);
    donorsErrorValue = `${userFriendlyRoundNumber(donorsError)} ${pluralize(
      "donor",
      Math.round(donorsError),
    )}`;
  }

  const previewMetrics = [
    {
      title: "Estimated reach",
      value: reachValue,
    },
    {
      title: "Estimated gross return",
      value: grossReturnValue,
      error: grossReturnErrorValue,
      error2: donorsErrorValue,
    },
    {
      title: "Estimated profit",
      value: profitValue,
    },
    {
      title: "Estimated ROI",
      value: roiValue,
    },
  ];

  // @todo need to circle back on this.
  // Also consider making this a separate component and extracting the logic above.
  // if (isChart) {
  //   return (
  //     <Stack direction="column" maxWidth="sm" spacing={2}>
  //       <EstimateBar
  //         formatFn={userFriendlyRoundNumber}
  //         title="Reach"
  //         typicalMultiplier={0.8}
  //         value={reach}
  //       />
  //       <EstimateBar
  //         formatFn={userFriendlyWholeDollars}
  //         title="Gross return"
  //         typicalMultiplier={0.6}
  //         value={grossReturn}
  //       />
  //       <EstimateBar
  //         formatFn={val => formatPercentage({ value: val, sigFig: 2 })}
  //         title="Donation rate"
  //         typicalMultiplier={0.9}
  //         value={0.5}
  //       />
  //       <Box>
  //         <Typography variant="h3">Return on investment (predicted)</Typography>
  //         <Typography color={COLORS.blue} variant="h3">
  //           {PROFIT_THRESHOLD ? `${formatPercentage({ value: roi })}` : "-"}
  //         </Typography>
  //       </Box>
  //     </Stack>
  //   );
  // }

  return (
    <Grid container marginTop="1rem" maxWidth="600px" spacing={1}>
      {previewMetrics.map(metricSpec => (
        <Grid item key={metricSpec.title} xs={6}>
          <TextLabel>{metricSpec.title}</TextLabel>
          <Typography color={COLORS.blue} fontWeight="bold">
            {metricSpec.value}
          </Typography>
          {metricSpec.error && !isPlaceholder ? (
            <Typography color={COLORS.blue} variant="body2">
              +/- {metricSpec.error}
            </Typography>
          ) : null}
          {metricSpec.error2 && !isPlaceholder ? (
            <Typography color={COLORS.blue} variant="body2">
              +/- {metricSpec.error2}
            </Typography>
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
};

export default CampaignFundraisingEstimate;
