import styled from "@emotion/styled";

const OverflowContainer = styled.div`
  height: 1rem;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
`;

const OverflowFirstChild = styled.div`
  height: 100%;
`;

// This component will hide the provided text completely if it overflows its container at all,
// but will show it normally if it does not overflow.
// See https://stackoverflow.com/a/77699567
const HideIfOverflow = ({ textContent }) => {
  return (
    <OverflowContainer>
      <OverflowFirstChild />
      <div>{textContent}</div>
    </OverflowContainer>
  );
};

export default HideIfOverflow;
