import { gql, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import { CORE_AUDIENCE_FIELDS } from "../../fragments";
import useAudienceNameField from "../../hooks/useAudienceNameField";
import SharedModal from "../shared/SharedModal";

const PROMOTE_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($pk: ID!, $name: String!) {
    promoteAudience(pk: $pk, name: $name) {
      ...CoreAudienceFields
    }
  }
`;

const PromoteAudience = ({ audience, buttonWidth }) => {
  const [doPromoteAudienceMutation, { loading: promoteAudienceLoading }] =
    useMutation(PROMOTE_AUDIENCE);
  const dispatch = useAppStateDispatch();
  const navigate = useNavigate();

  const { audienceNameField, audienceName, validateAudienceName } = useAudienceNameField();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const promoteAudience = () => {
    if (!validateAudienceName()) {
      return;
    }
    doPromoteAudienceMutation({
      variables: {
        pk: audience.id,
        name: audienceName,
      },
      onCompleted: data => {
        if (data.promoteAudience.id) {
          dispatch({
            type: "org-add-or-update-audience",
            organizationId: data.promoteAudience.organization.id,
            audience: data.promoteAudience,
          });
          toast("Audience created.", { type: "success" });
          navigate(`/audiences/${data.promoteAudience.id}`);
        }
      },
    });
  };

  return (
    <div>
      <Button
        disabled={promoteAudienceLoading}
        onClick={() => setIsModalOpen(true)}
        sx={{ width: buttonWidth }}
      >
        {promoteAudienceLoading ? "Loading..." : "Create audience"}
      </Button>

      <SharedModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} showCloseButton={false}>
        {audienceNameField}
        <Button disabled={promoteAudienceLoading} onClick={promoteAudience}>
          {promoteAudienceLoading ? "Loading..." : "Create audience"}
        </Button>
      </SharedModal>
    </div>
  );
};

export default PromoteAudience;
