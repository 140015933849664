import { gql, useMutation } from "@apollo/client";
import { Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import useGetContentVariationFromParam from "../../hooks/useGetContentVariationFromParam";
import ActionGrid from "../shared/ActionGrid";
import ContentCannotBeChangedTooltipWrapper from "../shared/ContentCannotBeChangedTooltipWrapper";
import Loading from "../shared/Loading";

import ContentGroupsHeader from "./ContentGroupsHeader";
import VariationPreview from "./VariationPreview";

const ARCHIVE_CONTENT_VARIATION = gql`
  mutation ($contentVariationTypedId: String!) {
    archiveContentVariation(contentVariationTypedId: $contentVariationTypedId)
  }
`;

const ContentVariationDetail = () => {
  const dispatch = useAppStateDispatch();
  const { variation, contentGroup } = useGetContentVariationFromParam();
  const navigate = useNavigate();

  const [doArchiveContentVariation, { loading: doArchiveContentVariationIsLoading }] =
    useMutation(ARCHIVE_CONTENT_VARIATION);

  const archiveVariation = () => {
    const confirmed = window.confirm("Are you sure you want to delete this variation?");
    if (!confirmed) {
      return;
    }
    doArchiveContentVariation({
      variables: { contentVariationTypedId: variation.typedId },
      onCompleted: () => {
        dispatch({ type: "org-delete-variation", contentVariationTypedId: variation.typedId });
        toast("Variation deleted.", { type: "success" });
        navigate(`/content/group/${contentGroup.typedId}`);
      },
    });
  };

  if (doArchiveContentVariationIsLoading) {
    return <Loading />;
  }

  if (!variation) {
    return "Not found.";
  }

  return (
    <>
      <ContentGroupsHeader
        backToLink={`/content/group/${contentGroup.typedId}`}
        backToText="Back to content"
        headerText={variation.name}
        showNewContentGroupButton={false}
      />

      <ActionGrid sx={{ marginBottom: "2rem" }}>
        {contentGroup.type !== "DirectMail" ? (
          <Grid item>
            <ContentCannotBeChangedTooltipWrapper shouldShow={contentGroup.isLocked}>
              <Button
                component={Link}
                disabled={contentGroup.isLocked}
                to={`/content/variation/new/${contentGroup.typedId}/${variation.typedId}`}
              >
                Create new variation starting from this one
              </Button>
            </ContentCannotBeChangedTooltipWrapper>
          </Grid>
        ) : null}
        <Grid item>
          <ContentCannotBeChangedTooltipWrapper shouldShow={contentGroup.isLocked}>
            <Button disabled={contentGroup.isLocked} onClick={archiveVariation}>
              Delete variation
            </Button>
          </ContentCannotBeChangedTooltipWrapper>
        </Grid>
      </ActionGrid>
      <VariationPreview full includeName={true} isLink={false} variation={variation} />
    </>
  );
};
export default ContentVariationDetail;
