import { useMemo } from "react";

import { useAppState } from "../AppStateContext";
import { DISTANCE_FROM_ZIP_DEFAULT } from "../constants";

const useGetDefaultFilters = () => {
  const { constants } = useAppState();

  return useMemo(
    () => ({
      // Demographic
      ageStart: constants.AGE_MIN,
      ageEnd: constants.AGE_MAX,
      ageIncludeUnknown: false,
      genders: [],
      races: [],
      registrationDateStart: null,
      registrationDateEnd: null,
      hasDonorHistory: "",
      contactInfo: "",

      // Geographic
      states: [],
      counties: [],
      congressionalDistricts: [],
      stateLegUpperDistricts: [],
      stateLegLowerDistricts: [],
      zip: "",
      distanceFromZip: DISTANCE_FROM_ZIP_DEFAULT,

      // Sort / limit
      sortField: "",
      sortAsc: "false",
      limit: "",
    }),
    [constants],
  );
};

export default useGetDefaultFilters;
