import { Box } from "@mui/material";

import { COLORS } from "../../colors";

const VariationDirectMailPreview = ({ name, pdfUrl }) => {
  if (pdfUrl) {
    // @todo add PDF rendering and original PDF filename
    return (
      <Box sx={{ border: `1px solid ${COLORS.lightGray}`, borderRadius: 3, padding: "1rem" }}>
        local URL we need to render: {pdfUrl}
      </Box>
    );
  } else {
    return "Upload your PDF to see a preview of its contents.";
  }
};

export default VariationDirectMailPreview;
