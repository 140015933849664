import AddIcon from "@mui/icons-material/Add";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ExpandIcon from "@mui/icons-material/Expand";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import DropdownButton from "../shared/DropdownButton";

const NEW_AUDIENCE_ACTIONS = [
  {
    url: "/audiences/filters",
    icon: <FilterAltIcon />,
    name: "Apply filters to national voter file",
  },
  {
    url: "/audiences/filters?startFromExisting",
    icon: <FilterAltOutlinedIcon />,
    name: "Apply filters to an existing audience",
  },
  { url: "/audiences/match", icon: <UploadFileIcon />, name: "Match a CSV to the voter file" },
  { url: "/audiences/expand", icon: <ExpandIcon />, name: "Expand an existing audience" },
];

const AudiencesHeader = ({
  showNewAudienceButton = true,
  headerText = "Audiences",
  alternateRightContent,
}) => {
  let rightContent = (
    <Grid item>
      <Button color="secondary" component={Link} startIcon={<ArrowLeftIcon />} to="/audiences">
        Back to library
      </Button>
    </Grid>
  );
  if (alternateRightContent) {
    rightContent = alternateRightContent;
  }
  return (
    <Grid container alignItems="center" spacing={1.5} sx={{ marginBottom: "30px" }}>
      <Grid item>
        <Typography sx={{ margin: 0, paddingRight: "1rem" }} variant="h2">
          {headerText}
        </Typography>
      </Grid>
      {showNewAudienceButton ? (
        <Grid item>
          <DropdownButton actions={NEW_AUDIENCE_ACTIONS} icon={<AddIcon />} text="New" />
        </Grid>
      ) : null}
      <Grid item xs></Grid>
      {rightContent}
    </Grid>
  );
};

export default AudiencesHeader;
