import { Outlet, Navigate } from "react-router-dom";

import { useAppState } from "../../AppStateContext";

const RequireStaff = () => {
  const { user } = useAppState();
  return user && user.isStaff ? <Outlet /> : <Navigate to="/" />;
};

export default RequireStaff;
