import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { capitalize, isEmpty, sortBy } from "lodash";
import pluralize from "pluralize";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { FormSection } from "../../common/layout";
import {
  arrayToSentence,
  formatPercentage,
  recursiveCamelToSnake,
  userFriendlyNumber,
} from "../../helpers";
import useAudienceNameField from "../../hooks/useAudienceNameField";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useMaintainCurrentAudienceId from "../../hooks/useMaintainCurrentAudienceId";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import FileDropzone from "../shared/FileDropzone";
import InfoBox from "../shared/InfoBox";
import Loading from "../shared/Loading";
import SimpleTextButton from "../shared/SimpleTextButton";

const CREATE_AUDIENCE_FOR_UPLOADED_CSV = gql`
  mutation CreateAudienceForUploadedCsv($input: AudienceForUploadedCsvInput!) {
    createAudienceForUploadedCsv(input: $input) {
      id
      status
      uploadedAudienceCsv {
        id
        createdAt
        status
        temporarySignedUrl
      }
    }
  }
`;

const UPDATE_MATCHED_AUDIENCE_PROGRESS = gql`
  mutation UpdateUploadedAudienceCsv($input: MatchedAudienceProgressInput!) {
    updateMatchedAudienceProgress(input: $input) {
      id
    }
  }
`;

const FormGridHeader = styled(Grid)`
  font-weight: bold;
  text-decoration: underline;
`;

const ErrorsOrWarningsBox = ({ isErrorBox, messagesObject }) => {
  const messages = Object.values(messagesObject);
  let renderedMessages;
  if (messages.length > 1) {
    const preMessage = isErrorBox
      ? "Please resolve the following errors"
      : "We've detected the following potential issues";

    renderedMessages = (
      <>
        {preMessage}:
        <ul>
          {messages.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        {isErrorBox ? null : (
          <>You can address these possible issues or click "Create audience" to proceed anyway.</>
        )}
      </>
    );
  } else {
    renderedMessages = (
      <>
        {messages[0]}
        {isErrorBox ? null : (
          <>
            <br />
            <br />
            You can address this possible issue or click "Create audience" to proceed anyway.
          </>
        )}
      </>
    );
  }

  return (
    <InfoBox backgroundColor={isErrorBox ? "lightRed" : "lightOrange"} marginTop="-20px">
      {renderedMessages}
    </InfoBox>
  );
};

// This should match _sanitize_column_name in uploaded_audience_csv.py (except we don't worry about
// validation here).
const sanitizeColumnName = name => {
  let sanitized = name.toLowerCase();

  // column name can't start with a number
  if (!sanitized.match(/^[_a-z].*/)) {
    sanitized = `_${sanitized}`;
  }

  return sanitized.replaceAll(/[^_a-z0-9]/g, "_");
};

const COLUMN_TYPE_OPTIONS = ["required", "optional"].map(s => ({ value: s, label: capitalize(s) }));

// How this upload flow works:
// 1. Client asks server to create the initial CSV record (with status READY_FOR_UPLOAD) and
//    audience record (with status PENDING).
// 2. Client gets signed upload URL from server and uploads file directly.
// 3. Client notifies server that upload is complete by passing status READY_FOR_PARSING for CSV.
// 4. Server loads file into BQ and updates CSV status to READY_FOR_COLUMN_SELECTION.
// 5. Client polls for READY_FOR_COLUMN_SELECTION status, asks user to select columns, then passes
//    selections and CSV status ACTIVE to server.
// 6. Server processes column selections and sets CSV status to ACTIVE, then kicks off async
//    matching job, which updates new audience status to ACTIVE when it completes.
// 7. Client polls for ACTIVE status for audience.

const UploadNewAudienceCsv = () => {
  const { organization } = useGetActiveOrg();
  const { audienceNameField, audienceName, validateAudienceName } = useAudienceNameField();

  const [errors, setErrors] = useState({});
  const [fieldsCausingErrors, setFieldsCausingErrors] = useState(new Set());
  const [warnings, setWarnings] = useState({});

  const [selectedFile, setSelectedFile] = useState();
  const [pendingAudienceId, setPendingAudienceId] = useState();

  useMaintainCurrentAudienceId(pendingAudienceId);

  // columnSelections is an object mapping from canonical field name to sanitized column name and
  // whether it's required
  const [columnSelections, setColumnSelections] = useState({});
  const [isColumnSelectionInFlight, setIsColumnSelectionInFlight] = useState(false);
  const [newColumnAddCounter, setNewColumnAddCounter] = useState(0);

  const { constants } = useAppState();
  const canonicalFields = constants.CANONICAL_CSV_FIELDS;

  const dispatch = useAppStateDispatch();

  const updateFieldIsRequired = useCallback(
    ({ canonicalField, isRequired }) =>
      setColumnSelections({
        ...columnSelections,
        [canonicalField]: { ...columnSelections[canonicalField], isRequired },
      }),
    [columnSelections],
  );

  const updateColumnMapping = useCallback(
    ({ canonicalField, columnName }) => {
      const newSpec = { ...columnSelections[canonicalField], columnName };
      if (canonicalFields[canonicalField].always_required) {
        newSpec.isRequired = true;
      }
      setColumnSelections({ ...columnSelections, [canonicalField]: newSpec });
    },
    [columnSelections, canonicalFields],
  );

  const deleteColumnMapping = canonicalField => {
    const newSelections = { ...columnSelections };
    delete newSelections[canonicalField];
    setColumnSelections(newSelections);
  };

  const addColumnMapping = canonicalField => {
    // The prepopulation of the column name here would only happen if the user had removed a
    // prepopulated row and then later re-added that field.
    const recognizedSpec = recognizedColumnMappings[canonicalField];
    setColumnSelections({
      ...columnSelections,
      [canonicalField]: { columnName: recognizedSpec && recognizedSpec.columnName },
    });
  };

  let pendingAudience, pendingCsv, columnMetadata;
  if (pendingAudienceId) {
    pendingAudience = organization.audiences.find(a => a.id === pendingAudienceId);
    pendingCsv = organization.uploadedAudienceCsvs.find(
      c => c.id === pendingAudience.uploadedAudienceCsv.id,
    );
    if (pendingCsv.rawColumnMetadata) {
      columnMetadata = pendingCsv.rawColumnMetadata;
    }
  }

  // When we show the list of columns in the column selection dropdowns, we want to preserve
  // the ordering from the original CSV.
  const columnNames = useMemo(
    () =>
      columnMetadata
        ? sortBy(Object.entries(columnMetadata), [
            ([columnName, metadata]) => metadata.position,
          ]).map(([columnName, metadata]) => columnName)
        : null,
    [columnMetadata],
  );

  // Try to guess the column mappings to facilitate the column selection step. We'll start with
  // exact string matching of sanitized column names, but we can easily make this a bit smarter.
  const recognizedColumnMappings = useMemo(() => {
    if (!pendingCsv || pendingCsv.status !== "READY_FOR_COLUMN_SELECTION" || !columnNames) {
      return {};
    }

    const mappings = {};
    const sanitizedToOriginal = columnNames.reduce(
      (acc, curr) => ({ ...acc, [sanitizeColumnName(curr)]: curr }),
      {},
    );
    Object.entries(canonicalFields).forEach(([canonicalField, spec]) => {
      if (canonicalField in sanitizedToOriginal) {
        mappings[canonicalField] = {
          columnName: sanitizedToOriginal[canonicalField],
          isRequired: spec.always_required ? true : null,
        };
      }
    });
    return mappings;
  }, [canonicalFields, columnNames, pendingCsv]);

  // This effect uses the recognized mappings to try to prepopulate the column selection form.
  useEffect(() => {
    if (!isEmpty(recognizedColumnMappings) && isEmpty(columnSelections)) {
      const initialColumnSelections = { ...recognizedColumnMappings };
      // For fields that are always required, we want to show them in the form even if we can't
      // prepopulate their column names.
      Object.entries(canonicalFields).forEach(([canonicalField, spec]) => {
        if (!(canonicalField in initialColumnSelections) && spec.always_required) {
          initialColumnSelections[canonicalField] = {
            columnName: null,
            isRequired: true,
          };
        }
      });

      setColumnSelections(initialColumnSelections);
    }
  }, [canonicalFields, columnSelections, recognizedColumnMappings]);

  const navigate = useNavigate();
  useEffect(() => {
    if (pendingAudience && pendingAudience.status !== "PENDING") {
      if (pendingAudience.isPreview) {
        // We ran into an error before even kicking off the matching process, so let's go back to
        // the beginning. @todo ensure that we're showing proper error messages also.
        setPendingAudienceId(null);
        setSelectedFile(null);
      } else {
        // When the matching process completes, redirect to the details page.
        navigate(`/audiences/${pendingAudience.id}`);
      }
    }
  }, [pendingAudience, navigate]);

  const [doUpdateMatchedAudienceProgressMutation] = useMutation(UPDATE_MATCHED_AUDIENCE_PROGRESS);

  const [doCreateAudienceForUploadedCsvMutation, { loading: creationLoading }] = useMutation(
    CREATE_AUDIENCE_FOR_UPLOADED_CSV,
    {
      onCompleted: data => {
        const audience = data.createAudienceForUploadedCsv;
        const csv = audience.uploadedAudienceCsv;

        // This is where we first add the new audience to our app state, which is necessary so that
        // we can poll for it etc.
        dispatch({
          type: "org-add-or-update-audience",
          organizationId: organization.id,
          audience,
        });

        fetch(csv.temporarySignedUrl, {
          body: selectedFile,
          headers: {
            "Content-Type": selectedFile.type,
          },
          method: "PUT",
        }).then(response => {
          if (response.status === 200) {
            // Tell the server that the file has been uploaded successfully, then start polling
            // so we know when the server is done parsing the file and we're ready to ask the user
            // to select columns.
            doUpdateMatchedAudienceProgressMutation({
              variables: {
                input: {
                  id: audience.id,
                  status: "READY_FOR_PARSING",
                },
              },
              // We don't need an onCompleted since we'll be polling for this audience already.
              // However note that depending on the timing of the polling and the async matching
              // job, it's possing that from the frontend's perspective the CSV status could go
              // directly from `READY_FOR_UPLOAD` to `READY_FOR_COLUMN_SELECTION`, skipping
              // `READY_FOR_PARSING` (even though it will go through that status on the backend).
              // @todo onError?
            });
            setPendingAudienceId(audience.id);
          } else {
            // @todo handle upload error here
          }
        });
      },
    },
  );

  const createUploadedAudienceCsv = file => {
    doCreateAudienceForUploadedCsvMutation({
      variables: {
        input: { organizationId: organization.id, filename: file.name },
      },
    });
  };

  const onSelectFile = file => {
    setSelectedFile(file);
    createUploadedAudienceCsv(file);
  };

  const csvColumnOptions = useMemo(
    () =>
      pendingCsv &&
      columnNames &&
      columnNames.map(columnName => ({
        label: columnName,
        value: columnName,
      })),
    [pendingCsv, columnNames],
  );

  const unusedCanonicalFieldOptions = useMemo(
    () =>
      Object.keys(canonicalFields)
        .filter(canonicalField => !columnSelections[canonicalField])
        .map(canonicalField => ({
          label: canonicalFields[canonicalField].label,
          value: canonicalField,
        })),
    [columnSelections, canonicalFields],
  );

  let formContent;

  const matchingInitiated =
    pendingCsv && ["COLUMNS_SELECTED", "ACTIVE"].includes(pendingCsv.status);

  if (!selectedFile) {
    // We're in the very first step: selecting a file to upload.
    const optionalColumnList = arrayToSentence(
      Object.values(canonicalFields)
        .filter(spec => !spec.always_required && !spec.internal_only)
        .map(spec => spec.sentence_text || spec.label.toLowerCase()),
    );

    formContent = (
      <>
        <FormSection>
          <b>Tips:</b>
          <ul>
            <li>Your CSV must contain a header row.</li>
            <li>
              Your file must include first name, last name, and at least one additional field
              (excluding ID) from among these options: {optionalColumnList}.
            </li>
            <li>
              After you upload your file, you'll have the opportunity to indicate which field is in
              each column and which fields should be required vs. optional for matching.
            </li>
          </ul>
        </FormSection>
      </>
    );
  } else if (creationLoading || !pendingCsv || pendingCsv.status === "READY_FOR_UPLOAD") {
    formContent = (
      <FormSection>
        <div>Uploading...</div>
        <Loading center={false} />
      </FormSection>
    );
  } else if (matchingInitiated) {
    // If the audience is no longer pending (i.e. it succeeded or failed), we'll use an effect to
    // redirect.
    formContent = (
      <FormSection>
        <div>Matching in progress. This may take a few minutes...</div>
        <Loading center={false} />
      </FormSection>
    );
  } else {
    if (pendingCsv.status === "READY_FOR_PARSING") {
      formContent = (
        <FormSection>
          <div>Parsing your file...</div>
          <Loading center={false} />
        </FormSection>
      );
    } else if (pendingCsv.status === "READY_FOR_COLUMN_SELECTION") {
      const columnsFormHeader = (
        <>
          <FormGridHeader item xs={2}>
            Matching field
          </FormGridHeader>
          <FormGridHeader item xs={4}>
            CSV column to use
          </FormGridHeader>
          <FormGridHeader item xs={3}>
            Required or optional
          </FormGridHeader>
          <FormGridHeader item xs={2}>
            Presence in CSV
          </FormGridHeader>
        </>
      );

      // We sort (stably) to ensure that the always-required fields will appear first.
      const columnsFormRows = sortBy(Object.entries(columnSelections), ([canonicalField, spec]) =>
        canonicalFields[canonicalField].always_required ? canonicalField : "zzz",
      ).map(([canonicalField, spec]) => {
        const alwaysRequired = canonicalFields[canonicalField].always_required;

        let columnTypeSelect;
        if (!alwaysRequired && canonicalField !== "id") {
          const onChange = columnType =>
            updateFieldIsRequired({ canonicalField, isRequired: columnType === "required" });
          let value;
          if (spec.isRequired) {
            value = "required";
          } else if (spec.isRequired === false) {
            value = "optional";
          }

          columnTypeSelect = (
            <DropdownSelectOne
              onChange={onChange}
              options={COLUMN_TYPE_OPTIONS}
              placeholder="Select an option"
              value={value}
            />
          );
        }

        const populatedText = spec.columnName ? (
          <Typography>
            {formatPercentage({
              value: columnMetadata[spec.columnName].num_populated / pendingCsv.numDataRows,
              sigFig: 2,
            })}{" "}
            of rows
          </Typography>
        ) : null;

        const deleteButton = alwaysRequired ? null : (
          <SimpleTextButton onClick={() => deleteColumnMapping(canonicalField)}>
            remove
          </SimpleTextButton>
        );

        // Need these to vertically align text with selectors.
        // I can't figure out why there's a 2px discrepancy between plain text and links!
        const marginAdjustmentText = { marginTop: "8px" };
        const marginAdjustmentLink = { marginTop: "6px" };

        return (
          <Fragment key={canonicalField}>
            <Grid item sx={marginAdjustmentText} xs={2}>
              <Typography variant={fieldsCausingErrors.has(canonicalField) ? "error" : null}>
                {canonicalFields[canonicalField].label}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <DropdownSelectOne
                autocomplete
                onChange={columnName => updateColumnMapping({ canonicalField, columnName })}
                options={csvColumnOptions}
                placeholder="Select a column"
                value={spec.columnName}
              />
            </Grid>
            <Grid item sx={columnTypeSelect ? null : marginAdjustmentText} xs={3}>
              {columnTypeSelect || <Typography>Required</Typography>}
            </Grid>
            <Grid item sx={marginAdjustmentText} xs={2}>
              {populatedText}
            </Grid>
            <Grid item sx={marginAdjustmentLink} xs={1}>
              {deleteButton}
            </Grid>
          </Fragment>
        );
      });

      // If they haven't already chosen a column for every single possible field, provide a way
      // to add another field to the list.
      if (Object.keys(columnSelections).length < Object.keys(canonicalFields).length) {
        const onChange = canonicalField => {
          setNewColumnAddCounter(newColumnAddCounter + 1);
          addColumnMapping(canonicalField);
        };

        columnsFormRows.push(
          <Grid item key="add" xs={4}>
            <DropdownSelectOne
              onChange={onChange}
              options={unusedCanonicalFieldOptions}
              placeholder="Add another field"
            />
          </Grid>,
        );
      }

      const validateColumnSelectionForm = () => {
        const newErrors = {};
        const newFieldsCausingErrors = new Set();
        const newWarnings = {};

        if (!validateAudienceName()) {
          newErrors.audienceName = "You must specify a name for your audience.";
        }

        const columnToFields = {};

        Object.entries(columnSelections).forEach(([canonicalField, spec]) => {
          if (spec.columnName) {
            if (columnToFields[spec.columnName]) {
              columnToFields[spec.columnName].push(canonicalField);
            } else {
              columnToFields[spec.columnName] = [canonicalField];
            }
          }

          if (
            !spec.columnName ||
            (canonicalField !== "id" && spec.isRequired !== true && spec.isRequired !== false)
          ) {
            newFieldsCausingErrors.add(canonicalField);
          }

          if (spec.columnName) {
            const availability =
              columnMetadata[spec.columnName].num_populated / pendingCsv.numDataRows;

            if (availability < 1) {
              if (canonicalField === "id") {
                newErrors.missingIds = (
                  <>
                    You selected {spec.columnName} as your ID field, but not every row in your
                    spreadsheet contains a value in this column.
                  </>
                );
              } else if (spec.isRequired) {
                const canonicalSpec = canonicalFields[canonicalField];
                const fieldName = canonicalSpec.sentence_text || canonicalSpec.label.toLowerCase();
                // We have a special case for when it's almost 100% because we're rounding the
                // percentage and it would be weird to say "only 100% of rows" when it's actually a
                // bit lower.
                const renderedPercentage = formatPercentage({ value: availability, sigFig: 2 });
                let problemText;
                if (renderedPercentage === "100%") {
                  const numMissing =
                    pendingCsv.numDataRows - columnMetadata[spec.columnName].num_populated;
                  problemText = (
                    <>
                      {userFriendlyNumber(numMissing)} {pluralize("row", numMissing)} in your CSV
                      {numMissing === 1 ? " has" : " have"} no value in this column and won't be
                      eligible for matching.
                    </>
                  );
                } else {
                  problemText = (
                    <>
                      only {renderedPercentage} of the rows in your CSV have a value in the{" "}
                      {spec.columnName} column. The rows without a value in this column won't be
                      eligible for matching.
                    </>
                  );
                }

                newWarnings[`${canonicalField}_populated`] = (
                  <>
                    You've specified {fieldName} as required for matching, but {problemText}
                  </>
                );
              }
            }
          }
        });

        if (newFieldsCausingErrors.size > 0) {
          newErrors.incompleteSpecs = (
            <>
              For each included field, you must specify a column name and indicate whether the field
              is required or optional for matching. You can click "Remove" for any fields that you
              want to exclude from the matching process.
            </>
          );
        }

        let columnsAreUnique = true;
        Object.entries(columnToFields).forEach(([column, canonicalFields]) => {
          if (canonicalFields.length > 1) {
            canonicalFields.forEach(f => {
              newFieldsCausingErrors.add(f);
              columnsAreUnique = false;
            });
          }
        });
        if (!columnsAreUnique) {
          newErrors.duplicateColumns = "You can't assign the same column to multiple fields.";
        }

        const numRequired = Object.values(columnSelections).filter(spec => spec.isRequired).length;

        if (numRequired < 3) {
          newErrors.tooFewRequired =
            "You must select at least one required column in addition to first name and last name.";
        } else if (numRequired > 4) {
          newWarnings.tooManyRequired = (
            <>
              Requiring more than 3 or 4 columns for matching will increase the quality of your
              matches, but may significantly limit the number of records that can be matched.
            </>
          );
        }

        const overlookedColumns = [];
        const selectedColumns = Object.keys(columnToFields);
        Object.entries(recognizedColumnMappings).forEach(([canonicalField, spec]) => {
          if (!selectedColumns.includes(spec.columnName) && canonicalField !== "id") {
            overlookedColumns.push(spec.columnName);
          }
        });
        if (overlookedColumns.length) {
          const plural = overlookedColumns.length > 1;
          const columnNounPhrase = plural
            ? `multiple columns (${arrayToSentence(overlookedColumns)}) that are`
            : `a column (${overlookedColumns[0]}) that is`;
          newWarnings[`overlookedColumns_${overlookedColumns.join("+")}`] = (
            <>
              Your CSV appears to contain {columnNounPhrase} eligible for matching but you haven't
              elected to use in the matching process. Even if you don't want to require a match on{" "}
              {plural ? "these fields" : "this field"}, adding {plural ? "them" : "it"} as optional
              may increase the number of matches we're able to identify.
            </>
          );
        }

        // Errors always block submission. Warnings block submission at first, but the user can
        // disregard them and submit again.
        const noNewWarnings = Object.keys(newWarnings).every(w => w in warnings);
        const allowSubmission = isEmpty(newErrors) && noNewWarnings;

        // We don't want to update the errors or warnings if allowSubmission is true, because that
        // might cause the warnings/error box to change for a split-second just before the form
        // submits and the page transitions (which would be a little confusing for the user).
        if (!allowSubmission) {
          setErrors(newErrors);
          setFieldsCausingErrors(newFieldsCausingErrors);

          // If there are errors, don't populate the warnings at all. We don't show them in this
          // case anyway -- plus, if the user resolves the errors and clicks the submit button again
          // and the exact same warnings would apply, they'll now get a chance to review them.
          setWarnings(isEmpty(newErrors) ? newWarnings : {});
        }

        return allowSubmission;
      };

      const columnSelectionOnClick = () => {
        if (!validateColumnSelectionForm()) {
          return;
        }
        setIsColumnSelectionInFlight(true);

        doUpdateMatchedAudienceProgressMutation({
          variables: {
            input: {
              audienceName,
              columnSelections: recursiveCamelToSnake(columnSelections),
              id: pendingAudienceId,
              status: "COLUMNS_SELECTED",
            },
          },
          onCompleted: data => {
            // Instead of updating the app state for this audience from here, instead tell our
            // audience polling mechanism in AudienceData to poll for this audience and update
            // it from there. This way we aren't trying to update state for the same audience
            // from two different places, which causes issues.
            dispatch({
              type: "additional-audiences-to-poll-queue-add",
              audienceIdsToPoll: data.updateMatchedAudienceProgress.id,
            });
            setIsColumnSelectionInFlight(false);
          },
          onError: () => {
            setIsColumnSelectionInFlight(false);
            // If there were errors previously, the most recent call to validateColumnSelectionForm
            // wouldn't have cleared them (because we don't do that when allowSubmission was true).
            setErrors({});
            setFieldsCausingErrors(new Set());
          },
        });
      };

      let errorsOrWarningsSection;
      if (!isEmpty(errors)) {
        errorsOrWarningsSection = <ErrorsOrWarningsBox isErrorBox messagesObject={errors} />;
      } else if (!isEmpty(warnings)) {
        errorsOrWarningsSection = (
          <ErrorsOrWarningsBox isErrorBox={false} messagesObject={warnings} />
        );
      }

      formContent = (
        <>
          <FormSection maxWidth="md">
            Your uploaded CSV contains <b>{userFriendlyNumber(pendingCsv.numDataRows)}</b>{" "}
            {pluralize("row", pendingCsv.numDataRows)} and{" "}
            <b>{userFriendlyNumber(columnNames.length)}</b>{" "}
            {pluralize("column", columnNames.length)}.
          </FormSection>
          <FormSection maxWidth="md">
            Please indicate which columns in your CSV correspond to fields that are eligible for
            inclusion in the matching process.
            <ul>
              <li>
                You must include first name, last name, and at least one additional field as
                required fields for matching.
              </li>
              <li>
                You can also specify any number of additional optional fields that will be used to
                improve your matching results.
              </li>
              <li>
                If your uploaded CSV has an ID column that uniquely identifies each row, you can
                include it. If you don't specify an ID column, we'll identify the records in your
                CSV using row numbers.
              </li>
            </ul>
          </FormSection>
          <FormSection>
            <Grid container maxWidth={850} spacing={2}>
              {columnsFormHeader}
              {columnsFormRows}
            </Grid>
          </FormSection>
          <FormSection maxWidth="xs">{audienceNameField}</FormSection>
          {errorsOrWarningsSection}
          <FormSection>
            <Button
              disabled={isColumnSelectionInFlight}
              onClick={columnSelectionOnClick}
              type="submit"
            >
              Create audience
            </Button>
          </FormSection>
        </>
      );
    }
  }

  let fileChooser;
  if (selectedFile) {
    let removeButton;
    if (!matchingInitiated) {
      const onRemoveFile = () => {
        setColumnSelections({});
        setPendingAudienceId(null);
        setSelectedFile(null);
      };

      removeButton = (
        <Box sx={{ paddingTop: "7px" }}>
          <SimpleTextButton onClick={onRemoveFile}>change</SimpleTextButton>
        </Box>
      );
    }
    fileChooser = (
      <Stack direction="row" spacing={2}>
        <span style={{ fontSize: "1.5rem" }}>{selectedFile.name}</span>
        {removeButton}
      </Stack>
    );
  } else {
    fileChooser = <FileDropzone fileType="CSV" onSelect={onSelectFile} />;
  }

  return (
    <Box maxWidth="md">
      <Typography variant="h4">Upload a CSV</Typography>
      <FormSection>{fileChooser}</FormSection>
      {formContent}
    </Box>
  );
};

export default UploadNewAudienceCsv;
