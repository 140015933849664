import { ThemeProvider, createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

import { COLORS } from "../colors";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          padding: "1px 18px",
          fontWeight: "800",
        },
      },
      defaultProps: {
        color: "primary",
        size: "medium",
        variant: "contained",
      },
      variants: [
        {
          props: { size: "large" },
          style: {
            fontSize: "18px",
            padding: "3px 25px",
            margin: "1rem 0",
          },
        },
        {
          props: props => props.variant === "outlined",
          style: {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiAutocomplete: {
      // @todo not sure why, but adding "cursor: pointer" works for most of the component but not
      // the whole thing
      styleOverrides: {
        root: {
          cursor: "pointer",
          marginBottom: "15px",
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: COLORS.black,
        textDecoration: "underline",
        "&:hover, &:active, &:visited": {
          color: COLORS.black,
          textDecoration: "underline",
        },
      },
    },
  },
  palette: {
    background: {
      default: COLORS.veryLightGray,
    },
    text: {
      primary: COLORS.black,
    },
    primary: {
      main: COLORS.magenta,
    },
    secondary: {
      main: COLORS.blue,
    },
    action: {
      disabledBackground: alpha(COLORS.magenta, 0.4),
      disabled: COLORS.lightGray,
    },
  },
  typography: {
    fontFamily: '"Aeonik", "Avenir", "Proxima Nova", "Helvetica"',
    color: COLORS.black,
    button: {
      textTransform: "none",
      fontSize: 16,
    },
    h2: {
      fontSize: 20,
      fontWeight: 900,
      marginBottom: "1.5rem",
      padding: 0,
    },
    h3: {
      fontSize: 18,
      fontWeight: 900,
      marginBottom: "1rem",
      padding: 0,
    },
    h4: {
      fontSize: 16,
      fontWeight: 800,
      marginBottom: "0.25rem",
      padding: 0,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    code: {
      fontFamily: '"Roboto mono", "Arial", "sans-serif"',
    },
    columnHeader: {
      fontWeight: "bold",
    },
    error: {
      color: COLORS.red,
      fontWeight: "bold",
    },
  },
});

const ThemeWrapper = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
