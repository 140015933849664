import { useParams } from "react-router-dom";

import useGetActiveOrg from "./useGetActiveOrg";

const useGetContentVariationFromParam = () => {
  const { contentVariationTypedId } = useParams();
  const { organization } = useGetActiveOrg();
  const { contentGroups } = organization;

  let variation, contentGroup;
  for (let i = 0; i < contentGroups.length; i++) {
    variation = contentGroups[i].variations.find(v => v.typedId === contentVariationTypedId);
    if (variation) {
      contentGroup = contentGroups[i];
      break;
    }
  }
  return { variation, contentGroup };
};

export default useGetContentVariationFromParam;
