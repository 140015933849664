import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";

const DropdownSelectOne = ({
  allowEmpty = false,
  autocomplete = false,
  isDisabled,
  onChange,
  options,
  placeholder,
  value,
}) => {
  const selectedOption = options.find(o => o.value === value);

  if (autocomplete) {
    return (
      <Autocomplete
        disableClearable
        isOptionEqualToValue={(option, value) =>
          value !== null && value !== undefined && option.value === value.value
        }
        onChange={(e, selectedOption) => onChange(selectedOption.value)}
        options={options}
        renderInput={params => <TextField {...params} placeholder={placeholder} />}
        size="small"
        value={selectedOption || null}
      />
    );
  } else {
    const label = selectedOption ? selectedOption.label : null;
    const controlledValue = value === null || value === undefined ? "" : value;

    // 0.42 is the default opacity for the Autocomplete placeholder, so we mimic that here.
    const renderValue = value => label || <span style={{ opacity: 0.42 }}>{placeholder}</span>;

    return (
      <Select
        fullWidth
        disabled={isDisabled}
        displayEmpty={!allowEmpty}
        inputProps={{ shrink: "false" }}
        onChange={e => onChange(e.target.value)}
        renderValue={renderValue}
        size="small"
        value={controlledValue}
      >
        {allowEmpty ? (
          <MenuItem key="empty" value="">
            --
          </MenuItem>
        ) : null}
        {options.map(o => (
          <MenuItem key={o.label} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
};

export default DropdownSelectOne;
