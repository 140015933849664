import { gql, useLazyQuery } from "@apollo/client";

import { useAppStateDispatch } from "../AppStateContext";

import useGetActiveOrg from "./useGetActiveOrg";

const GET_ORG_NUM_CREDITS = gql`
  query getOrgNumCredits($organizationId: ID!) {
    organization(pk: $organizationId) {
      id
      numCredits
    }
  }
`;

const useRefreshOrgCredits = () => {
  const { organization } = useGetActiveOrg();
  const dispatch = useAppStateDispatch();
  const [doQuery] = useLazyQuery(GET_ORG_NUM_CREDITS, {
    onCompleted: data => {
      const { id: organizationId, numCredits } = data.organization;
      dispatch({ type: "org-update-num-credits", organizationId, numCredits });
    },
  });
  const refreshOrgCredits = () => doQuery({ variables: { organizationId: organization.id } });
  return refreshOrgCredits;
};

export default useRefreshOrgCredits;
