import { Tooltip, Typography } from "@mui/material";

const ContentCannotBeChangedTooltipWrapper = ({ shouldShow, children }) => {
  return (
    <>
      <Tooltip
        title={
          !shouldShow ? null : (
            <Typography variant="subtitle2">
              This content can't be changed becuase it's being used in a campaign.
            </Typography>
          )
        }
      >
        <span>{children}</span>
      </Tooltip>
    </>
  );
};

export default ContentCannotBeChangedTooltipWrapper;
