import { Grid, Box } from "@mui/material";
import { reverse, sortBy } from "lodash";
import pluralize from "pluralize";

import { CONTENT_GROUP_TYPES } from "../../constants";
import { formatDate } from "../../helpers";
import useGetCurrentContentGroups from "../../hooks/useGetCurrentContentGroups";
import { OneLineTypography } from "../../styles";
import CardItem from "../shared/CardItem";

import ContentGroupsHeader from "./ContentGroupsHeader";

const ContentGroupListItem = ({ contentGroup }) => {
  const numVariations = contentGroup.variations && contentGroup.variations.length;
  return (
    <CardItem linkTo={`/content/group/${contentGroup.typedId}`}>
      <Box sx={{ marginBottom: "0.5rem" }}>
        <OneLineTypography sx={{ marginBottom: "0.25rem" }} title={contentGroup.name} variant="h3">
          {contentGroup.name}
        </OneLineTypography>
        <OneLineTypography title={CONTENT_GROUP_TYPES[contentGroup.type].name} variant="subtitle2">
          {CONTENT_GROUP_TYPES[contentGroup.type].name}
        </OneLineTypography>
        <OneLineTypography variant="subtitle2">
          {formatDate(contentGroup.createdAt)}
        </OneLineTypography>
      </Box>
      <OneLineTypography>
        {numVariations} {pluralize("variation", numVariations)}
      </OneLineTypography>
    </CardItem>
  );
};

const ContentGroupList = () => {
  const contentGroups = useGetCurrentContentGroups();

  const contentGroupList = reverse(sortBy(contentGroups, "createdAt")).map(cg => (
    <ContentGroupListItem contentGroup={cg} key={cg.typedId} />
  ));
  return (
    <>
      <ContentGroupsHeader alternateRightContent={null} />
      <Grid container spacing={4}>
        {contentGroupList}
      </Grid>
    </>
  );
};

export default ContentGroupList;
