import { gql, useLazyQuery, useMutation } from "@apollo/client";
import pluralize from "pluralize";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../AppStateContext";
import { CORE_AUDIENCE_FIELDS } from "../fragments";
import { doesApolloErrorIncludeErrorOfType, userFriendlyNumber } from "../helpers";

import useRefreshOrgCredits from "./useRefreshOrgCredits";

const UNLOCK_CSV_MATCH_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($pk: ID!, $expectedCost: Int!) {
    unlockCsvMatchAudience(pk: $pk, expectedCost: $expectedCost) {
      ...CoreAudienceFields
    }
  }
`;

const PURCHASE_EXPORT = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($pk: ID!, $expectedCost: Int!) {
    purchaseExport(pk: $pk, expectedCost: $expectedCost) {
      id
      audience {
        ...CoreAudienceFields
      }
    }
  }
`;

const GET_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  query getAudience($audienceId: ID!) {
    audience(pk: $audienceId) {
      ...CoreAudienceFields
    }
  }
`;

const useMakeAudiencePurchase = ({
  audienceId,
  confirmPhrase,
  successMessage,
  additionalOnCompleted,
  additionalOnError,
}) => {
  const dispatch = useAppStateDispatch();
  const [doPurchaseExportMutation, { loading: isPurchaseExportLoading }] =
    useMutation(PURCHASE_EXPORT);
  const [doUnlockCsvMatchAudienceMutation, { loading: isUnlockCsvMatchAudienceLoading }] =
    useMutation(UNLOCK_CSV_MATCH_AUDIENCE);

  const _getConfirmation = expectedCost => {
    if (expectedCost > 0) {
      return window.confirm(
        `Are you sure you want to use ${userFriendlyNumber(expectedCost)} ` +
          `${pluralize("credit", expectedCost)} to ${confirmPhrase}?`,
      );
    } else {
      return true;
    }
  };

  const onCompleted = updatedAudience => {
    const organizationId = updatedAudience.organization.id;
    dispatch({
      type: "org-add-or-update-audience",
      organizationId,
      audience: updatedAudience,
    });
    dispatch({
      type: "org-update-num-credits",
      organizationId,
      numCredits: updatedAudience.organization.numCredits,
    });
    if (successMessage) {
      toast(successMessage, { type: "success" });
    }
    additionalOnCompleted && additionalOnCompleted();
  };

  const refreshOrgCredits = useRefreshOrgCredits();

  const [refreshAudience] = useLazyQuery(GET_AUDIENCE);

  const onError = e => {
    if (doesApolloErrorIncludeErrorOfType(e, "InsufficientCreditsAvailable")) {
      refreshOrgCredits();
    } else {
      refreshAudience({
        variables: { audienceId },
        onCompleted: data => {
          const { audience } = data;
          dispatch({
            type: "org-add-or-update-audience",
            organizationId: audience.organization.id,
            audience,
          });
        },
      });
    }
    additionalOnError && additionalOnError(e);
  };

  const handlePurchaseExport = ({ exportId, expectedCost }) => {
    if (!_getConfirmation(expectedCost)) {
      return;
    }
    doPurchaseExportMutation({
      variables: { pk: exportId, expectedCost },
      onCompleted: data => onCompleted(data.purchaseExport.audience),
      onError,
    });
  };

  const handleUnlockCsvMatchAudience = ({ expectedCost }) => {
    if (!_getConfirmation(expectedCost)) {
      return;
    }
    doUnlockCsvMatchAudienceMutation({
      variables: { pk: audienceId, expectedCost },
      onCompleted: data => onCompleted(data.unlockCsvMatchAudience),
      onError,
    });
  };

  return {
    handlePurchaseExport,
    handleUnlockCsvMatchAudience,
    isPurchaseLoading: isPurchaseExportLoading || isUnlockCsvMatchAudienceLoading,
  };
};

export default useMakeAudiencePurchase;
