import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const OneLineTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const TypographyWithSpacing = styled(Typography)`
  margin: 1rem 0;
`;
