import { Outlet, Navigate } from "react-router-dom";

import { useAppState } from "../../AppStateContext";

const RequireAuth = () => {
  const { user } = useAppState();
  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default RequireAuth;
