import { gql, useMutation } from "@apollo/client";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { MAX_TEXT_LENGTH_STANDARD } from "../../constants";
import Loading from "../shared/Loading";
import TextLabel from "../shared/TextLabel";

const ADMIN_CREATE_ORG = gql`
  mutation AdminCreateOrg($name: String!) {
    adminCreateOrg(name: $name) {
      name
      id
    }
  }
`;

const CreateOrg = () => {
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState("");
  const [doCreateOrg, { loading }] = useMutation(ADMIN_CREATE_ORG);

  const handleCreateOrg = () => {
    doCreateOrg({
      variables: { name: orgName },
      onCompleted: data => {
        navigate(`/admin/org/${data.adminCreateOrg.id}`);
        toast("Organization created.", { type: "success" });
      },
    });
  };

  const mainContent = (
    <>
      <TextLabel>Organization name:</TextLabel>
      <TextField
        fullWidth
        autoComplete="off"
        inputProps={{ maxLength: MAX_TEXT_LENGTH_STANDARD }}
        margin="dense"
        name="org-name"
        onChange={e => setOrgName(e.target.value)}
        size="small"
        value={orgName}
      />
      <Button disabled={!orgName} onClick={handleCreateOrg}>
        Create organization
      </Button>
    </>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h2">Create organization</Typography>
        </Grid>
        <Grid item textAlign="right" xs={6}>
          <Link to="/admin/org">&larr; Back to organization admin</Link>
        </Grid>
      </Grid>
      {loading ? <Loading /> : mainContent}
    </>
  );
};

export default CreateOrg;
