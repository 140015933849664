import CampaignIcon from "@mui/icons-material/Campaign";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

import { CONTENT_GROUP_TYPES } from "../../constants";
import { formatDateTime } from "../../helpers";
import useGetContentGroupFromParam from "../../hooks/useGetContentGroupFromParam";
import { TypographyWithSpacing } from "../../styles";
import CampaignsUsedIn from "../outreach_campaign/CampaignsUsedIn";
import ActionGrid from "../shared/ActionGrid";
import ContentCannotBeChangedTooltipWrapper from "../shared/ContentCannotBeChangedTooltipWrapper";
import TextLabel from "../shared/TextLabel";

import ContentGroupsHeader from "./ContentGroupsHeader";
import VariationPreview from "./VariationPreview";

const ContentGroupDetail = () => {
  const contentGroup = useGetContentGroupFromParam();

  if (!contentGroup) {
    return "Content not found.";
  }

  const campaignsUsedIn = <CampaignsUsedIn audienceOrContentGroup={contentGroup} />;

  const contentGroupActions = (
    <ActionGrid>
      <Grid item>
        <ContentCannotBeChangedTooltipWrapper shouldShow={contentGroup.isLocked}>
          <Button
            component={Link}
            disabled={contentGroup.isLocked}
            startIcon={CONTENT_GROUP_TYPES[contentGroup.type].icon}
            to={`/content/variation/new/${contentGroup.typedId}/`}
          >
            Create new variation
          </Button>
        </ContentCannotBeChangedTooltipWrapper>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          startIcon={<CampaignIcon />}
          to={`/campaigns/new/content/${contentGroup.typedId}`}
        >
          Launch a campaign
        </Button>
      </Grid>
    </ActionGrid>
  );

  return (
    <>
      <Box maxWidth="md">
        <ContentGroupsHeader />
        <Typography variant="h2">{contentGroup.name}</Typography>
        {contentGroupActions}
        <TypographyWithSpacing>
          <TextLabel>Type: </TextLabel> {CONTENT_GROUP_TYPES[contentGroup.type].name}
        </TypographyWithSpacing>
        <TypographyWithSpacing>
          <TextLabel>Number of variations: </TextLabel> {contentGroup.variations.length}
        </TypographyWithSpacing>
        <TypographyWithSpacing>
          <TextLabel>Created: </TextLabel> {formatDateTime(contentGroup.createdAt)}
        </TypographyWithSpacing>
        {campaignsUsedIn ? <TypographyWithSpacing>{campaignsUsedIn}</TypographyWithSpacing> : null}

        <Grid container spacing={2}>
          {contentGroup.variations &&
            contentGroup.variations.map(v => (
              <Grid item key={v.typedId} lg={3} sx={{ padding: "1.5rem 0" }} xs={4}>
                <VariationPreview includeName variation={v} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default ContentGroupDetail;
