import AddIcon from "@mui/icons-material/Add";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { CONTENT_GROUP_TYPES } from "../../constants";
import DropdownButton from "../shared/DropdownButton";

const ContentGroupsHeader = ({
  showNewContentGroupButton = true,
  headerText = "Content",
  alternateRightContent,
  backToText = "Back to library",
  backToLink = "/content",
}) => {
  let rightContent = (
    <Grid item>
      <Button color="secondary" component={Link} startIcon={<ArrowLeftIcon />} to={backToLink}>
        {backToText}
      </Button>
    </Grid>
  );
  if (alternateRightContent !== undefined) {
    rightContent = alternateRightContent;
  }
  return (
    <Grid container alignItems="center" spacing={1.5} sx={{ marginBottom: "30px" }}>
      <Grid item>
        <Typography sx={{ margin: 0, paddingRight: "1rem" }} variant="h2">
          {headerText}
        </Typography>
      </Grid>
      {showNewContentGroupButton ? (
        <Grid item>
          <DropdownButton
            actions={Object.values(CONTENT_GROUP_TYPES)}
            icon={<AddIcon />}
            text="New"
          />
        </Grid>
      ) : null}
      <Grid item xs></Grid>
      {rightContent}
    </Grid>
  );
};

export default ContentGroupsHeader;
