import CheckIcon from "@mui/icons-material/Check";
import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import Loading from "./Loading";

// In milliseconds.
const TIME_TO_CHECK_OFF_STEP = 5 * 1000;
const TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE = 0.08 * 1000;

const INITIAL_COMPLETED_STEP = -1;

const LoadingExtended = ({ steps, isLoading, renderOnComplete, title = "Loading..." }) => {
  const [completedStep, setCompletedStep] = useState(INITIAL_COMPLETED_STEP);
  const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);

  // Kick things off when isLoading is set to true. This makes it so that the parent component
  // can cycle isLoading from false to true multiple times.
  useEffect(() => {
    if (isLoading && !isLoadingInProgress) {
      setIsLoadingInProgress(true);
      setCompletedStep(INITIAL_COMPLETED_STEP);
    }
  }, [isLoadingInProgress, isLoading]);

  // Show final step for TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE and then render `renderOnComplete`.
  useEffect(() => {
    if (completedStep >= steps.length - 1) {
      setTimeout(() => setIsLoadingInProgress(false), TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE);
    }
  }, [completedStep, steps.length]);

  const incrementStep = useCallback(() => {
    setCompletedStep(prevStep => {
      // If we're on the last step, don't check it off until isLoading is false.
      // Instead, set a timeout to check again shortly.
      if (prevStep === steps.length - 2 && isLoading) {
        setTimeout(incrementStep, TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE);
        return prevStep;
      } else {
        return prevStep < steps.length - 1 ? prevStep + 1 : prevStep;
      }
    });
  }, [steps.length, setCompletedStep, isLoading]);

  useEffect(() => {
    if (isLoadingInProgress) {
      setTimeout(
        incrementStep,
        isLoading ? TIME_TO_CHECK_OFF_STEP : TIME_TO_CHECK_OFF_STEP_ONCE_COMPLETE,
      );
    }
  }, [incrementStep, completedStep, isLoading, isLoadingInProgress]);

  if (!isLoading && !isLoadingInProgress) {
    return renderOnComplete;
  } else {
    return (
      <>
        <Typography sx={{ textAlign: "left" }} variant="h3">
          {title}
        </Typography>
        {steps.map((step, index) => (
          <Grid container columnSpacing={2} key={index}>
            <Grid item>
              {completedStep < index ? (
                <Loading />
              ) : (
                <CheckIcon
                  sx={{
                    height: "28px",
                    width: "28px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs style={{ textAlign: "left" }}>
              {step}
            </Grid>
          </Grid>
        ))}
      </>
    );
  }
};

export default LoadingExtended;
