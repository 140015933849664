import { Grid } from "@mui/material";

// Children should be any number of `<Grid item>`s.
const FixedToBottomGrid = ({ children }) => {
  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={0}
      sx={{
        position: "fixed",
        bottom: 0,
        padding: "0.75rem 0",
        background: "inherit",
        zIndex: 1,
      }}
    >
      {children}
    </Grid>
  );
};

export default FixedToBottomGrid;
