import { TextField } from "@mui/material";

import { MIN_PASSWORD_LENGTH } from "../../constants";

const CreatePasswordInput = ({ password, setPassword, ...rest }) => {
  return (
    <TextField
      fullWidth
      autoComplete="currenewnt-password"
      helperText="Passwords must be at least 8 characters."
      label="Password"
      margin="dense"
      minLength={MIN_PASSWORD_LENGTH}
      name="password"
      onChange={e => setPassword(e.target.value)}
      type="password"
      value={password}
      {...rest}
    />
  );
};
export default CreatePasswordInput;
