import SearchIcon from "@mui/icons-material/Search";
import { Box, Typography } from "@mui/material";

const Experiments = () => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        <SearchIcon fontSize="large" sx={{ marginRight: "8px" }} />
        <Typography variant="h5">Experiments coming soon!</Typography>
      </Box>
    </>
  );
};

export default Experiments;
