import { gql, useMutation } from "@apollo/client";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useCallback } from "react";

import { STRIPE_PUBLISHABLE_KEY } from "../constants";
import useGetActiveOrg from "../hooks/useGetActiveOrg";

const STRIPE_CREATE_CHECKOUT_SESSION = gql`
  mutation stripeCreateCheckoutSession(
    $organizationId: ID!
    $numCredits: Int!
    $returnPath: String
  ) {
    stripeCreateCheckoutSession(
      organizationId: $organizationId
      numCredits: $numCredits
      returnPath: $returnPath
    ) {
      id
      clientSecret
    }
  }
`;

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PurchaseCreditsCheckout = ({ numCredits, returnPath = null }) => {
  const { organization } = useGetActiveOrg();
  const organizationId = organization.id;
  const [doStripeCreateCheckoutSession] = useMutation(STRIPE_CREATE_CHECKOUT_SESSION);

  const fetchClientSecret = useCallback(async () => {
    const { data } = await doStripeCreateCheckoutSession({
      variables: { numCredits, organizationId, returnPath: returnPath },
    });
    return data.stripeCreateCheckoutSession.clientSecret;
  }, [doStripeCreateCheckoutSession, numCredits, organizationId, returnPath]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider options={options} stripe={stripePromise}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default PurchaseCreditsCheckout;
