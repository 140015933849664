import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";

import { useAppState } from "../../AppStateContext";
import { formatPercentage, userFriendlyNumber } from "../../helpers";

const formatPctCell =
  ({ field, invert = false }) =>
  params =>
    formatPercentage({ value: invert ? 1 - params.row[field] : params.row[field], sigFig: 2 });

const REQUIRED_COLUMN_SORT_ORDER = ["Always required", "Set as required", "Set as optional"];

const CsvMatchResults = ({ csv, metadata }) => {
  const { constants } = useAppState();

  const rows = metadata.columnMatchResults
    .map(row => ({
      ...row,
      required: (() => {
        if (constants.CANONICAL_CSV_FIELDS[row.column].always_required) {
          return "Always required";
        } else {
          return csv.columnSelections[row.column].is_required
            ? "Set as required"
            : "Set as optional";
        }
      })(),
    }))
    .sort((a, b) =>
      // Show required first and then sort alphabetically
      a.required === b.required
        ? a.column.localeCompare(b.column)
        : REQUIRED_COLUMN_SORT_ORDER.indexOf(a.required) -
          REQUIRED_COLUMN_SORT_ORDER.indexOf(b.required),
    );

  const tableColumnSpecs = [
    {
      field: "column",
      headerName: "Field",
      valueGetter: params => {
        const field = params.row.column;
        const originalColumnName = csv.columnSelections[field].column_name;
        return field === originalColumnName ? field : `${field} (${originalColumnName})`;
      },
      width: 200,
    },
    {
      field: "required",
      headerName: "Match required?",
      width: 125,
    },
    {
      field: "percentMissing",
      headerName: "% of rows with data",
      valueGetter: formatPctCell({ field: "percentMissing", invert: true }),
      width: 150,
    },
    {
      field: "matchRate",
      headerName: "% matching",
      valueGetter: formatPctCell({ field: "matchRate" }),
      width: 100,
    },
    {
      field: "percentOfMatches",
      headerName: "% of matches using",
      valueGetter: formatPctCell({ field: "percentOfMatches" }),
      width: 200,
    },
  ];

  const overallDetails = [
    `Filename: ${csv.name}`,
    `Rows in uploaded file: ${userFriendlyNumber(csv.numDataRows)}`,
    <>
      Matches: {userFriendlyNumber(metadata.numMatches)} (
      {userFriendlyNumber(metadata.numUniqueMatches)} unique)
    </>,
  ];

  const perFieldRenderedColumns = tableColumnSpecs.map(spec => ({
    ...spec,
    renderHeader: params => (
      <Typography variant="columnHeader">{params.colDef.headerName}</Typography>
    ),
    sortable: false, // setting disableColumnSorting on DataGrid must be a "pro" feature
  }));
  return (
    <>
      {overallDetails.map(d => (
        <Typography key={d}>{d}</Typography>
      ))}
      <Typography sx={{ marginTop: 1 }}>
        The table below shows a summary of each column's impact on the matching process. Note that
        the "% matching" value reflects the percentage of all rows in your CSV for which the given
        column matched at least one record in the voter file, whereas the "% of matches using" value
        reflects the percentage of successfully matched rows for which the given column matched.
        (The latter will always be 100% for a required column.)
      </Typography>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        hideFooter
        columns={perFieldRenderedColumns}
        rows={rows}
        rowSelection={false}
        sx={{ marginTop: 5 }}
      />
    </>
  );
};

export default CsvMatchResults;
