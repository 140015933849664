import { gql, useMutation } from "@apollo/client";
import { Typography, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppState } from "../../AppStateContext";

const SEND_PASSWORD_RESET = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      ... on LoginError {
        message
      }
    }
  }
`;

const ForgotPassword = () => {
  const { user } = useAppState();
  const [email, setEmail] = useState("");
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const [doSendPasswordResetMutation, { loading }] = useMutation(SEND_PASSWORD_RESET);

  useEffect(() => {
    if (user) {
      toast("You're already logged in.", { type: "info" });
      navigate("/");
    }
  }, [user, navigate]);

  const handleSendPasswordReset = event => {
    event.preventDefault();
    doSendPasswordResetMutation({
      variables: { email },
      onCompleted: data => {
        if (data.sendPasswordResetEmail && data.sendPasswordResetEmail.message) {
          toast(data.sendPasswordResetEmail.message, { type: "error" });
        } else {
          setWasFormSubmitted(true);
        }
      },
    });
  };

  let forgotPasswordForm;
  if (loading) {
    forgotPasswordForm = <p>Loading...</p>;
  } else if (wasFormSubmitted) {
    forgotPasswordForm = (
      <>
        <p>
          If we have an account associated with the supplied email address, we'll send an email
          containing a link to reset your password. Please check your spam folder if it doesn't
          appear within a few minutes.
        </p>
        <Link to="/">Back to home page</Link>
      </>
    );
  } else {
    forgotPasswordForm = (
      <form onSubmit={handleSendPasswordReset}>
        <TextField
          fullWidth
          autoComplete="email"
          disabled={loading}
          label="Email"
          margin="dense"
          name="email"
          onChange={e => setEmail(e.target.value)}
          type="email"
          value={email}
        />
        <Button disabled={loading} size="large" type="submit">
          {loading ? "Loading..." : "Send password reset email"}
        </Button>
      </form>
    );
  }

  return (
    <>
      <Typography variant="h2">Forgot password</Typography>
      {forgotPasswordForm}
    </>
  );
};
export default ForgotPassword;
