import dayjs from "dayjs";
import { map, mapValues, sortBy } from "lodash";
import { toast } from "react-toastify";

export const toastWithAdditionalMessages = (message, additionalMessages, options) => {
  const additionalMessagesMarkup = additionalMessages ? (
    <ul>
      {additionalMessages.map(msg => (
        <li key={msg}>{msg}</li>
      ))}
    </ul>
  ) : null;
  toast(
    <>
      {message}
      {additionalMessagesMarkup}
    </>,
    options,
  );
};

// Our IDs are sometimes cast as strings and sometimes as ints. Use this function to compare them
// properly, regardless of type.
export const idsMatch = (id1, id2) => {
  // falsey IDs never match
  if (!id1 || !id2) {
    return false;
  }
  return id1.toString() === id2.toString();
};

export const camelToSnake = str =>
  str.replace(/[\w]([A-Z])/g, m => `${m[0]}_${m[1]}`).toLowerCase();

export const recursiveCamelToSnake = obj => recursiveConversion(obj, camelToSnake);

export const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const recursiveSnakeToCamel = obj => recursiveConversion(obj, snakeToCamel);

// Takes a JS object, array, or scalar, and recursively traverses it to convert all keys from
// snake_case to camelCase or vice versa.
const recursiveConversion = (obj, fn) => {
  if (Array.isArray(obj)) {
    return obj.map(elem => recursiveConversion(elem, fn));
  }

  // Make sure the object isn't null, since typeof(null) is 'object'. ¯\_(ツ)_/¯
  if (obj && typeof obj === "object") {
    const newObj = {};
    Object.keys(obj).forEach(key => {
      newObj[fn(key)] = recursiveConversion(obj[key], fn);
    });
    return newObj;
  }
  return obj;
};

export const userFriendlyNumber = number => {
  return number.toLocaleString();
};

export const userFriendlyRoundNumber = number => {
  return Math.round(number).toLocaleString();
};

export const userFriendlyFlooredNumber = number => {
  return Math.floor(number).toLocaleString();
};

const numberRoundedToSignificantDigits = (number, significantDigits = 3) => {
  return Number(number.toPrecision(significantDigits));
};

export const userFriendlyNumberRoundedToSignificantDigits = (number, significantDigits) => {
  return userFriendlyNumber(numberRoundedToSignificantDigits(number, significantDigits));
};

export const userFriendlyDollarsRoundedToSignificantDigits = (number, significantDigits) => {
  return userFriendlyWholeDollars(numberRoundedToSignificantDigits(number, significantDigits));
};

export const userFriendlyWholeDollars = number => {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const userFriendlyCents = number => {
  return (number / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const audienceType = audience => {
  if (audience.audienceLookalikeCreatedFrom) {
    return "Lookalike";
  } else if (audience.uploadedAudienceCsv) {
    return "CSV match";
  } else if (audience.isFilteredFromLookalike) {
    return "Filtered from lookalike";
  } else if (audience.audienceFilteredFrom) {
    return "Filtered from CSV-matched audience";
  } else if (audience.isFilteredFromNational) {
    return "Filtered from national";
  }
};

export const arrayToSentence = (arr, conjunction = "and") => {
  if (!arr || !arr.length) {
    return "";
  }

  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return `${arr[0]} ${conjunction} ${arr[1]}`;
  }

  return `${arr.slice(0, -1).join(", ")}, ${conjunction} ${arr[arr.length - 1]}`;
};

// Takes an array like 0 `[{value: 'NONE', label: 'Unknown'}, {value: 'M', label: 'Male'}, ...]`
// and returns a lookup dict like `{NONE: 'Unknown', M: "Male", ...}`.
export const valueLabelListToLookupDict = valueLabelList =>
  valueLabelList.reduce((acc, { value, label }) => {
    acc[value] = label;
    return acc;
  }, {});

export const formatPercentage = ({ value, sigFig }) => {
  if (value === null) {
    return "N/A";
  }

  const options = sigFig ? { maximumSignificantDigits: sigFig } : {};
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    ...options,
  });
  return formatter.format(value);
};

// These happen to be the same currently, but let's keep them separate and use formatDateForBackend
// when we're sending a date to the backend to make this consistent and future-proof (e.g. if we
// change formatDate so that it uses a different user-facing format).
export const formatDate = timestamp => dayjs(timestamp).format("YYYY-MM-DD");
export const formatDateForBackend = timestamp => dayjs(timestamp).format("YYYY-MM-DD");

export const formatDateTime = timestamp => dayjs(timestamp).format("lll");
export const formatDateTimeForBackend = timestamp => dayjs(timestamp).toISOString();

// Takes an object/dict like `{congressionalDistricts: "US House District", ...}` and returns a
// value label list like `[{ value: "congressionalDistricts", label: "US House District" }, ...]`
export const lookupDictToValueLabelList = lookupDict =>
  map(lookupDict, (label, value) => ({ value: value, label: label }));

export const isAudienceCurrentActiveNotPreviewNotArchived = audience =>
  audience.isCurrentVersion &&
  audience.status === "ACTIVE" &&
  !audience.isPreview &&
  !audience.isArchived;

export const toastForGraphqlError = error => {
  // This stops us from toasting specific error message we may not want to, i.e. only toast
  // specific error message when they have `extensions` (which we add on the backend for any
  // user-facing error).
  if (!error.extensions) {
    toast("Unexpected error. Please try again.", { type: "error" });
  } else {
    const type = error.extensions.type ? error.extensions.type : "error";
    toast(error.message, { type });
  }
};

export const isObject = v => typeof v === "object" && !Array.isArray(v) && v !== null;

export const objectWithSortedListValues = obj =>
  mapValues(obj, val => {
    if (Array.isArray(val)) {
      // For each item in the list, if it's an object, sort it by its `sortString` or `value` key.
      // If it's not an object (e.g. for gender or race), sort it by itself.
      return sortBy(val, v => (isObject(v) ? v.sortString || v.value : v));
    }
    return val;
  });

export const getInitialContentVariationData = (variationNumber = 1) => ({
  name: `Variation #${variationNumber}`,
  textContent: "",
  contentComponentImageId: null,
  contentComponentPdfId: null,
});

export const validateContentVariationData = ({
  contentVariationData,
  type,
  validateFieldIsPresent,
}) => {
  const nameValidates = validateFieldIsPresent("contentVariationName", contentVariationData.name);
  let contentValidates;
  if (type === "DirectMail") {
    contentValidates = !!contentVariationData.contentComponentPdfId;
    if (!contentValidates) {
      toast("You must attach a PDF.", { type: "error" });
    }
  } else {
    contentValidates = validateFieldIsPresent(
      "contentVariationTextContent",
      contentVariationData.textContent,
    );
  }
  return nameValidates && contentValidates;
};

export const extractErrorTypesFromApolloError = apolloError => {
  let errorTypes = [];
  if (apolloError.graphQLErrors.length > 0) {
    errorTypes = apolloError.graphQLErrors.map(graphQLError => graphQLError.extensions.class);
  }
  return errorTypes;
};

// errorType should be e.g. `AudienceAlreadyArchived`.
export const doesApolloErrorIncludeErrorOfType = (apolloError, errorType) =>
  extractErrorTypesFromApolloError(apolloError).includes(errorType);
