import { gql, useMutation } from "@apollo/client";
import { Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { MAX_TEXT_LENGTH_STANDARD, MIN_PASSWORD_LENGTH } from "../../constants";
import { LOGGED_IN_USER_FIELDS } from "../../fragments";
import { toastWithAdditionalMessages } from "../../helpers";

import CreatePasswordInput from "./CreatePasswordInput";

const SIGNUP_WITH_INVITE = gql`
  ${LOGGED_IN_USER_FIELDS}
  mutation signup(
    $firstName: String!
    $lastName: String!
    $password: String!
    $inviteCode: String!
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName
      password: $password
      inviteCode: $inviteCode
    ) {
      ... on LoginSuccess {
        user {
          ...RootUserFields
        }
      }
      ... on LoginError {
        message
        additionalMessages
      }
    }
  }
`;

const Signup = () => {
  const { user } = useAppState();
  const { inviteCode } = useParams();
  const dispatch = useAppStateDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const [doSignupWithInviteMutation, { loading }] = useMutation(SIGNUP_WITH_INVITE);

  // This will handle if the user is already logged in _and_ if they log in via this signup flow.
  // @todo do we want to show an error if the user is already logged in?
  if (user) {
    return <Navigate to="/" />;
  }

  const handleSignup = event => {
    event.preventDefault();
    doSignupWithInviteMutation({
      variables: { firstName, lastName, password, inviteCode },
      onCompleted: data => {
        if (data.signup.user) {
          dispatch({ type: "user-and-orgs-set", user: data.signup.user });
          toast("User created.", { type: "success" });
        } else if (data.signup.message) {
          toastWithAdditionalMessages(data.signup.message, data.signup.additionalMessages, {
            type: "error",
          });
        }
      },
    });
  };

  const submitEnabled = !!firstName && !!lastName && password.length >= MIN_PASSWORD_LENGTH;
  const signupForm = (
    <form onSubmit={handleSignup}>
      <TextField
        fullWidth
        autoComplete="given-name"
        disabled={loading}
        inputProps={{ maxLength: MAX_TEXT_LENGTH_STANDARD }}
        label="First name"
        margin="dense"
        name="firstName"
        onChange={e => setFirstName(e.target.value)}
        value={firstName}
      />

      <TextField
        fullWidth
        autoComplete="family-name"
        disabled={loading}
        inputProps={{ maxLength: MAX_TEXT_LENGTH_STANDARD }}
        label="Last name"
        margin="dense"
        name="lastName"
        onChange={e => setLastName(e.target.value)}
        value={lastName}
      />
      <CreatePasswordInput {...{ password, setPassword }} disabled={loading} />

      <Button disabled={loading || !submitEnabled} size="large" type="submit">
        {loading ? "Loading..." : "Sign up"}
      </Button>
    </form>
  );

  return (
    <>
      <Typography variant="h2">Sign up</Typography>
      {signupForm}
    </>
  );
};
export default Signup;
