import VariationPreview from "./VariationPreview";

const VariationPreviewForForm = ({ type, variationData }) => {
  const url = variationData.previewAttachmentUrl;
  const variationDataForPreview =
    type === "DirectMail"
      ? { type, pdf: { url } }
      : { type, image: { url }, text: { content: variationData.textContent } };

  return <VariationPreview full isLink={false} variation={variationDataForPreview} />;
};

export default VariationPreviewForForm;
