import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import VariationDirectMailPreview from "./VariationDirectMailPreview";
import VariationTextMessagePreview from "./VariationTextMessagePreview";

const VariationPreview = ({ variation, includeName = false, isLink = true, full = false }) => {
  let preview;
  // @todo-content we'll probably need a separate one for digital ads, but this is fine for now.
  if (variation.type === "TextMessage" || variation.type === "DigitalAd") {
    preview = (
      <VariationTextMessagePreview
        imageUrl={variation.image && variation.image.url}
        name={variation.name}
        textContent={variation.text.content}
      />
    );
  } else if (variation.type === "DirectMail") {
    preview = <VariationDirectMailPreview name={variation.name} pdfUrl={variation.pdf.url} />;
  }
  let nameHeader;
  if (includeName) {
    nameHeader = <Typography variant="h4">{variation.name}</Typography>;
  }
  const mainContent = (
    <>
      {nameHeader}
      {preview}
    </>
  );

  if (isLink) {
    return (
      <Link style={{ textDecoration: "none" }} to={`/content/variation/${variation.typedId}`}>
        {mainContent}
      </Link>
    );
  } else {
    return mainContent;
  }
};

export default VariationPreview;
