import { mapValues } from "lodash";

import { useAppState } from "../AppStateContext";
import { formatDateForBackend } from "../helpers";

import useGetGeoFilterOptions from "./useGetGeoFilterOptions";

// The purpose of this function is to take a state value that and transform it, when necessary,
// into a version of that value that is better suited to be a param in an API call.
// For example, controlled text inputs cannot be set to `null`, but if a text input is empty
// (at least in this case), we want to send that to the API as `null` rather than an empty string.
// Ditto for empty lists.
const backendParamValueFromStateValue = val => {
  // Empty string
  if (val === "") {
    return null;
  }
  // Empty list
  else if (val && val.length === 0) {
    return null;
  }
  // True / false strings -- needed for controlled components.
  else if (val === "false") {
    return false;
  } else if (val === "true") {
    return true;
  } else {
    return val;
  }
};

const valuesForValueLabelList = list => list && list.map(s => s.value);

const formatRegDate = filterValue => {
  if (!filterValue) {
    return filterValue;
  }
  return formatDateForBackend(filterValue);
};

const useTransformFiltersForBackendFn = () => {
  const { constants } = useAppState();
  const geoFilterOptions = useGetGeoFilterOptions();

  // Note that this logic is basically the reverse of the logic in `transformFiltersFromBackend`:
  // this function takes filters from state and transforms them so they can be send to the backend.
  return filters => {
    const newFilters = {
      ...Object.fromEntries(
        Object.keys(filters).map(key => [key, backendParamValueFromStateValue(filters[key])]),
      ),
    };
    const areSortAndLimitPresent = !!newFilters.sortField && !!newFilters.limit;
    return {
      ...newFilters,
      ...mapValues(geoFilterOptions, (_, filterKey) =>
        valuesForValueLabelList(newFilters[filterKey]),
      ),
      // If zip isn't present, remove distance from zip.
      distanceFromZip: newFilters.zip ? newFilters.distanceFromZip : null,
      ageStart: newFilters.ageStart === constants.AGE_MIN ? null : newFilters.ageStart,
      ageEnd: newFilters.ageEnd === constants.AGE_MAX ? null : newFilters.ageEnd,
      registrationDateStart: formatRegDate(filters.registrationDateStart),
      registrationDateEnd: formatRegDate(filters.registrationDateEnd),
      // Only include sortField, sortAsc, and limit if sortField and limit are both present.
      sortField: areSortAndLimitPresent ? newFilters.sortField : null,
      sortAsc: areSortAndLimitPresent ? newFilters.sortAsc : null,
      limit: areSortAndLimitPresent ? newFilters.limit : null,
    };
  };
};

export default useTransformFiltersForBackendFn;
