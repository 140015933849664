import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { chain, round, sum } from "lodash";
import { useMemo } from "react";

import { COLORS } from "../../colors";

import HideIfOverflow from "./HideIfOverflow";

const CHART_COLORS = [
  COLORS.cyan,
  COLORS.magenta,
  COLORS.orange,
  COLORS.blue,
  COLORS.brown,
  COLORS.lightBlue,
  COLORS.red,
];
const CHART_HEIGHT = 40;

// Takes `data` like `{"category 1": 30, "category 2": 40, "category 3": 83}`.
const HorizontalPieChart = ({ title, data, thresholdForInclusion = 1 }) => {
  const totalValue = useMemo(() => sum(Object.values(data)), [data]);
  const transformedData = useMemo(
    () =>
      chain(data)
        .map((dataValue, dataKey) => {
          const percentValue = (dataValue / totalValue) * 100;
          return {
            rawValue: dataValue,
            percentValue: percentValue,
            label: `${round(percentValue, 0)}% ${dataKey}`,
            key: dataKey,
          };
        })
        .filter(d => d.percentValue >= thresholdForInclusion)
        .sortBy(d => -d.percentValue)
        .value(),
    [data, thresholdForInclusion, totalValue],
  );

  return (
    <Box sx={{ margin: "1rem 0" }}>
      <Typography variant="h4">{title}</Typography>
      <Grid container columns={100} sx={{ margin: "10px 0 8px" }}>
        {transformedData.map((d, i) => (
          <Grid
            item
            key={d.key}
            sx={{ backgroundColor: CHART_COLORS[i], height: CHART_HEIGHT }}
            xs={d.percentValue}
          >
            <Tooltip title={d.label}>
              <Box sx={{ height: "100%" }}>&nbsp;</Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
      <Grid container columns={100} columnSpacing={2} spacing={0}>
        {transformedData.map(d => (
          <Grid item key={d.key} sx={{ typography: "body2" }} xs={d.percentValue}>
            <HideIfOverflow textContent={d.label} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HorizontalPieChart;
