import { COLORS } from "../../colors";

const MatchbookLogoWithText = ({ width = 100, color = "black", ...rest }) => {
  const hexColor = COLORS[color];
  return (
    <div style={{ width: width, lineHeight: 0, ...rest }}>
      <svg
        fill="none"
        height="100%"
        viewBox="0 0 1000 184"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={hexColor}>
          <path d="m155.75 27.1502c-35.63-35.82004-93.3999-35.82004-129.0299 0-33.51995 33.7-35.49989 87.0998-5.9399 123.1298l24.6699-24.8 12.49 12.55-24.6699 24.8c35.84 29.71 88.9598 27.73 122.4898-5.97 35.63-35.82 35.63-93.8898 0-129.7098zm-41.62 104.5998c-12.8 12.87-31.5098 16.18-47.3498 9.94l18.21-18.31c6.9-6.93 6.9-18.17 0-25.0998-6.9-6.93-18.08-6.93-24.97 0l-18.21 18.3098c-6.21-15.92-2.9101-34.7299 9.8899-47.5899 17.24-17.33 54.1099-25.0999 74.9199-37.6599 20.91-12.61 27.06 60.6699-12.49 100.4198z" />
          <path d="m314.18 51.2698c-5.95 0-11.25 1.16-15.91 3.48s-8.43 5.6399-11.3 9.9699c-4.41-8.96-12.25-13.4499-23.52-13.4499-5.54.1-10.15 1.08-13.84 2.94-3.69 1.85-6.87 4.48-9.53 7.88l-2-9.89h-18.91v79.6002h21.68v-43.4302c0-5.87 1.41-10.54 4.23-13.99s6.59-5.18 11.3-5.18c9.23 0 13.84 6.03 13.84 18.08v44.5102h21.68v-43.5902c0-5.98 1.44-10.64 4.31-13.99s6.66-5.02 11.38-5.02c9.12 0 13.68 6.03 13.68 18.08v44.5102h21.68v-43.7403c0-13.19-2.67-22.6199-8-28.2799-5.33-5.67-12.25-8.5-20.76-8.5z" />
          <path d="m423.74 112.169c-.82-.72-1.23-2.06-1.23-4.02v-27.9697c0-9.38-3.05-16.54-9.15-21.48-6.1-4.95-14.74-7.42-25.91-7.42s-19.22 2.24-25.68 6.72-10.05 10.79-10.76 18.93h20.91c.51-2.99 2.05-5.36 4.61-7.11s5.84-2.63 9.84-2.63c4.51 0 8.1 1.06 10.76 3.17 2.67 2.11 4 4.97 4 8.58v3.86h-15.68c-11.79 0-20.78 2.29-26.98 6.88s-9.3 11.0997-9.3 19.5497c0 7.42 2.69 13.16 8.07 17.23s12.48 6.1 21.3 6.1c5.95 0 11.02-1.18 15.22-3.55s7.74-5.82 10.61-10.36c-.21 8.66 4.66 12.98 14.61 12.98h11.07v-18.39h-2.46c-1.74 0-3.02-.36-3.84-1.08zm-22.6-13.2897c-.1 5.4597-1.79 9.8097-5.07 13.0597s-7.74 4.87-13.38 4.87c-3.59 0-6.43-.82-8.53-2.47s-3.15-3.86-3.15-6.65c0-3.3 1.26-5.85 3.77-7.65 2.51-1.7998 6.02-2.6997 10.53-2.6997h15.84v1.55z" />
          <path d="m464.82 111.39c-1.18-1.24-1.77-3.25-1.77-6.03v-34.7803h17.99v-18.39h-17.99v-22.26h-21.68v22.26h-13.07v18.39h13.07v38.3303c0 15.15 7.53 22.72 22.6 22.72h17.99v-18.39h-11.22c-2.77 0-4.74-.62-5.92-1.85z" />
          <path d="m524.76 69.0493c4.1 0 7.53 1.16 10.3 3.48s4.61 5.44 5.54 9.35h22.45c-1.54-9.58-5.64-17.08-12.3-22.49s-15.27-8.11-25.83-8.11c-8.2 0-15.4 1.67-21.6 5.02s-10.97 8.09-14.3 14.22-5 13.27-5 21.41c0 8.1397 1.67 15.1497 5 21.3297s8.07 10.95 14.22 14.3 13.33 5.02 21.53 5.02c10.66 0 19.35-2.76 26.06-8.27s10.79-13.11 12.22-22.8h-22.29c-.82 4.12-2.64 7.37-5.46 9.74s-6.33 3.55-10.53 3.55c-5.64 0-10.15-2.11-13.53-6.34s-5.07-9.7398-5.07-16.5397c0-6.8 1.69-12.31 5.07-16.54 3.38-4.22 7.89-6.3401 13.53-6.3401z" />
          <path d="m615.02 51.2692c-9.74 0-17.53 3.55-23.37 10.66v-38.48h-21.68v108.1898h21.68v-40.7998c0-6.8 1.56-12.11 4.69-15.92s7.46-5.72 12.99-5.72 9.33 1.73 11.99 5.18c2.67 3.45 4 8.47 4 15.07v42.1898h21.68v-43.1198c0-13.29-3-22.82-8.99-28.59-6-5.77-13.66-8.66-22.99-8.66z" />
          <path d="m720.44 56.1392c-5.54-3.25-11.84-4.87-18.91-4.87-9.84 0-17.78 3.66-23.83 10.97v-38.79h-21.68v108.1898h18.91l2.15-10.82c2.67 3.61 6.05 6.47 10.15 8.58s8.92 3.17 14.45 3.17c6.97 0 13.22-1.7 18.76-5.1s9.87-8.19 12.99-14.37c3.13-6.18 4.69-13.2898 4.69-21.3298s-1.56-15.43-4.69-21.56-7.46-10.82-12.99-14.06zm-9.69 52.3198c-3.59 4.22-8.3 6.34-14.15 6.34s-10.51-2.11-13.99-6.34c-3.49-4.22-5.23-9.7898-5.23-16.6898s1.74-12.29 5.23-16.46 8.15-6.26 13.99-6.26 10.56 2.11 14.15 6.34c3.59 4.22 5.38 9.74 5.38 16.54s-1.79 12.3098-5.38 16.5398z" />
          <path d="m806.55 56.2895c-6.3-3.35-13.61-5.02-21.91-5.02s-15.61 1.67-21.91 5.02-11.17 8.11-14.61 14.3c-3.43 6.18-5.15 13.29-5.15 21.33s1.72 15.1505 5.15 21.3305 8.3 10.95 14.61 14.299c6.3 3.35 13.61 5.021 21.91 5.021s15.61-1.671 21.91-5.021c6.3-3.349 11.17-8.089 14.61-14.219 3.43-6.13 5.15-13.271 5.15-21.4105 0-8.14-1.72-15.15-5.15-21.33s-8.3-10.95-14.61-14.3zm-7.53 52.1605c-3.54 4.22-8.33 6.339-14.38 6.339s-10.69-2.109-14.22-6.339c-3.54-4.22-5.3-9.7405-5.3-16.5405s1.77-12.31 5.3-16.54c3.54-4.22 8.28-6.34 14.22-6.34s10.84 2.11 14.38 6.34c3.54 4.22 5.3 9.74 5.3 16.54s-1.77 12.3105-5.3 16.5405z" />
          <path d="m894.74 56.2895c-6.3-3.35-13.61-5.02-21.91-5.02s-15.61 1.67-21.91 5.02-11.17 8.11-14.61 14.3c-3.43 6.18-5.15 13.29-5.15 21.33s1.72 15.1505 5.15 21.3305 8.3 10.95 14.61 14.299c6.3 3.35 13.61 5.021 21.91 5.021s15.61-1.671 21.91-5.021c6.3-3.349 11.17-8.089 14.61-14.219 3.43-6.13 5.15-13.271 5.15-21.4105 0-8.14-1.72-15.15-5.15-21.33s-8.3-10.95-14.61-14.3zm-7.53 52.1605c-3.54 4.22-8.33 6.339-14.38 6.339s-10.69-2.109-14.22-6.339c-3.54-4.22-5.3-9.7405-5.3-16.5405s1.77-12.31 5.3-16.54c3.54-4.22 8.28-6.34 14.22-6.34s10.84 2.11 14.38 6.34c3.54 4.22 5.3 9.74 5.3 16.54s-1.77 12.3105-5.3 16.5405z" />
          <path d="m970.32 83.2592 28.14-31.06h-24.29l-31.06 33.85v-62.6h-21.68v108.1898h21.68v-19.32l11.84-12.8298 19.99 32.1498h25.06z" />
        </g>
      </svg>
    </div>
  );
};

export default MatchbookLogoWithText;
