import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment } from "@mui/material";

import { COLORS } from "../../colors";

const XOutAdornment = ({ ...rest }) => (
  <InputAdornment position="end">
    <IconButton
      {...rest}
      sx={{
        "&:disabled": {
          color: COLORS.lightGray,
        },
        color: COLORS.gray,
      }}
    >
      <ClearIcon />
    </IconButton>
  </InputAdornment>
);

export default XOutAdornment;
