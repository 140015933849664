import { useAppState } from "../AppStateContext";

const useGetActiveOrg = () => {
  const { organizations, user } = useAppState();
  // @todo determine which organization to use/org picker
  const organizationId = user.organizations[0].id;
  const organization = organizations[organizationId] || {};
  return { organization, organizationId };
};

export default useGetActiveOrg;
