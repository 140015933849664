import styled from "@emotion/styled";
import { Loader } from "@googlemaps/js-api-loader";
import { Box } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

import { grayscale } from "../../colors";
import { GOOGLE_MAPS_API_KEY } from "../../constants";
import { median } from "../../lib/math";

import Loading from "./Loading";

// General map stuff: if we create a new map type, we may want to pull this out so that we can share
// it across types.

const DEFAULT_HEIGHT = 420;
const MINIMUM_ZOOM = 2;

const MAP_OPTIONS = {
  disableDefaultUI: true,
  fullscreenControl: true,
  zoomControl: true,
  styles: [
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{ color: grayscale(10) }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: grayscale(7) }],
    },
    {
      featureType: "road.arterial",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.local",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [{ visibility: "off" }],
    },
  ],
};

const MapContainer = styled.div`
  height: ${({ height }) => height || DEFAULT_HEIGHT}px;
`;

// @todo the margin-top is wrong if the height of the container isn't DEFAULT_HEIGHT.
const MapOverlayText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  text-shadow:
    2px 0 0 #fff,
    -2px 0 0 #fff,
    0 2px 0 #fff,
    0 -2px 0 #fff,
    1px 1px #fff,
    -1px -2px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff;
  margin-top: -${DEFAULT_HEIGHT / 2 + 20}px;
`;

const MapOverlay = ({ message }) => (
  <Box sx={{ position: "relative" }}>
    <MapOverlayText>
      <div>{message}</div>
    </MapOverlayText>
  </Box>
);

// Don't get any closer than this (before the user zooms in further)
const INITIAL_MAX_ZOOM = 11;

const HEATMAP_OPTIONS = {
  opacity: 0.6,
  gradient: [
    // from an old palette, with max saturation, and lower brightness
    // @todo consider converting this to the Matchbook palette
    "rgba(255, 255, 255, 0)", // white
    "rgba(175, 100, 255, 1)", // medium purple
    "rgba(0, 130, 251, 1)", // azure
    "rgba(10, 202, 255, 1)", // vivid sky blue
    "rgba(28, 255, 251, 1)", // aqua
    "rgba(50, 255, 156, 1)", // medium spring green
    "rgba(255, 237, 73, 1)", // lemon yellow
  ],
};

const INITIAL_HEAT_RADIUS = 10;
const DEFAULT_HEAT_RADIUS = 14;
const MIN_HEAT_RADIUS = 1;

const HeatMap = ({
  // A list of objects with { latitude, longitude, [weight=1] } values, for showing as a
  // heat map layer.
  locations,
  // A list of objects with { latitude, longitude } values, for determining the bounds
  // of the map. If omitted, we'll just use `locations` instead.
  boundsLocations,
  // Whether to show the user an explanation that the map is using sampled data.
  showSamplingMessage = true,
  // The percentage of locations to trim out, depending on distance from the
  // centroid of the boundsLocations. Set to 0 to disable this behavior.
  outlierPercentage = 0.01,
  // If we have fewer than this number of `locations`, show a "not enough data" message over the
  // map.
  notEnoughDataThreshold = 20,
  // If we want the map to show the whole country by default regardless of the data
  isNationwide = false,
}) => {
  let centerLatitude, centerLongitude, validBounds;
  let minLatitude, maxLatitude, minLongitude, maxLongitude;

  if (isNationwide) {
    // We just default to a view of the continental U.S. in this case.
    minLatitude = 25;
    maxLatitude = 50;
    minLongitude = -125;
    maxLongitude = -67;

    centerLatitude = (minLatitude + maxLatitude) / 2;
    centerLongitude = (minLongitude + maxLongitude) / 2;
    validBounds = true;
  } else {
    // Compute these location stats, so we can use them below as the useEffect dependencies.
    // If we use `boundsLocations` directly, it will look like it changed each time someone changes
    // the filter in FilteredHeatMap, since React just does a shallow comparison of the object.
    // TODO Could make this simpler if we use https://github.com/kentcdodds/use-deep-compare-effect
    if (!boundsLocations) {
      boundsLocations = locations;
    }

    let lats = boundsLocations.map(x => parseFloat(x.latitude));
    let longs = boundsLocations.map(x => parseFloat(x.longitude));
    centerLatitude = median(lats);
    centerLongitude = median(longs);
    validBounds = !isNaN(centerLatitude) && !isNaN(centerLongitude);

    const distanceFromCenter = loc =>
      Math.sqrt(
        Math.pow(parseFloat(loc.latitude) - centerLatitude, 2) +
          Math.pow(parseFloat(loc.longitude) - centerLongitude, 2),
      );

    if (validBounds) {
      // Sort boundsLocations by distance from the center, and remove outliers.
      const boundsOutlierPos = boundsLocations.length * outlierPercentage;
      const newBoundsLocations = _.sortBy(boundsLocations, distanceFromCenter).slice(
        0,
        -1 * boundsOutlierPos,
      );
      // ...same for locations.
      const locationOutlierPos = locations.length * outlierPercentage;
      const newLocations = _.sortBy(locations, distanceFromCenter).slice(
        0,
        -1 * locationOutlierPos,
      );
      // Only filter out the outliers if it won't result in too few locations to work with.
      if (
        newBoundsLocations.length > notEnoughDataThreshold &&
        newLocations.length > notEnoughDataThreshold
      ) {
        boundsLocations = newBoundsLocations;
        locations = newLocations;
      }
    }

    // Compute these stats after we've removed outliers.
    lats = boundsLocations.map(x => parseFloat(x.latitude));
    longs = boundsLocations.map(x => parseFloat(x.longitude));

    minLatitude = Math.min(...lats);
    maxLatitude = Math.max(...lats);
    minLongitude = Math.min(...longs);
    maxLongitude = Math.max(...longs);
  }

  // Very rough calculation to figure out initial zoom level. Note that this will be overriden
  // fairly quickly by `fitBounds(`.
  let initialRoughZoom = INITIAL_MAX_ZOOM;
  if (validBounds) {
    initialRoughZoom -= Math.floor((maxLongitude - minLongitude + maxLatitude - minLatitude) / 2);
    // The rough calculation doesn't work well for the OOD/nationwide case, so set a floor.
    initialRoughZoom = Math.max(initialRoughZoom, MINIMUM_ZOOM);
  }

  let container = null;

  // Without this, the effect that runs the initialization might happen when container is still
  // null -- and then it won't re-run when container is populated because ref updates don't
  // trigger re-renders.
  const [isContainerVisible, setIsContainerVisible] = useState(false);

  // Stores the map object. We use React state (rather than a React ref) here, since we want the
  // other useEffect hooks (for fitting bounds and adding the heatmap layer) to trigger once the
  // map is ready.
  const [map, setMap] = useState(null);

  // The radius of influence for each sample in the heatmap. This value will be initialized
  // properly when we receive the first "zoom_changed" event from the map.
  const [heatRadius, setHeatRadius] = useState(DEFAULT_HEAT_RADIUS);

  // This state tracks the initial zoom of our map, _after_ we've adjusted the map bounds to
  // fit our samples. It helps us adjust the heatRadius as the user zooms. This value may be
  // different than the loosy-goosy initial zoom level we computed above.
  const [initialZoom, setInitialZoom] = useState(null);

  useEffect(() => {
    if (map) {
      return;
    }

    // The container may not be initialized yet.
    if (isContainerVisible) {
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["visualization"],
      });
      loader.load().then(google => {
        // Make sure we have valid bounds. Otherwise there's no point showing the map at all.
        // Also, container may have reverted to null while we were loading the Google script
        // (presumably because the user navigated away?).
        if (!validBounds || !container) {
          return;
        }

        const mapOptions = {
          ...MAP_OPTIONS,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT,
          },
          center: { lat: centerLatitude, lng: centerLongitude },
          zoom: initialRoughZoom,
          minZoom: MINIMUM_ZOOM,
        };
        const newMap = new google.maps.Map(container, mapOptions);
        setMap(newMap);
      });
    }
  }, [
    isContainerVisible,
    map,
    setMap,
    centerLatitude,
    centerLongitude,
    initialRoughZoom,
    validBounds,
    container,
  ]);

  const updateHeatRadius = useCallback(() => {
    const mapZoom = map ? map.getZoom() : null;
    if (!mapZoom) {
      return;
    }
    let localInitialZoom = initialZoom;
    if (!localInitialZoom) {
      setInitialZoom(mapZoom);
      localInitialZoom = mapZoom;
    }
    const zoomDifference = mapZoom - localInitialZoom;
    const adjustment = Math.pow(zoomDifference, 2);
    const radiusIncrease = ((1500 - locations.length) / 1500) * 10;
    let newRadius = INITIAL_HEAT_RADIUS;
    if (radiusIncrease > 0) {
      newRadius += 1 + radiusIncrease;
    }
    if (zoomDifference > 0) {
      newRadius += adjustment;
    } else {
      newRadius -= adjustment;
    }
    newRadius = Math.max(newRadius, MIN_HEAT_RADIUS);
    setHeatRadius(newRadius);
  }, [initialZoom, locations, setHeatRadius, map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    // When the zoom changes, adjust our heatmap radius, to account for the fact that we have
    // fewer samples as you zoom closer. As you zoom closer, the radius increases, making the
    // heatmap look less precise but also look more similar to the previous zoom level.
    // The math details were based on trial-and-error.
    const listener = map.addListener("zoom_changed", updateHeatRadius);

    return () => window.google.maps.event.removeListener(listener);
  }, [map, initialZoom, locations, updateHeatRadius]);

  // Effect for updating the map bounds. It's triggered when the map is ready, and whenever the
  // boundsLocations are changed (see note above about all these max/min variables.)

  useEffect(() => {
    if (!map || !validBounds) {
      return;
    }

    const newBounds = new window.google.maps.LatLngBounds();
    newBounds.extend({ lat: minLatitude, lng: minLongitude });
    newBounds.extend({ lat: maxLatitude, lng: maxLongitude });
    map.fitBounds(newBounds);
  }, [map, minLatitude, maxLatitude, minLongitude, maxLongitude, validBounds]);

  // The heatmap object. We use a React ref here, since we don't need a re-render when this object
  // changes.
  const heatmapRef = useRef(null);
  const heatmap = heatmapRef.current;

  // Effect for adding the heatmap layer. It's triggered when the map is ready, and whenever the
  // provided `locations` change.

  useEffect(() => {
    if (!map || !locations || locations.length < notEnoughDataThreshold) {
      return;
    }

    async function initialize() {
      const heatmapData = locations.map(loc => ({
        location: new window.google.maps.LatLng(
          parseFloat(loc.latitude),
          parseFloat(loc.longitude),
        ),
        weight: isNaN(loc.weight) ? 1 : loc.weight,
      }));

      // Clear out the old heatmap
      if (heatmap) {
        heatmap.setMap(null);
      }

      if (!window.google.maps.visualization) {
        await window.google.maps.importLibrary("visualization");
      }
      const newHeatmap = new window.google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        ...HEATMAP_OPTIONS,
        radius: heatRadius,
      });
      newHeatmap.setMap(map);
      heatmapRef.current = newHeatmap;
    }

    initialize();
  }, [map, heatmap, locations, heatRadius, notEnoughDataThreshold]);

  return (
    <>
      <MapContainer
        ref={c => {
          container = c;
          setIsContainerVisible(!!c);
        }}
      >
        <Loading />
      </MapContainer>
      {map && locations.length < notEnoughDataThreshold ? (
        <MapOverlay message="Not enough data available" />
      ) : null}
      {map && showSamplingMessage ? (
        <Box sx={{ paddingTop: "10px" }}>This map shows a sample of your audience.</Box>
      ) : null}
    </>
  );
};

/*        <MapOverlayWrapper>
          <MapOverlayText justify="center">
            <div>Not enough data available</div>
          </MapOverlayText>
        </MapOverlayWrapper>
*/

export default HeatMap;
