import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useAppState } from "../../AppStateContext";
import { FormSection } from "../../common/layout";
import {
  DISTANCE_FROM_ZIP_GEO_TYPE_OPTION,
  DISTANCE_FROM_ZIP_MAX,
  DISTANCE_FROM_ZIP_MIN,
  GEO_TYPES,
  HAS_DONOR_HISTORY,
  SHOULD_SORT_ASCENDING,
} from "../../constants";
import { lookupDictToValueLabelList, userFriendlyNumber } from "../../helpers";
import useGetDefaultFilters from "../../hooks/useGetDefaultFilters";
import useGetGeoFilterOptions from "../../hooks/useGetGeoFilterOptions";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import Loading from "../shared/Loading";
import MatchbookDatePicker from "../shared/MatchbookDatePicker";
import XOutAdornment from "../shared/XOutAdornment";

const FORM_GRID_LABEL_SIZE = { xs: 4 };
const FORM_GRID_INPUT_SIZE = { xs: 8 };
// @todo-ui-later define a max width for the whole main (non-left-nav) section of the app.
const FORM_GRID_CONTAINER_PROPS = { alignItems: "center", spacing: 2 };

const FilterSelectMultiCheckbox = ({
  filterKey,
  options,
  isDisabled,
  updateFilterFromList,
  audienceFilters,
}) => (
  <Select
    fullWidth
    multiple
    disabled={isDisabled}
    inputProps={{ shrink: "false" }}
    onChange={e => updateFilterFromList(filterKey, e.target.value)}
    renderValue={selectedValues =>
      options
        .filter(g => selectedValues.includes(g.value))
        .map(g => g.label)
        .join(", ")
    }
    size="small"
    value={audienceFilters[filterKey]}
  >
    {options.map(o => (
      <MenuItem key={o.label} value={o.value}>
        <Checkbox
          checked={audienceFilters[filterKey].includes(o.value)}
          sx={{ padding: "3px 5px 3px 3px" }}
        />
        <ListItemText primary={o.label} />
      </MenuItem>
    ))}
  </Select>
);

const FilterSelectOne = ({
  filterKey,
  options,
  isDisabled,
  setAudienceFilterValue,
  audienceFilters,
  allowEmpty = true,
}) => (
  <DropdownSelectOne
    {...{ allowEmpty, isDisabled, options }}
    onChange={value => setAudienceFilterValue(filterKey, value)}
    value={audienceFilters[filterKey]}
  />
);

const RegistrationDateYearPicker = ({
  filterKey,
  isDisabled,
  updateFormError,
  setAudienceFilterValue,
  audienceFilters,
}) => {
  const regDateAsYear = ({ regDate, isEnd = false }) => {
    if (!regDate || !regDate.year()) {
      return regDate;
    } else if (isEnd) {
      return dayjs().endOf("year").year(regDate.year());
    } else {
      return dayjs().startOf("year").year(regDate.year());
    }
  };

  const isStart = filterKey.includes("Start");
  return (
    <MatchbookDatePicker
      disableFuture
      disabled={isDisabled}
      maxDate={
        isStart && audienceFilters.registrationDateEnd ? audienceFilters.registrationDateEnd : null
      }
      minDate={
        !isStart && audienceFilters.registrationDateStart
          ? audienceFilters.registrationDateStart
          : null
      }
      onChange={(newValue, context) => {
        updateFormError(filterKey, context.validationError);
        if (!context.validationError) {
          setAudienceFilterValue(
            filterKey,
            regDateAsYear({ regDate: newValue, isEnd: filterKey === "registrationDateEnd" }),
          );
        }
      }}
      slotProps={{
        textField: {
          label: isStart ? "From" : "To",
          placeholder: "Any",
          InputLabelProps: { shrink: true },
        },
      }}
      value={audienceFilters[filterKey]}
      views={["year"]}
    />
  );
};

const AudienceFilters = ({
  audienceFilters,
  setAudienceFilters,
  formErrors,
  updateFormError,
  shouldShowSortAndLimit = true,
  isDisabled = false,
  maxLimit = null,
}) => {
  const { constants } = useAppState();
  const geoFilterOptions = useGetGeoFilterOptions();
  const DEFAULT_FILTERS = useGetDefaultFilters();

  const [geoType, setGeoType] = useState();

  const allGeoTypeOptions = useMemo(
    () => lookupDictToValueLabelList(GEO_TYPES).concat(DISTANCE_FROM_ZIP_GEO_TYPE_OPTION),
    [],
  );

  const existingGeoType = useMemo(
    // This `.length > 0` condition works for the array values of the standard geo filters and also
    // for the string value of the `zip` filter.
    () => allGeoTypeOptions.find(geoType => audienceFilters[geoType.value].length > 0),
    [audienceFilters, allGeoTypeOptions],
  );
  useEffect(() => {
    if (existingGeoType) {
      setGeoType(existingGeoType.value);
    }
  }, [existingGeoType]);

  useEffect(() => {
    if (audienceFilters.sortField && !audienceFilters.limit && !isDisabled) {
      updateFormError("limit", "Sorting won't do anything unless a limit is applied.");
    } else if (audienceFilters.sortField && audienceFilters.limit && audienceFilters.limit < 1) {
      updateFormError("limit", "Limit must be a positive number.");
    } else if (!audienceFilters.sortField && !!audienceFilters.limit) {
      updateFormError("limit", "Limit can't be applied to an unsorted audience.");
    } else if (maxLimit && audienceFilters.limit > maxLimit) {
      updateFormError(
        "limit",
        `Limit cannot exceed the underlying audience size (${userFriendlyNumber(maxLimit)}).`,
      );
    } else {
      updateFormError("limit", false);
    }
  }, [audienceFilters.limit, audienceFilters.sortField, isDisabled, updateFormError, maxLimit]);

  const geoOptions = useMemo(() => {
    if (!geoType) {
      return [];
    } else if (geoType === "states") {
      return sortBy(constants.US_STATES_AND_DC, "label");
    } else {
      return sortBy(geoFilterOptions[geoType], "sortString");
    }
  }, [geoType, geoFilterOptions, constants.US_STATES_AND_DC]);

  const validateZip = newZip => {
    if (newZip !== "" && !newZip.match(/^\d{5}$/)) {
      updateFormError("zip", "Please enter a valid five-digit ZIP code.");
    } else {
      updateFormError("zip", null);
    }
  };

  // Setters

  const setAudienceFilterValue = (name, value) => {
    setAudienceFilters(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAgeChange = (e, newAgeRange) => {
    setAudienceFilters(prevData => ({
      ...prevData,
      ageStart: newAgeRange[0],
      ageEnd: newAgeRange[1],
    }));
  };

  const updateFilterFromList = (key, value) => {
    setAudienceFilterValue(key, value);
  };

  const handleGeoTypeChange = newGeoType => {
    setGeoType(prevGeoType => {
      // Reset any filter values for previous geo type to ensure we only filter by a single type.
      if (prevGeoType) {
        setAudienceFilterValue(prevGeoType, DEFAULT_FILTERS[prevGeoType]);
        if (prevGeoType === "zip") {
          setAudienceFilterValue("distanceFromZip", DEFAULT_FILTERS["distanceFromZip"]);
        }
      }
      return newGeoType;
    });
  };

  if (!geoFilterOptions) {
    return <Loading />;
  }

  const zipIsPresentAndValid = !!audienceFilters.zip && !formErrors.zip;

  const distanceFromZipDisplay = (
    <>
      <Grid item style={{ width: "110px" }} xs="auto">
        <TextField
          fullWidth
          autoComplete="off"
          disabled={isDisabled}
          error={!!formErrors.zip}
          inputProps={{ autoComplete: "off", maxLength: 5 }}
          label="ZIP"
          margin="dense"
          name="audience-name"
          onChange={e => {
            validateZip(e.target.value);
            setAudienceFilterValue("zip", e.target.value);
          }}
          size="small"
          sx={{ marginBottom: "1rem", width: "75px" }}
          value={audienceFilters.zip}
        />
      </Grid>
      <Grid item xs>
        <Slider
          disabled={isDisabled || !zipIsPresentAndValid}
          getAriaLabel={() => "Distance"}
          getAriaValueText={v => v}
          marks={[
            {
              value: audienceFilters.distanceFromZip,
              label: zipIsPresentAndValid ? `${audienceFilters.distanceFromZip} miles` : " ",
            },
          ]}
          max={DISTANCE_FROM_ZIP_MAX}
          min={DISTANCE_FROM_ZIP_MIN}
          onChange={(e, newDistanceFromZip) =>
            setAudienceFilterValue("distanceFromZip", newDistanceFromZip)
          }
          value={audienceFilters.distanceFromZip}
          valueLabelDisplay="off"
        />
        {formErrors.zip ? (
          <FormHelperText style={{ color: "red", marginTop: "-20px" }}>
            {formErrors.zip}
          </FormHelperText>
        ) : null}
      </Grid>
    </>
  );

  const geographySection = (
    <>
      <Typography variant="h4">Select geography</Typography>
      <FormSection>
        <Grid container {...FORM_GRID_CONTAINER_PROPS}>
          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Geography type
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <DropdownSelectOne
              {...{ isDisabled }}
              allowEmpty={true}
              onChange={handleGeoTypeChange}
              options={allGeoTypeOptions}
              value={geoType}
            />
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Geography filter
          </Grid>
          <Grid container item {...FORM_GRID_INPUT_SIZE}>
            {geoType === "zip" ? (
              distanceFromZipDisplay
            ) : (
              <Grid item xs>
                <Autocomplete
                  multiple
                  disabled={isDisabled || !geoType}
                  getOptionLabel={option => option.label}
                  onChange={(e, selectedOption) => {
                    setAudienceFilterValue(geoType, selectedOption);
                  }}
                  options={geoOptions}
                  renderInput={params => <TextField {...params} placeholder={GEO_TYPES[geoType]} />}
                  size="small"
                  style={{ marginBottom: 10 }}
                  value={audienceFilters[geoType] || []}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </FormSection>
    </>
  );

  const traitsSection = (
    <>
      <Typography variant="h4">Select traits</Typography>
      <FormSection>
        <Grid container {...FORM_GRID_CONTAINER_PROPS}>
          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Age range
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <Slider
              disabled={isDisabled}
              getAriaLabel={() => "Age"}
              getAriaValueText={v => v}
              marks={[
                { value: audienceFilters.ageStart, label: audienceFilters.ageStart },
                { value: audienceFilters.ageEnd, label: audienceFilters.ageEnd },
              ]}
              max={constants.AGE_MAX}
              min={constants.AGE_MIN}
              onChange={handleAgeChange}
              value={[audienceFilters.ageStart, audienceFilters.ageEnd]}
              valueLabelDisplay="off"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={audienceFilters.ageIncludeUnknown}
                  disabled={isDisabled}
                  name="include-unknown-ages"
                  onChange={e => setAudienceFilterValue("ageIncludeUnknown", e.target.checked)}
                />
              }
              label="Include unknown ages"
            />
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Sex
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <FilterSelectMultiCheckbox
              filterKey="genders"
              options={constants.GENDERS}
              {...{ isDisabled, updateFilterFromList, audienceFilters }}
            />
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Race
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <FilterSelectMultiCheckbox
              filterKey="races"
              options={constants.RACES}
              {...{ isDisabled, updateFilterFromList, audienceFilters }}
            />
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Donation history
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <FilterSelectOne
              filterKey="hasDonorHistory"
              options={HAS_DONOR_HISTORY}
              {...{ isDisabled, setAudienceFilterValue, audienceFilters }}
            />
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Contact info
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <FilterSelectOne
              filterKey="contactInfo"
              options={constants.CONTACT_INFO}
              {...{ isDisabled, setAudienceFilterValue, audienceFilters }}
            />
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Voter registration year
          </Grid>
          <Grid item xs={3}>
            <RegistrationDateYearPicker
              {...{ isDisabled, updateFormError, setAudienceFilterValue, audienceFilters }}
              filterKey="registrationDateStart"
            />
          </Grid>
          <Grid item>to</Grid>
          <Grid item xs={3}>
            <RegistrationDateYearPicker
              {...{ isDisabled, updateFormError, setAudienceFilterValue, audienceFilters }}
              filterKey="registrationDateEnd"
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );

  const sortAndLimitSection = !shouldShowSortAndLimit ? null : (
    <>
      <Typography variant="h4">Sort and limit</Typography>
      <FormSection>
        <Grid container {...FORM_GRID_CONTAINER_PROPS}>
          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Sort by
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FilterSelectOne
                  filterKey="sortField"
                  options={constants.SORT_FIELDS}
                  {...{ isDisabled, setAudienceFilterValue, audienceFilters }}
                />
              </Grid>
              <Grid item xs={5}>
                <FilterSelectOne
                  allowEmpty={false}
                  filterKey="sortAsc"
                  isDisabled={isDisabled || !audienceFilters.sortField}
                  options={SHOULD_SORT_ASCENDING}
                  {...{ setAudienceFilterValue, audienceFilters }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item {...FORM_GRID_LABEL_SIZE}>
            Limit
          </Grid>
          <Grid item {...FORM_GRID_INPUT_SIZE}>
            <TextField
              disabled={isDisabled || !audienceFilters.sortField}
              error={!!formErrors.limit}
              helperText={formErrors.limit}
              InputProps={{
                min: 1,
                sx: { width: "200px" },
                endAdornment: (
                  <XOutAdornment
                    disabled={!audienceFilters.limit || isDisabled}
                    onClick={() => setAudienceFilterValue("limit", "")}
                  />
                ),
              }}
              onChange={e => setAudienceFilterValue("limit", parseInt(e.target.value))}
              // @todo consider using MUI's NumberInput component, which has its own problems.
              // See https://mui.com/base-ui/react-number-input/#introduction
              size="small"
              type="number"
              value={audienceFilters.limit || ""}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );

  return (
    <form>
      {geographySection}
      {traitsSection}
      {sortAndLimitSection}
    </form>
  );
};

export default AudienceFilters;
