import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { toastForGraphqlError } from "./helpers";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { routes } from "./routes";

const IS_DEV = process.env.NODE_ENV === "development";

if (!IS_DEV) {
  Sentry.init({
    dsn: "https://337f92120b070f850199118b40ed5466@o4506185815293952.ingest.sentry.io/4506260385628160",
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
  });
}

let uri, credentials;
if (IS_DEV) {
  uri = "http://localhost:8000/api/graphql";
  // NB: This means that credentials will always be passed locally. So make sure not to pass
  // them to any third-party services!
  credentials = "include";
} else {
  uri = `${window.location.origin}/api/graphql`;
  credentials = "same-origin";
}

const httpLink = createHttpLink({ uri, credentials });

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const { errorHandler } = operation.getContext();

  graphQLErrors &&
    graphQLErrors.forEach(error => {
      console.error(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
          error.locations,
        )}, Path: ${error.path}`,
      );

      if (errorHandler) {
        return forward(operation);
      } else if (error.extensions && error.extensions.class === "LoggedOutError") {
        // If user is logged out, redirect to /login. This is especially important because
        // of the audience pending polling we do: we don't want to keep polling if the user
        // is logged out.
        window.location.href = "/login";
      } else {
        toastForGraphqlError(error);
      }
    });
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: concat(errorLink, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { fetchPolicy: "no-cache" },
    query: { fetchPolicy: "no-cache" },
  },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")).render(
  <ApolloProvider client={client}>
    <RouterProvider router={router} />
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
