export const median = values => {
  values = values.slice(0).sort(function (a, b) {
    return a - b;
  });

  return middle(values);
};

export const middle = values => {
  var len = values.length;
  var half = Math.floor(len / 2);

  if (len % 2) {
    return values[half];
  }
  return (values[half - 1] + values[half]) / 2.0;
};
