import dayjs from "dayjs";
import { mapValues } from "lodash";
import { useMemo } from "react";

import { NON_FILTER_FILTER_KEYS } from "../constants";

import useGetDefaultFilters from "./useGetDefaultFilters";
import useGetGeoFilterOptions from "./useGetGeoFilterOptions";

const valueLabelListForValues = (values, valueLabelList) =>
  values && valueLabelList.filter(s => values.includes(s.value));

const filterAsDate = filterValue => filterValue && dayjs(filterValue);

const boolToString = filterValue => (filterValue === null ? filterValue : filterValue.toString());

const useTransformedFiltersFromBackend = filters => {
  const DEFAULT_FILTERS = useGetDefaultFilters();
  const geoFilterOptions = useGetGeoFilterOptions();

  // Note that this logic is basically the reverse of the logic in `transformFiltersForBackend`:
  // this function takes filters from the backend and transforms them so they can be used as state.
  const transformFiltersFromBackend = ({ filters, geoFilterOptions }) => {
    let newFilters = {
      ...filters,
      ...mapValues(geoFilterOptions, (filterValue, filterKey) =>
        valueLabelListForValues(filters[filterKey], filterValue),
      ),
      registrationDateStart: filterAsDate(filters.registrationDateStart),
      registrationDateEnd: filterAsDate(filters.registrationDateEnd),
      // These use a select UI which means they need to be strings rather than booleans.
      hasDonorHistory: boolToString(filters.hasDonorHistory),
      sortAsc: boolToString(filters.sortAsc),
    };
    return Object.fromEntries(
      Object.entries(newFilters).filter(
        ([key, value]) =>
          !NON_FILTER_FILTER_KEYS.includes(key) && value !== null && value !== undefined,
      ),
    );
  };

  return useMemo(
    () =>
      filters
        ? {
            ...DEFAULT_FILTERS,
            ...transformFiltersFromBackend({ filters, geoFilterOptions }),
          }
        : null,
    [filters, geoFilterOptions, DEFAULT_FILTERS],
  );
};

export default useTransformedFiltersFromBackend;
