import { Typography } from "@mui/material";

const TextLabel = ({ children }) => {
  return (
    <Typography component="span" fontWeight="bold">
      {children}
    </Typography>
  );
};

export default TextLabel;
