import { Container } from "@mui/material";

import { COLORS } from "../../colors";

const InfoBox = ({
  backgroundColor = "lightGray",
  color = "black",
  marginTop = "1.5rem",
  marginBottom = "0",
  typography = "body2",
  children,
}) => {
  return (
    <Container
      sx={{
        backgroundColor: COLORS[backgroundColor],
        color: COLORS[color],
        padding: "1rem",
        marginTop,
        marginBottom,
        typography,
      }}
    >
      {children}
    </Container>
  );
};

export default InfoBox;
