import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

import { COLORS } from "../../colors";

const CardItem = ({ linkTo, isArchived, isPending, requiresPayment, children }) => {
  let opacity;
  if (isArchived) {
    opacity = 0.5;
  } else if (requiresPayment) {
    opacity = 0.7;
  } else {
    opacity = 1;
  }

  return (
    <Grid item md={4} xl={3} xs={6}>
      <Link style={{ textDecoration: "none" }} to={linkTo}>
        <Box
          sx={{
            background: isPending ? COLORS.veryLightOrange : COLORS.white,
            height: "100%",
            padding: "0.75rem",
            borderTop: `4px solid ${isArchived ? COLORS.lightOrange : COLORS.orange}`,
            opacity,
          }}
        >
          {children}
        </Box>
      </Link>
    </Grid>
  );
};

export default CardItem;
