import ExpandIcon from "@mui/icons-material/Expand";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Outlet } from "react-router-dom";

import Tabs from "../shared/Tabs";

import AudiencesHeader from "./AudiencesHeader";

const AUDIENCE_TABS = [
  { url: "/audiences/filters", icon: <FilterAltIcon />, name: "Apply filters" },
  { url: "/audiences/match", icon: <UploadFileIcon />, name: "Match a CSV to the voter file" },
  { url: "/audiences/expand", icon: <ExpandIcon />, name: "Expand an existing audience" },
];

const CreateAudience = () => {
  return (
    <>
      <AudiencesHeader headerText="Create an audience" showNewAudienceButton={false} />
      <Tabs tabs={AUDIENCE_TABS} />
      <Outlet />
    </>
  );
};

export default CreateAudience;
