import { Grid } from "@mui/material";

import { COLORS } from "../../colors";

const ActionGrid = ({ children, sx = {} }) => {
  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={1}
      rowSpacing={0}
      sx={{
        backgroundColor: COLORS.lightGray,
        padding: "1rem 0.75rem",
        margin: "1rem 0",
        // Need two different selectors to get enough specificity for the styling to be applied.
        // This is important because otherwise the text is the same color as the background
        // of the action grid cell (lightGray).
        "& a.Mui-disabled, & button.Mui-disabled": {
          color: COLORS.lightMagenta,
        },
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
};

export default ActionGrid;
