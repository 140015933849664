import { omit } from "lodash";
import { useCallback, useState } from "react";

const useFormErrors = () => {
  const [formErrors, setFormErrors] = useState({});

  // If `value` is false-y, omit the key from `formErrors` rather than setting it.
  const updateFormError = useCallback((key, value) => {
    setFormErrors(prevData =>
      value
        ? {
            ...prevData,
            [key]: value,
          }
        : omit(prevData, key),
    );
  }, []);

  const validateFieldIsPresent = (fieldName, fieldValue) => {
    if (fieldValue === null || fieldValue.length === 0) {
      updateFormError(fieldName, "This field is required.");
      return false;
    } else {
      updateFormError(fieldName, null);
      return true;
    }
  };

  return { formErrors, updateFormError, validateFieldIsPresent };
};

export default useFormErrors;
