import { gql, useQuery } from "@apollo/client";
import { Outlet } from "react-router-dom";

import { useAppStateDispatch } from "../AppStateContext";
import {
  CORE_OUTREACH_CAMPAIGN_FIELDS,
  CORE_GENERIC_CONTENT_VARIATION_FIELDS,
  CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS,
  CORE_CONTENT_GROUP_FIELDS,
} from "../fragments";
import useGetActiveOrg from "../hooks/useGetActiveOrg";

export const GET_CONTENT_GROUPS_AND_CAMPAIGNS_FOR_ORG = gql`
  ${CORE_CONTENT_GROUP_FIELDS}
  ${CORE_OUTREACH_CAMPAIGN_FIELDS}
  ${CORE_GENERIC_CONTENT_VARIATION_FIELDS}
  query getContentGroupsAndCampaigns($organizationId: ID!) {
    organization(pk: $organizationId) {
      id
      contentGroups {
        ...CoreContentGroupFields
        variations {
          ...CoreGenericContentVariationFields
          ... on ContentVariationDirectMail {
            ${CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS["DirectMail"]}
          }
          ... on ContentVariationTextMessage {
            ${CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS["TextMessage"]}
          }
          ... on ContentVariationDigitalAd {
            ${CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS["DigitalAd"]}
          }
        }
      }
      outreachCampaigns {
        ...CoreOutreachCampaignFields
      }
    }
  }
`;

const ContentGroupAndCampaignData = () => {
  const dispatch = useAppStateDispatch();
  const { organizationId, organization } = useGetActiveOrg();
  const { contentGroups, outreachCampaigns } = organization;

  useQuery(GET_CONTENT_GROUPS_AND_CAMPAIGNS_FOR_ORG, {
    variables: { organizationId },
    onCompleted: data => {
      dispatch({
        type: "org-set-content-groups",
        organization: data.organization,
      });
      dispatch({
        type: "org-set-outreach-campaigns",
        organization: data.organization,
      });
    },
    skip: !!outreachCampaigns && !!contentGroups,
  });

  if (!contentGroups || !outreachCampaigns) {
    return "Loading...";
  }
  return <Outlet />;
};

export default ContentGroupAndCampaignData;
