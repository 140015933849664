import { Box, Button, Grid, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useAppState } from "../AppStateContext";
import { COLORS } from "../colors";
import { userFriendlyCents, userFriendlyNumber } from "../helpers";
import useGetActiveOrg from "../hooks/useGetActiveOrg";
import useGetMinMaxCreditsPurchase from "../hooks/useGetMinMaxCreditsPurchase";

import PurchaseCreditsCheckout from "./PurchaseCreditsCheckout";
import InfoBox from "./shared/InfoBox";
import NumericField from "./shared/NumericField";
import SharedModal from "./shared/SharedModal";

const CreditsInfo = () => {
  const { constants } = useAppState();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Item</strong>
              </TableCell>
              <TableCell>
                <strong>Price</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Voter file match</TableCell>
              <TableCell>
                {constants.AUDIENCE_FIELD_PRICING.match.price} credit per person
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Basic export</TableCell>
              <TableCell>
                {constants.AUDIENCE_FIELD_PRICING.base_export.price} credits per person
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email, phone, or address export</TableCell>
              <TableCell>
                {constants.AUDIENCE_CONTACT_INFO_FIELDS_PRICE} credits per person
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Content evaluation</TableCell>
              <TableCell>{constants.CAMPAIGN_CREATION_PRICE} credits</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PurchaseCredits = () => {
  const [numCredits, setNumCredits] = useState();
  const [isReadyForCheckout, setIsReadyForCheckout] = useState(false);
  const [shouldShowCreditsInfo, setShouldShowCreditsInfo] = useState(false);
  const { constants } = useAppState();
  const { organization } = useGetActiveOrg();
  const { creditTransactions } = organization;
  const { MIN_CREDITS_PURCHASE, MAX_CREDITS_PURCHASE } = useGetMinMaxCreditsPurchase();
  const { returnPath } = useParams();

  // See get_price_per_credit in credit_transaction.py
  const getPricingTier = useCallback(
    numCredits =>
      constants.CREDIT_PRICING_TIERS[
        Math.max(
          ...Object.keys(constants.CREDIT_PRICING_TIERS)
            .map(Number)
            .filter(threshold => numCredits >= threshold),
        )
      ],
    [constants.CREDIT_PRICING_TIERS],
  );

  // See get_price_info in credit_transaction.py
  const getPriceInfo = useCallback(
    numCredits => {
      const tier = getPricingTier(numCredits || 0);
      const totalPrice = Math.floor(numCredits * tier.pricePerCredit);
      return { ...tier, totalPrice };
    },
    [getPricingTier],
  );

  const priceInfo = useMemo(() => getPriceInfo(numCredits), [numCredits, getPriceInfo]);

  const numCreditsIsValid = useMemo(
    () => numCredits && numCredits >= MIN_CREDITS_PURCHASE && numCredits <= MAX_CREDITS_PURCHASE,
    [numCredits, MAX_CREDITS_PURCHASE, MIN_CREDITS_PURCHASE],
  );

  const pricingInfo = (
    <>
      <TableContainer sx={{ marginTop: "10px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Number of credits</strong>
              </TableCell>
              <TableCell>
                <strong>Price per credit</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(constants.CREDIT_PRICING_TIERS, (tier, threshold) => (
              <TableRow
                key={threshold}
                sx={{
                  backgroundColor:
                    numCreditsIsValid &&
                    priceInfo.volumeDiscountPercentage === tier.volumeDiscountPercentage
                      ? COLORS.lightGreen
                      : "inherit",
                }}
              >
                <TableCell>{userFriendlyNumber(Number(threshold))}+</TableCell>
                <TableCell>
                  {tier.pricePerCredit}¢{tier.volumeDiscountDisplay}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  let errorInfoBox;
  if (numCredits && !numCreditsIsValid) {
    errorInfoBox = (
      <InfoBox backgroundColor="lightRed">
        <Typography>
          Number of credits must be between {MIN_CREDITS_PURCHASE} and{" "}
          {userFriendlyNumber(MAX_CREDITS_PURCHASE)}
        </Typography>
      </InfoBox>
    );
  }

  let infoBoxContent = <Typography>Select a number of credits to view your price.</Typography>;
  if (priceInfo && priceInfo.totalPrice && numCreditsIsValid) {
    infoBoxContent = (
      <>
        <Typography>
          <strong>{userFriendlyNumber(numCredits)}</strong> credits will cost{" "}
          {priceInfo.volumeDiscountPercentage > 0 ? (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {userFriendlyCents(numCredits * constants.CREDIT_PRICING_TIERS["0"].pricePerCredit)}
              </span>{" "}
            </>
          ) : null}
          <strong>{userFriendlyCents(priceInfo.totalPrice)}</strong>
        </Typography>
      </>
    );
  }
  const infoBox = (
    <InfoBox backgroundColor="lighterGray" marginTop="0">
      {infoBoxContent}
      {pricingInfo}
      <Box marginTop="1rem">
        <Link onClick={() => setShouldShowCreditsInfo(true)} size="small">
          What can I buy with credits?
        </Link>
      </Box>
    </InfoBox>
  );

  let creditTransactionsLink;
  if (creditTransactions && creditTransactions.length > 0) {
    creditTransactionsLink = (
      <Box marginTop="1rem">
        <Link to="/credits/transactions">View credit transaction history</Link>
      </Box>
    );
  }
  return (
    <>
      <Typography variant="h2">Purchase credits for your organization:</Typography>
      <SharedModal isOpen={isReadyForCheckout} setIsOpen={setIsReadyForCheckout} width={600}>
        <PurchaseCreditsCheckout numCredits={numCredits} returnPath={returnPath} />
      </SharedModal>
      <SharedModal isOpen={shouldShowCreditsInfo} setIsOpen={setShouldShowCreditsInfo} width={600}>
        <CreditsInfo />
      </SharedModal>
      <Grid
        container
        alignItems="baseline"
        columnSpacing={2}
        // This explicit height is so that the dropdown doesn't overlap the submit button.
        // sx={{ height: `${(Object.values(constants.CREDIT_PRICING_TIERS).length - 1) * 50}px` }}
      >
        <Grid item>
          <NumericField label="Number of credits" setValue={setNumCredits} value={numCredits} />
        </Grid>
        <Grid item xs>
          {infoBox}
        </Grid>
      </Grid>
      {errorInfoBox}
      <Box>
        <Button
          disabled={!numCreditsIsValid}
          onClick={() => setIsReadyForCheckout(true)}
          sx={{ marginTop: "1rem" }}
        >
          Purchase credits
        </Button>
      </Box>
      {creditTransactionsLink}
    </>
  );
};

export default PurchaseCredits;
